import { BRAVE_CHAT_HISTORY, CHAT_HISTORY, SITES_DATA } from "../types";

const initialState = {
    chat: [],
    historyChat: [],
    loading: false,
    chattingId: -1,
    delete_loader: false,
    sitesData: [],
    braveSearchChat: {},
    braveSearchHistory: [],
};

const chattingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHATBOTReply":
            return {
                ...state,
                chat: action.payload.chatting,
                chattingId: action.payload.chatId,
            };
        case "CHATBOT_BRAVESEARCH":
            return {
                ...state,
                braveSearchChat: action.payload,
            };
        case BRAVE_CHAT_HISTORY:
            return {
                ...state,
                braveSearchHistory: action.payload,
            };

        //LOADER
        case CHAT_HISTORY:
            return {
                ...state,
                historyChat: action.payload,
            };
        case SITES_DATA:
            return {
                ...state,
                sitesData: action.payload,
            };
        case "LOADER":
            return {
                ...state,
                loading: action.payload,
            };
        case "DELETE_LOADER":
            return {
                ...state,
                delete_loader: action.payload,
            };
        default:
            return state;
    }
};

export default chattingReducer;
