import React, { useState } from 'react';
import MarkDownRenderer from './MarkDownRender';
function ParentComponent({ newsData }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container">
      <div className="main_all_card_container">
        <div className="all-cards-container">
          <MarkDownRenderer newsData={newsData} showMore={showMore} toggleShowMore={toggleShowMore} />
        </div>
        {newsData.length > 200 && (
          <div className="news_btn">
            <span
              className={showMore ? 'show-less-text' : 'show-more-text'}
              onClick={toggleShowMore}
            >
              {showMore ? 'Show Less' : 'Show More...'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ParentComponent;

