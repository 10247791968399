import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoOpenTruth from "../../assets/img/logoOpenTruth.png";
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    Row,
    Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { SignUpAction, VerifyAction } from "../../store/actions/authAction";

export const SignUp = () => {
    const { loading } = useSelector((state) => state.userData);
    console.log("loading--", loading);
    const [data, setData] = useState({
        Username: "",
        Email: "",
        Password: "",
        Status: "",
    });
    const [otp, setOtp] = useState(["", "", "", ""]);

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;

        // Move to the next input box if the current input is not empty
        if (value !== "" && index < 3) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }

        setOtp(newOtp);
    };
    const [modal, setModal] = useState(false);
    const [timer, setTimer] = useState(60);

    const dispatch = useDispatch();
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(
            SignUpAction(data, () => {
                setTimer(60);
                setModal(!modal);
            })
        );
    };
    const toggle = () => {
        setModal(!modal);
    };
    useEffect(() => {
        let countdownInterval;

        if (modal) {
            countdownInterval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 0) {
                        clearInterval(countdownInterval);
                        toast.warn("Sign Up Again");
                        setTimer(60);
                        setOtp(["", "", "", ""]);
                        toggle(); // Ensure toggle updates the modal state correctly
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        // Clean up the interval when the component unmounts or when modal becomes false
        return () => clearInterval(countdownInterval);
    }, [modal, toggle]);

    // useEffect(() => {
    //     if (modal == true) {
    //         const countdown = setInterval(() => {
    //             setTimer((prevTimer) => {
    //                 if (prevTimer === 0) {
    //                     clearInterval(countdown);
    //                     toast.warn("Sign Up Again");
    //                     setTimer(60);
    //                     setOtp(["", "", "", ""]);
    //                     toggle();
    //                 }
    //                 return prevTimer - 1;
    //             });
    //         }, 1000);

    //         // Clean up the interval when the component unmounts
    //         return () => clearInterval(countdown);
    //     }
    // }, [modal]);
    const history = useHistory();
    return (
        <>
            <div className="vh-100   overflow-auto bg-black">
                <Container>
                    <Row className="justify-content-center   signUp vh-100  align-items-center">
                        <Col md="5">
                            <Card className=" signUp__card shadow p-3 mb-3 bg-white">
                                {/* <div className="text-center my-3">
                                <img
                                    className="signUp__im img-fluid"
                                    src={logoOpenTruth}
                                />
                            </div> */}
                                <div className="fs--24 fw--700 signUp__title text-center">
                                    Sign Up
                                </div>

                                <CardBody>
                                    <Form onSubmit={handleRegister}>
                                        <FormGroup>
                                            <Label className="signUp__label">
                                                Username
                                            </Label>
                                            <Input
                                                type="text"
                                                name="Username"
                                                placeholder="Username"
                                                required
                                                onChange={handleChange}
                                                className="signUp__input"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="signUp__label">
                                                Email
                                            </Label>
                                            <Input
                                                type="email"
                                                name="Email"
                                                placeholder="Email"
                                                className="signUp__input"
                                                required
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mt-2">
                                            <Label className="signUp__label">
                                                Password
                                            </Label>
                                            <Input
                                                required
                                                type="password"
                                                className="signUp__input"
                                                placeholder="Password"
                                                name="Password"
                                                onChange={handleChange}
                                            />
                                        </FormGroup>

                                        <Button
                                            className="w-100 mt-3 signUp__button cursor-pointer"
                                            disabled={loading}
                                            type="submit"
                                        >
                                            {loading && modal == false ? (
                                                <Spinner size="md" />
                                            ) : (
                                                "Sign up"
                                            )}
                                        </Button>
                                        <p className="mt-2 fs--18 fw--700 text-center signUp__login">
                                            <Link to="/login">Login</Link>
                                        </p>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal}
                toggle={toggle}
                size="md"
                // centered={true}
            >
                <ModalBody className="px-4 py-5">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            let stringotp = otp.join("");
                            console.log("stringotp", stringotp);
                            let obj = {
                                Otp: stringotp,
                                Email: data.Email,
                            };
                            dispatch(
                                VerifyAction(obj, () => {
                                    history.push("/pricing");
                                    toggle();
                                    setTimer(60);
                                    setOtp(["", "", "", ""]);
                                })
                            );
                        }}
                    >
                        <Row className="my-2">
                            <Col sm="12" className="">
                                <div className="otp-verification__title text-center fs--24 fw--600">
                                    OTP Verification
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" className="text-center">
                                <div className="fs--16 fw--500 otp-verification__title py-2 ">
                                    Time Remaining: {timer} seconds
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-2 justify-content-center">
                            <Col
                                xs="10"
                                className="d-flex justify-content-center"
                            >
                                {otp?.map((digit, index) => (
                                    <Input
                                        type="text"
                                        maxLength="1"
                                        className="signUp__inpu mx-1  w-25"
                                        key={index}
                                        id={`otp-input-${index}`}
                                        value={digit}
                                        onChange={(e) =>
                                            handleOtpChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                    />
                                ))}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <div className="text-right">
                                    <Button
                                        className="button-design cursor-pointer text-white fs--16  fw--400 px-5 py-2 mr-2"
                                        type="submit"
                                    >
                                        {" "}
                                        {loading ? <Spinner /> : "Verify"}
                                    </Button>
                                    <Button
                                        className="bg-primary button-design  cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTimer(60);
                                            toggle();
                                            setOtp(["", "", "", ""]);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};
