import React, { useRef } from "react";
import { Col, Row } from "reactstrap";
import upload from "../assets/img/upload.png";
import defaultImg from "../assets/img/defaultImg.png";
import { useDispatch, useSelector } from "react-redux";
import { ebayImagesAction } from "../store/actions/getEbayAction";
export const EbayListingUpload = ({ selectedImage, setSelectedImage,setApiStatus }) => {
    const fileRef = useRef(null);
    const dispatch = useDispatch();
    const { uid } = useSelector((state) => state.authUser);
    const {loading} = useSelector((state) => state.getEbay);
    const handleChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/gif",
        ]; // Add more image types if needed

        if (file && allowedTypes.includes(file.type)) {
            setSelectedImage(file)
            let payload = {
                UserId: uid,
                Image: file,
            };
            dispatch(ebayImagesAction(payload,()=>{
                setApiStatus(true)
            }));
        } else {
            alert("Please select a valid image file (JPEG, PNG, or GIF)");
        }
    };
    return (
        <Row className="mt-4 flex justify-center">
            <Col
            
                xs={2}
                className="d-flex align-items-center justify-content-end"
            >
                <div className="h-[50px] w-[50px] rounded-lg">
                    {selectedImage == null ? (
                        <img src={defaultImg} />
                    ) : (
                        <img src={URL.createObjectURL(selectedImage)} />
                    )}
                </div>
            </Col>
            <Col xs={4}>
                <div
                    onClick={() => {
                        fileRef.current.click();
                    }}
                    className="ebay-image-upload d-flex justify-content-center align-items-center cursor-pointer"
                >
                    <div className="mx-2">
                        <img src={upload} />
                    </div>
                    <div className="fs-18 fw--400">Upload Image</div>
                </div>
            </Col>
            <input
                type="file"
                disabled={loading}
                // onChange={(e) => {
                //     handleFile(e);
                // }}
                accept="image/*"
                onChange={handleChange}
                ref={fileRef}
                hidden
            ></input>
        </Row>
    );
};
