import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { BRAVE_CHAT_HISTORY, CHAT_HISTORY, SITES_DATA } from "../types";
import { baseURL } from "../../repository/Repository";
var chatting = RepositoryFactory.get("chatting");

export const startChatAction =
    (payload, token, onSuccess) => async (dispatch, getState) => {
        await dispatch(Loader(false));
        console.log("payload-->", payload);

        try {
            const { data } = await chatting.checkUserRep(payload.UserId);

            if (data?.status_code == 200) {
                if (
                    payload?.Category == "Chat" ||
                    payload?.Category == "Document"
                ) {
                    try {
                        await dispatch(Loader(true));
                        const url = `${baseURL}Chat/Start-Chat?Id=${payload.Id}&UserId=${payload.UserId}&Query=${payload.Query}&Category=${payload.Category}&SubCategory=${payload.SubCategory}&Model=${payload.Model}&PluginType=${payload.Plugin}&SaveHistory=${payload.SaveHistory}`;
                        const response = await fetch(url, {
                            method: "POST",
                            // headers: {
                            //     "Content-Type": "multipart/form-data",
                            // },
                            body: payload.formData,
                        });
                        if (!response.ok) {
                            await dispatch(Loader(false));
                            throw new Error(
                                `HTTP error! status: ${response.status}`
                            );
                        }
                        if (payload.Id == -1) {
                            dispatch({
                                type: "CHATBOTReply",
                                payload: {
                                    chatting: [],
                                    chatId: "",
                                },
                            });
                        }
                        const reader = response.body.getReader();

                        const { chat } = getState().chatting;
                        let userAdded = false;
                        let accumulatedAiText = "";
                        let tmpdata = "";
                        while (true) {
                            const { done, value } = await reader.read();
                            console.log("value==>", value);
                            if (done) {
                                break;
                            }
                            accumulatedAiText += new TextDecoder().decode(
                                value
                            );
                            console.log(
                                "accumulatedAiText==>",
                                accumulatedAiText
                            );

                            if (accumulatedAiText.split("1#$@!,").length > 0) {
                                console.log(
                                    "accumulatedAiText...",
                                    accumulatedAiText
                                );
                                let id = accumulatedAiText.split("1#$@!,")[1];
                                console.log("id----", id);
                                accumulatedAiText =
                                    accumulatedAiText.split("1#$@!,")[0];

                                if (payload.Id == -1 && id != undefined) {
                                    onSuccess(id);
                                }
                            }

                            let checkDetail =
                                accumulatedAiText?.split("status_code")[1];
                            if (checkDetail?.length > 0) {
                                let res = JSON.parse(accumulatedAiText);
                                toast.error(res?.detail);
                                await dispatch(Loader(false));
                            } else {
                                console.log(
                                    "accumulatedAiText",
                                    accumulatedAiText
                                );
                                const payloadData = {
                                    User:
                                        chat[chat.length]?.User ||
                                        payload.Query,
                                    AI: accumulatedAiText,
                                };

                                let updatedChatArray = [...chat, payloadData];

                                await dispatch(Loader(false));
                                dispatch({
                                    type: "CHATBOTReply",
                                    payload: {
                                        chatting: updatedChatArray,
                                        chatId: "",
                                    },
                                });
                            }
                        }
                        onSuccess(null);
                    } catch (error) {
                        await dispatch(Loader(false));
                        console.log(
                            "There was a problem with your fetch operation:",
                            error
                        );
                    }
                } else {
                    console.log("else");
                    try {
                        await dispatch(Loader(true));

                        const { data } = await chatting.startChattingRep(
                            payload
                        );
                        console.log(data, "replyfromapi");
                        if (payload.Id == -1) {
                            dispatch({
                                type: "CHATBOTReply",
                                payload: {
                                    chatting: [],
                                    chatId: "",
                                },
                            });
                        }
                        console.log(data, "dataaaaaa");
                        if (data?.status_code == 200) {
                            const { chat } = getState().chatting;
                            // let payloadData;
                            // if (data?.detail?.response?.result) {
                            //     payloadData = {
                            //         User: payload?.Query,
                            //         AI: data?.detail?.response?.result,
                            //     };
                            // } else {
                            console.log(data?.detail?.image, "Image");
                            let payloadData = {
                                User: payload?.Query,
                                AI: data?.detail?.image
                                    ? data?.detail?.image
                                    : data.detail.response,
                            };
                            // }

                            const updatedChatArray = [...chat, payloadData];
                            console.log(updatedChatArray, "updatedChatArray");
                            dispatch({
                                type: "CHATBOTReply",
                                payload: {
                                    chatting: updatedChatArray,
                                    chatId: "",
                                },
                            });

                            if (payload.Id == -1) {
                                onSuccess(data?.detail?.chatId);
                            }
                            onSuccess(null);
                            dispatch(Loader(false));
                        } else {
                            await dispatch(Loader(false));

                            toast.warn(data?.detail);
                            dispatch(Loader(false));
                        }
                    } catch (error) {
                        await dispatch(Loader(false));

                        console.error("Error logging out:", error.message);
                        dispatch(Loader(false));
                    }
                }
            } else {
                console.log("idr agia");
                await dispatch(Loader(false));
                onSuccess("not exist");
            }
        } catch (err) {
            await dispatch(Loader(false));
            toast.error(err.message);
        }
    };
export const braveSearchChatAction =
    (payload, onSuccess) => async (dispatch, getState) => {
        await dispatch(Loader(false));
        console.log("payload-=", payload);

        try {
            const { data } = await chatting.checkUserRep(payload.UserId);

            if (data?.status_code == 200) {
                console.log("else");
                try {
                    await dispatch(Loader(true));
                    const { data } = await chatting.chatBraveSearchRep(payload);

                    if (data?.status_code == 200) {
                        let payloadData = {
                            User: payload?.Query,
                            AI: data?.detail?.image
                                ? data?.detail?.image
                                : data.detail.response,
                        };

                        dispatch({
                            type: "CHATBOT_BRAVESEARCH",
                            payload: payloadData,
                        });
                        onSuccess(null);
                        dispatch(Loader(false));
                    } else {
                        await dispatch(Loader(false));

                        toast.warn(data?.detail);
                        dispatch(Loader(false));
                    }
                } catch (error) {
                    await dispatch(Loader(false));

                    console.error("Error logging out:", error.message);
                    dispatch(Loader(false));
                }
            } else {
                await dispatch(Loader(false));
                onSuccess("not exist");
            }
        } catch (err) {
            await dispatch(Loader(false));
            toast.error(err.message);
        }
    };
export const chatHistoryAction = (email, onSuccess) => async (dispatch) => {
    try {
        const { data } = await chatting.historyChatRep(email);
        console.log("chat data", data);

        console.log(data, "data");
        if (data?.Detail?.length > 0) {
            dispatch({
                type: CHAT_HISTORY,
                payload: data,
            });
            onSuccess();
        } else {
            dispatch({
                type: CHAT_HISTORY,
                payload: [],
            });
        }
    } catch (error) {
        console.error("Error logging out:", error.message);
    }
};

export const getBraveSearchHistory = (payload, status) => async (dispatch) => {
    try {
        if (status) {
            dispatch({
                type: "CHATBOT_BRAVESEARCH",
                payload: {
                    User: "",
                    AI: "",
                },
            });
        }

        const { data } = await chatting.getBraveSearchHistoryRep(payload);

        console.log(data, "data");
        if (data?.detail?.length > 0) {
            dispatch({
                type: BRAVE_CHAT_HISTORY,
                payload: data?.detail,
            });
        } else {
            dispatch({
                type: BRAVE_CHAT_HISTORY,
                payload: [],
            });
        }
    } catch (error) {
        console.error("Error logging out:", error.message);
    }
};

export const chatDetailAction = (id, onSuccess) => async (dispatch) => {
    try {
        const { data } = await chatting.chatDetailRep(id);
        console.log("chat data detail---", data);
        console.log(data, "data");
        if (data?.Detail.History?.history.length > 0) {
            dispatch({
                type: "CHATBOTReply",
                payload: {
                    chatting: data?.Detail.History?.history,
                    chatId: data.Detail.Id,
                },
            });

            dispatch(Loader(false));
        } else {
            alert("Sorry Response Failed!");
            dispatch(Loader(false));
        }
        // if (data?.length > 0) {
        //     dispatch({
        //         type: CHAT_HISTORY,
        //         payload: data,
        //     });
        //     onSuccess();
        // } else {
        //     toast.error("Sorry Response Failed!");
        // }
    } catch (error) {
        console.error("Error logging out:", error);
    }
};

export const chatDeleteAction =
    (id, category, onSuccess) => async (dispatch) => {
        try {
            await dispatch(DeleteLoader(true));
            const { data } = await chatting.deleteChatHistory(id, category);
            console.log("chat data history", data);
            console.log(data, "data");
            if (data?.status_code == 200) {
                toast.success("Chat History Deleted Successfully!");
                onSuccess();
                await dispatch(DeleteLoader(false));
            } else {
                console.log(data?.detail);
                await dispatch(DeleteLoader(false));
            }
        } catch (error) {
            console.error("delete:", error?.message);
            await dispatch(DeleteLoader(false));
        }
    };

export const getSitesAction = (id) => async (dispatch) => {
    try {
        const { data } = await chatting.getSitesRep(id);

        if (
            data?.detail?.length > 0 &&
            data?.detail != "User Id Invalid" &&
            data?.detail != "Site Filter Not Found"
        ) {
            dispatch({
                type: SITES_DATA,
                payload: data?.detail,
            });
        } else {
            toast.warn(data?.detail);
        }
    } catch (error) {
        console.error("Error logging out:", error.message);
    }
};

export const saveSitesAction = (payload, onSuccess) => async (dispatch) => {
    try {
        await dispatch(DeleteLoader(true));
        const { data } = await chatting.addSitesRep(payload);

        if (data?.status_code == 200) {
            toast.success("settings saved!");
            onSuccess();
            await dispatch(DeleteLoader(false));
        } else {
            await dispatch(DeleteLoader(false));
        }
    } catch (error) {
        await dispatch(DeleteLoader(false));
        console.error("Error logging out:", error.message);
    }
};

const Loader = (data) => async (dispatch) => {
    dispatch({
        type: "LOADER",
        payload: data,
    });
};
const DeleteLoader = (data) => async (dispatch) => {
    dispatch({
        type: "DELETE_LOADER",
        payload: data,
    });
};
