import Repository from "./Repository";
const GET_EBAY = "/Chat/Get-Ebay-Items-By-Keywords/";
const EBAY_IMAGES = "/Ebay/Search-Image-Google-Lens";
const SEND_CODE = "/Ebay/Save-Ebay-Access-Token";
const GET_EBAY_TOKEN = "/Ebay/Verify-OAuth-Access-Token-Expiration?UserId=";
const GET_EBAY_JSON_DATA = "/Ebay/Get-Serp-Api-Response?ResponseId=";
const EBAY_AI_DESCRIPTION = "/Ebay/Generate-AI-Description"
const GET_EBAY_CATEGORY = "/Ebay/Get-Ebay-Categories?UserId="
const CREATE_EBAY_LISTING = "/Ebay/Create-Ebay-Listing"
const GET_CONDITION = "/Ebay/Get-Condition-IDs?UserId=";
const GET_SHIPPING = "/Ebay/Get-Ebay-Shipping-Details?UserId="
const GET_COUNTRY = "/Ebay/Get-Country-Details?UserId="
const LOGOUT_EBAY="/Ebay/Delete-Ebay-Access-Token?UserId="
const EBAY_ITEM_SPECIFIC="/Ebay/Get-Ebay-Category-Details?UserId="
export default {
    getEbayItemSpecific(payload){
        return Repository.post(`${EBAY_ITEM_SPECIFIC}${payload.userId}&CategoryId=${payload.categoryId}`);
    },

    getEbay(payload) {
        return Repository.get(`${GET_EBAY}${payload}`);
    },
    logoutEbay(id){
        return Repository.delete(`${LOGOUT_EBAY}${id}`);
    },
    getShippingDetail(payload) {
        return Repository.get(`${GET_SHIPPING}${payload.userId}&SiteId=${payload.siteId}`)

    },
    getCountry(id) {
        return Repository.get(`${GET_COUNTRY}${id}`)
    },
    createEbayListing(payload) {
        return Repository.post(`${CREATE_EBAY_LISTING}`, payload);
    },
    getEbayJson(id) {
        return Repository.post(`${GET_EBAY_JSON_DATA}${Number(id)}`);
    },
    ebayImagesRep(payload) {
        let form = new FormData();
        form.append("Image", payload.Image);
        return Repository.post(`${EBAY_IMAGES}?UserId=${payload.UserId}`, form);
    },
    getEbayCategory(payload) {
        return Repository.get(`${GET_EBAY_CATEGORY}${payload.id}&Keyword=${payload.keyword}`);
    },
    sendEbayCode(data) {
        return Repository.post(`${SEND_CODE}`, data);
    },
    getEbayToken(id) {
        return Repository.get(`${GET_EBAY_TOKEN}${id}`);
    },
    ebayDescriptionRep(payload) {
        return Repository.post(`${EBAY_AI_DESCRIPTION}`, payload);
    },
    getConditionRep(payload) {
        return Repository.get(`${GET_CONDITION}${payload.userId}&CategoryId=${payload.categoryId}`)
    }
};

