import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var checkout = RepositoryFactory.get("checkout");

export const checkoutAction = (payload, onSuccess) => async (dispatch) => {
    try {
        const { data } = await checkout.addPayment(payload);
        if (data?.success) {
            toast.success(data?.message);

            onSuccess(true);
        } else {
            toast.error(data?.message);
            onSuccess(false);
        }
    } catch (error) {
        console.error("Error logging out:", error);
    }
};
// const getToken = () => {
//     try {
//         const tokenString = JSON.parse(localStorage.getItem("access_token"));
//         if (tokenString) {
//             return tokenString;
//         }
//     } catch (error) {
//         console.log("Error parsing localStorage:", error);
//     }
//     return "";
// };
