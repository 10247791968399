import React from "react";
import { Col, Row } from "reactstrap";
import userchat from "../assets/img/userchat.jpeg";
import botchat from "../assets/img/botchat.jpeg";
import WebSearchCard from "./WebSearchCard";
import { WebSearchDataAI } from "./WebSearchDataAI";
import BraveSearchImages from "./BraveSearchImages";

export const BraveSearchMessage = ({
    chats,
    handleShowMore,
    visibleItems,
    data,
    item,
}) => {
    return (
        <>
            <div>
                <Row className="py-3 mx-2 flex justify-center ">
                    <Col xs="10">
                        <Row>
                            <Col xs="12" md="11" sm="11" className="px-0">
                                <div className="px-1">
                                    <p className="chat-message-user">
                                        {typeof chats?.AI === "string" &&
                                        chats?.AI.startsWith("http") ? (
                                            <img
                                                src={chats.AI}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        ) : typeof chats?.AI === "string" &&
                                          !chats?.AI.startsWith("http") ? (
                                            <>
                                                <div
                                                    style={{
                                                        whiteSpace: "pre-wrap",
                                                    }}
                                                >
                                                    {
                                                        chats?.AI?.split(
                                                            "##end##"
                                                        )[0]
                                                    }
                                                </div>
                                            </>
                                        ) : chats ? (
                                            <>
                                                <div className="my-5">
                                                    {chats?.AI?.web
                                                        ?.slice(0, visibleItems)
                                                        ?.map((item, idx) => (
                                                            <>
                                                                <WebSearchCard
                                                                    item={item}
                                                                />
                                                            </>
                                                        ))}
                                                </div>

                                                {visibleItems <
                                                    (chats?.AI?.web?.length ||
                                                        0) && (
                                                    <>
                                                        <div className="text-center">
                                                            <button
                                                                className=" px-3 py-2 border rounded-[12px] cursor-pointer"
                                                                onClick={
                                                                    handleShowMore
                                                                }
                                                            >
                                                                Show More
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                                <>
                                                    {chats?.AI?.type ==
                                                        "summarizer" &&
                                                        chats?.AI?.results
                                                            // ?.slice(
                                                            //     0,
                                                            //     visibleItemsMap +
                                                            //         3
                                                            // )
                                                            ?.map(
                                                                (item, idx) => (
                                                                    <>
                                                                        <div className="mb-3 ">
                                                                            <WebSearchDataAI
                                                                                item={
                                                                                    item
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            )}

                                                    {/* {visibleItemsMap <
                                                        (chats?.AI?.results
                                                            ?.length > 3 ||
                                                            0) && (
                                                        <>
                                                            <div className="text-center">
                                                                <button
                                                                    className=" px-3 py-2 rounded-[12px] cursor-pointer"
                                                                    onClick={() =>
                                                                        handleShowMoreForIndex
                                                                    }
                                                                >
                                                                    Show More
                                                                </button>
                                                            </div>
                                                        </>
                                                    )} */}
                                                </>
                                                <Row>
                                                    {chats?.AI?.Image?.length >
                                                        0 &&
                                                        chats?.AI?.Image?.map(
                                                            (item, index) => (
                                                                <>
                                                                    <Col lg="4">
                                                                        <BraveSearchImages
                                                                            key={
                                                                                index
                                                                            }
                                                                            item={
                                                                                item
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </>
                                                            )
                                                        )}
                                                </Row>
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};
