import { Link, useHistory } from "react-router-dom";

import { LinkPreview } from "@dhaiwat10/react-link-preview";
import _debounce from "lodash/debounce";
import "react-link-previewer/src/style.css";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Spinner,
    Button,
    Form,
    Modal,
    ModalBody,
} from "reactstrap";
import { IoDocumentTextOutline, IoSend } from "react-icons/io5";
import { FaFilePdf, FaFilterCircleXmark, FaRobot } from "react-icons/fa6";

import user from "../assets/img/user.png";
import bot from "../assets/img/bot.png";
import userchat from "../assets/img/userchat.jpeg";

import botchat from "../assets/img/botchat.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import {
    chatDeleteAction,
    chatDetailAction,
    chatHistoryAction,
    getSitesAction,
    saveSitesAction,
    startChatAction,
} from "../store/actions/chattingAction";

import { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import { DotPulse, Ring } from "@uiball/loaders";
import { async } from "q";

import { FaUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { useRef } from "react";

import {
    UpdateHistoryStatusAction,
    getCustomizationAction,
    getUserAction,
    logout,
} from "../store/actions/authAction";
import { GrAttachment } from "react-icons/gr";
import DropDownGPT from "../components/DropDownGPT";
import { ChatHistory } from "../components/ChatHistory";
import FilterWebsilteModal from "../components/FilterWebsilteModal";
import ChatbotFooter from "../components/ChatbotFooter";
import { ChatBotInnerDesign } from "../components/ChatBotInnerDesign";
import { ChatBotMessages } from "../components/ChatBotMessages";
import SwitchToggle from "../components/SwitchToggle";
const AdminView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [typeMessage, setTypeMessage] = useState("");
    const [category, setCategory] = useState("Chat");
    const [subCategory, setSubCategory] = useState("WebSearch");
    const [Model, setModel] = useState({
        id: 1,
        text: "GPT-3.5",
        key: "GPT-3.5",
    });

    const [Plugin, setPlugin] = useState("");
    console.log("Pluin", Plugin);
    const [apiUse, setApiUse] = useState("AIPRO");
    const [graphicSelected, setGraphicSelected] = useState("DALL-E 2");

    const { chat, sitesData } = useSelector((state) => state.chatting);

    const { customizationData } = useSelector((state) => state.userData);
    const [chatId, setChatId] = useState(-1);
    // const [chatDetail, setChatDetail] = useState(false);
    const [id, setId] = useState("");

    const { user } = useSelector((state) => state.authUser);

    const { uid } = useSelector((state) => state.authUser);
    const { singleUser } = useSelector((state) => state.userData);

    const [chats, setChats] = useState([]);

    const [filter, setFilter] = useState(false);
    const [detailShow, setDetailShow] = useState(false);
    const [SaveHistory, setSaveHistory] = useState(true);

    const fileRef = useRef(null);
    const [file, setFile] = useState("");

    const [data, setData] = useState({
        SidebarBackgroundColor: "#000",
        SidebarTextColor: "#FFFFFF",
        ChatbotBackgroundColor: "#FFFFFF",
        ChatHistoryBackgroundColor: "#eaeaea",
        ChatbotTextColor: "#000",
        UserMessageColor: "#000",
        ChatbotColor: "#000",
        CategoryTextColor: "#FFFFFF",
        CategoryBackgroundColor: "#30b5f1",
        HeaderBackgroundColor: "#000",
        HeaderTextColor: "#FFFFFF",
    });
    const toggleFilter = () => {
        setFilter(!filter);
    };

    useEffect(() => {
        if (customizationData != null) {
            setData(customizationData);
        } else {
            setData({
                SidebarBackgroundColor: "#000",
                SidebarTextColor: "#FFFFFF",
                ChatbotBackgroundColor: "#FFFFFF",
                ChatHistoryBackgroundColor: "#eaeaea",
                ChatbotTextColor: "#000",
                UserMessageColor: "#000",
                ChatbotColor: "#000",
                CategoryTextColor: "#FFFFFF",
                CategoryBackgroundColor: "#30b5f1",
                HeaderBackgroundColor: "#000",
                HeaderTextColor: "#FFFFFF",
            });
        }
    }, [customizationData]);
    const [logouts, setLogout] = useState(false);
    const logoutToggle = () => {
        setLogout(!logouts);
    };
    const submit = (input) => {
        if (uid) {
            setTypeMessage(input);

            if (input != "" && category != "") {
                console.log("SubCategory", subCategory);
                let obj = {
                    Category: category,
                    SubCategory:
                        category == ("Chat" || "Document")
                            ? ""
                            : category == "Image"
                            ? graphicSelected
                            : subCategory == "WebSearch"
                            ? `${subCategory},${apiUse}`
                            : subCategory,
                    Model: Model?.key,
                    Plugin: Plugin?.key ? Plugin?.key : "",
                    Query: input,
                    Id: Number(chatId),
                    UserId: user?.UserId,
                    SaveHistory,
                };
                if (obj?.Category == "Document") {
                    if (chatId == -1 && file == "") {
                        toast.warn(
                            "No  Document Provided. Please provide some context to start chat with your data."
                        );
                    } else {
                        var formData = new FormData();
                        formData.append("Document", file);

                        let data = {
                            ...obj,
                            formData,
                        };
                        dispatch(
                            startChatAction(data, user.AccessToken, (value) => {
                                console.log("value>>>", value);

                                if (value == "not exist") {
                                    toast.error("User block");
                                    dispatch(
                                        logout(() => {
                                            history.push("/");
                                        })
                                    );
                                } else {
                                    if (value != null) {
                                        // setChatDetail(true);
                                        setChatId(value);

                                        if (value) {
                                            dispatch(
                                                chatHistoryAction(
                                                    user?.Email,
                                                    () => {}
                                                )
                                            );
                                        }
                                    }
                                }
                                setTypeMessage("");
                                dispatch(
                                    chatHistoryAction(user?.Email, () => {})
                                );
                                setFile("");
                                fileRef.current.value = "";
                            })
                        );
                    }
                } else {
                    console.log("obj", obj);
                    var formData = new FormData();
                    formData.append("Document", file);

                    let data = {
                        ...obj,
                        formData,
                    };
                    dispatch(
                        startChatAction(data, user.AccessToken, (value) => {
                            console.log("value", value);

                            if (value == "not exist") {
                                toast.error("User block");
                                dispatch(
                                    logout(() => {
                                        history.push("/");
                                    })
                                );
                            } else {
                                if (value != null) {
                                    // setChatDetail(true);
                                    setChatId(value);

                                    if (value) {
                                        dispatch(
                                            chatHistoryAction(
                                                user?.Email,
                                                () => {}
                                            )
                                        );
                                    }
                                }
                            }

                            setTypeMessage("");
                            dispatch(chatHistoryAction(user?.Email, () => {}));
                            setFile("");
                        })
                    );
                    // let updateChat = { user: input, bot: "Thinking...." };
                    // setChats([...chats, updateChat]);
                }
            } else {
                toast.warn("Please must the required fields!");
            }
        } else {
            toast.warn("First  Login");
        }
    };

    const chatContainerRef = useRef(null);
    const chatBodyRef = useRef(null);
    useEffect(() => {
        setChats(chat);

        if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [chat]);

    useEffect(() => {
        if (user?.Email) {
            dispatch(chatHistoryAction(user?.Email, () => {}));
        }
    }, [user]);

    useEffect(() => {
        if (chatId != -1) {
            // console.log("agya ja", !chatDetail);
            dispatch(chatDetailAction(chatId));
        } else {
            setChats([]);
        }
    }, [chatId]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [toggle, setToggle] = useState(window.innerWidth > 992);

    useEffect(() => {
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            setWindowWidth(newWindowWidth);

            // Update toggle based on window width
            setToggle(newWindowWidth > 992);
        };

        const handleOutsideClick = (e) => {
            // Close the sidebar when clicking outside of it on screens below the medium size
            if (toggle && window.innerWidth <= 992) {
                setToggle(false);
            }
        };
        window.addEventListener("resize", handleResize);
        // document.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [toggle]);

    useEffect(() => {
        if (user?.UserId) {
            dispatch(getCustomizationAction(user?.UserId));
            // dispatch(getSitesAction(user?.UserId));
        }
    }, [user?.UserId]);
    const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "text/plain",
    ];
    const handleFile = (e) => {
        console.log("selectedFile e", e.target.files[0]);
        const selectedFile = e.target.files[0];
        console.log("selectedFile", selectedFile);
        if (selectedFile) {
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
                fileRef.current.value = "";
            } else {
                toast.warn(
                    "Please select a valid document type (pdf, doc, docx, csv, txt)."
                );
            }
        }
    };

    useEffect(() => {
        if (category != "Chat") {
            setModel({
                id: 1,
                text: "GPT-3.5",
                key: "GPT-3.5",
            });
            setPlugin("");
        }
        if (uid) {
            dispatch(
                UpdateHistoryStatusAction(
                    { userId: uid, SaveHistory: true },
                    () => {
                        dispatch(getUserAction(uid));
                    }
                )
            );
        }
    }, [category]);

    useEffect(() => {
        dispatch(getUserAction(uid));
        dispatch(
            UpdateHistoryStatusAction(
                { userId: uid, SaveHistory: true },
                () => {
                    dispatch(getUserAction(uid));
                }
            )
        );
    }, [uid]);
    console.log("singleUser", singleUser);
    useEffect(() => {
        if (singleUser) {
            setSaveHistory(singleUser.SaveHistory);
        }
    }, [singleUser]);

    return (
        <>
            <MainHeader toggle={toggle} setToggle={setToggle} />
            <div className="">
                <Container
                    fluid
                    //className="vh-100 overflow-x-hidden position-relative bg-[#2a2a2a]"
                    className={`vh-100 overflow-x-hidden position-relative `}
                    style={{
                        background: data?.ChatbotBackgroundColor,
                    }}
                >
                    <Row>
                        {toggle && (
                            <Col
                                lg="3"
                                xl="3"
                                className="sidebar pt-1 pb-5"
                                style={{
                                    background: data?.SidebarBackgroundColor,
                                }}
                            >
                                <ChatHistory
                                    chatId={chatId}
                                    setChatId={setChatId}
                                    setSaveHistory={setSaveHistory}
                                    // setChatDetail={setChatDetail}
                                    data={data}
                                    category={category}
                                    setCategory={setCategory}
                                    subCategory={subCategory}
                                    setSubCategory={setSubCategory}
                                    setFile={setFile}
                                    setPlugin={setPlugin}
                                    setChats={setChats}
                                />
                            </Col>
                        )}

                        <Col
                            lg="9"
                            xl="10"
                            className="py-2  main-content"
                            data-aos="fade-up"
                        >
                            {/* dropdown top */}

                            <Row className="mb-2 justify-between flex px-4">
                                {category == "Chat" ||
                                category == "Document" ? (
                                    <>
                                        <Col md="6">
                                            <DropDownGPT
                                                selectedOption={Model}
                                                setSelectedOption={setModel}
                                                Plugin={Plugin}
                                                setPlugin={setPlugin}
                                                category={category}
                                            />
                                        </Col>
                                    </>
                                ) : null}

                                {uid && (
                                    <Col className="justify-end flex">
                                        <div>
                                            <SwitchToggle
                                                setSaveHistory={setSaveHistory}
                                                SaveHistory={SaveHistory}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <Row
                                ref={chatBodyRef}
                                className={`px-2  mx-3 px-md-5 pb-1 mb-1 rounded-[20px]   overflow-auto  ${
                                    chat.length > 0
                                        ? "h-[calc(100vh-calc(20px+280px))]"
                                        : "h-[calc(100vh-calc(20px+280px))]"
                                } `}
                                style={{
                                    background:
                                        data?.ChatHistoryBackgroundColor,
                                }}
                            >
                                <Col
                                    xs="12"
                                    className={`${
                                        chats?.length == 0 &&
                                        chatId == -1 &&
                                        "my-auto"
                                    } pb-5 `}
                                >
                                    {chats?.length == 0 && chatId == -1 ? (
                                        <>
                                            <ChatBotInnerDesign data={data} />
                                        </>
                                    ) : (
                                        <div className="chat-bod mt-3 ">
                                            <ChatBotMessages
                                                chats={chats}
                                                data={data}
                                                chatContainerRef={
                                                    chatContainerRef
                                                }
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            <ChatbotFooter
                                apiUse={apiUse}
                                setApiUse={setApiUse}
                                chatId={chatId}
                                setChatId={setChatId}
                                toggleFilter={toggleFilter}
                                setTypeMessage={setTypeMessage}
                                submit={submit}
                                graphicSelected={graphicSelected}
                                setGraphicSelected={setGraphicSelected}
                                typeMessage={typeMessage}
                                file={file}
                                setFile={setFile}
                                // setChatDetail={setChatDetail}
                                data={data}
                                category={category}
                                setCategory={setCategory}
                                subCategory={subCategory}
                                setSubCategory={setSubCategory}
                                setPlugin={setPlugin}
                                setChats={setChats}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AdminView;
