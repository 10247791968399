export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const LOADER = "LOADER";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const SITES_DATA = "SITES_DATA";
export const GET_TICKET = "GET_TICKET";
export const CREATE_TICKET = "CREATE_TICKET";
export const TICKET_CHAT = "TICKET_CHAT";
export const GET_CHAT = "GET_CHAT";

// EBAY...
export const GET_EBAY = "GET_EBAY";
export const EBAY_LOADING = "EBAY_LOADING";
export const EBAY_IMAGES="EBAY_IMAGES";
export const EBAY_AI_DESC="EBAY_AI_DESC";
export const GET_EBAY_CATEGORY="GET_EBAY_CATEGORY";
export const GET_EBAY_CONDITION="GET_EBAY_CONDITION"


export const SINGLE_USER = "SINGLE_USER";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const NEWS_LOADER = "NEWS_LOADER";
export const GET_ALL_PUBLISH_NEWS = "GET_ALL_PUBLISH_NEWS";
export const BRAVE_CHAT_HISTORY = "BRAVE_CHAT_HISTORY";
export const EBAY_DATA="EBAY_DATA";
export const GET_EBAY_IMAGE="GET_EBAY_IMAGE";
export const DESCRIPTION_AI="DESCRIPTION_AI";
export const CREATE_EBAY_LOADING="CREATE_EBAY_LOADING";
export const GET_SHIPPING="GET_SHIPPING";
export const GET_COUNTRY="GET_COUNTRY";
export const EBAY_ITEM_SPECIFIC="EBAY_ITEM_SPECIFIC"

