import React, { useEffect, useState } from 'react'

import { Row, Col, Input } from 'reactstrap'
import EbayDropDown from './EbayDropDown'
import { useSelector } from 'react-redux'
import Select from 'react-select';
const ItemSpecificRequired = ({formValues, setFormValues}) => {

    console.log("formValues",formValues)

    const {ebaySpecificItem} = useSelector((state) => state.getEbay);


   
    console.log("formValues", formValues)

  
    const handleChange = (name, value) => {
        if (value === null || value === '' ) {
            const updatedFormValues = { ...formValues };
            delete updatedFormValues[name];
            setFormValues(updatedFormValues);
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    };

    const formData = [
        {
            "Name": "Brand",
            "dropDown": true,
            "required": true,
            "value": [
                { "localizedValue": "Unbranded" },
                { "localizedValue": "Apple" },
                { "localizedValue": "Samsung" }
            ]
        },
        {
            "Name": "Model",
            "dropDown": false,
            "required": true,
            "value": ''
        },
        {
            "Name": "Color",
            "dropDown": false,
            "required": false,
            "value": ''
        }
    ];
    const [requiredFields, setRequiredFields] = useState([]);
    const [otherFields, setOtherFields] = useState([]);
  
    useEffect(() => {
      if (ebaySpecificItem?.length > 0) {
        const required = ebaySpecificItem.filter(field => field.required);
        const others = ebaySpecificItem.filter(field => !field.required);
        setRequiredFields(required);
        setOtherFields(others);
      }
    }, [ebaySpecificItem]);

   

    const renderFormField = (field) => {
        const { name
            , dropDown, value,required } = field;
        if (dropDown) {
            return (
                
                <div key={name}>
                    <Row className='justify-between items-center my-2'>
                        <Col md='6'>
                            <div className='text-muted text-lg'>
                                <label>{name}</label>
                            </div>

                        </Col>
                        <Col md='6'>
                            <Select
                            required={required}
                            options={value.map(option => ({ label: option.localizedValue, value: option.localizedValue }))}
                            value={formValues[name] ? { label: formValues[name], value: formValues[name] } : null}
                            onChange={(selectedOption) => handleChange(name, selectedOption ? selectedOption.value : null)}
                                isSearchable
                                isClearable
                            />
                        </Col>

                    </Row>


                </div>
            );
        } else {
            return (
                <div key={name}>
                    <Row className='justify-between items-center my-2'>
                        <Col md='6'>
                            <div className='text-muted text-lg'>
                                <label>{name}</label>
                            </div>
                        </Col>
                        <Col>
                            <Input
                             required={required}
                                type="text"
                                value={formValues[name] || ''}
                                onChange={(e) => handleChange(name, e.target.value)}
                            />
                        </Col>
                    </Row>


                </div>
            );
        }
    };






    return (
        <>
            <div className='main_container'>
                <h4>ITEM SPECIFICS</h4>
                {
                    requiredFields?.length >0 && 
                    <h3 className='my-1'>Required</h3>
                }
                
                
                { requiredFields?.map(renderFormField)}
                {
                    otherFields.length>0  &&
                    <h3 className='my-1 '>Optional</h3>

                }
               
                {otherFields.length>0 && otherFields?.map(renderFormField)}
              
            </div>
            <div>
                <hr style={{ height: '1px' }} />
            </div>
        </>

    )
}

export default ItemSpecificRequired
