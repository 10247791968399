import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import RadioButton from "./RadioButton";
import { PluginDropDown } from "./PluginDropDown";
import { Col, Row } from "reactstrap";
import bravelogo from "../../src/assets/img/bravelogo.png";
import news from "../../src/assets/img/news.jpg";
import pubmed from "../../src/assets/img/pubmed.png";
const dropdownData = [
    {
        id: 1,
        key: "GPT-3.5",
        text: "GPT-3.5",
        // tooltip: "Tooltip for Option 1",
    },
    {
        id: 2,
        key: "GPT-4",
        text: "GPT-4",
        tooltip: "Tooltip for Option 2",
    },
    {
        id: 3,
        key: "Mixtral-8x7b",
        text: "Mixtral-8x7b Uncensored",
        tooltip: "Tooltip for Option 3",
    },
    // {
    //     id: 4,
    //     text: "Add Plugin",
    //     key: "GPT-4",
    //     tooltip: "Tooltip for Option 3",
    // },
];
// const data = {
//     user: 'Abdul',
//     email: 'abdul@gmail.com'
// }
// console.log(data.email)

const DropDownGPT = ({
    Plugin,
    setPlugin,
    selectedOption,
    setSelectedOption,
    category,
}) => {
    console.log(selectedOption);
    const [toggleNav, setToggleNav] = useState(false);
    const toggle = () => {
        setToggleNav(!toggleNav);
    };

    return (
        <>
            <Row className="align-items-center">
                <Col xs="2">
                    <div className="mr-5">
                        <RadioButton
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            dropdownData={dropdownData}
                        />
                    </div>
                </Col>
                {category == "Chat" ? (
                    <>
                        <Col className="ml-5">
                            <div>
                                <PluginDropDown
                                    Plugin={Plugin}
                                    setPlugin={setPlugin}
                                />
                            </div>
                        </Col>
                    </>
                ) : null}
            </Row>
        </>
    );
};

export default DropDownGPT;
