import { toast } from "react-toastify";
import { GET_TICKET, CREATE_TICKET, GET_CHAT, TICKET_CHAT } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

var ticket = RepositoryFactory.get("ticket");

// when we create ticket
export const createTicketAction =
    (payload, formData, onSuccess) => async (dispatch) => {
        try {
            let { data } = await ticket.createTicket(payload, formData);
            if (data?.status_code === 200) {
                dispatch({ type: CREATE_TICKET, payload: data?.detail });
                onSuccess();
                toast.success(data?.detail);
            } else {
                toast.error(data?.detail);
            }
        } catch (e) {
            toast.error(e.message);
        }
    };
// Get ticket details on ID base in Table
export const getTicketDetailsAction = (ID) => async (dispatch) => {
    try {
        let { data } = await ticket.getTicketRepo(ID);
        if (data?.status_code == 200) {
            dispatch({ type: GET_TICKET, payload: data?.detail });
        } else {
            toast.error(data?.Detail);
        }
    } catch (e) {
        toast.error(e.message);
    }
};

// when the user Chat with admin, just post the messages
export const ticketChatAction = (payload, onSuccess) => async (dispatch) => {
    try {
        let { data } = await ticket.ticketChat(payload);
        if (data?.status_code === 200) {
            dispatch({ type: TICKET_CHAT, payload: data?.detail });
            onSuccess();
        } else {
            toast.error(data?.detail);
        }
    } catch (e) {
        toast.error(e.message);
    }
};
// get all chat of admin and users messages
export const getTicketChat = (payload) => async (dispatch) => {
    try {
        let { data } = await ticket.getChat(payload);
        if (data?.status_code == 200) {
            dispatch({ type: GET_CHAT, payload: data?.detail });
        } else {
            toast.error(data?.Detail);
        }
    } catch (e) {
        toast.error(e.message);
    }
};
