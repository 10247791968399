import React from "react";
import {
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Input,
} from "reactstrap";
import { FaCircleCheck } from "react-icons/fa6";

const EbayListingCard = ({ item, isSelected, toggleSelect }) => {
    return (
        <>
            <Card
            className={`my-2 cursor-pointer ebay_card  ${
                isSelected(item?.position) ? "border-2 border-success" : ""
            }`}
            onClick={() => toggleSelect(item?.position, item?.title,item?.thumbnail)}
            >
                <div className="relative h-50">
                    <img alt="Sample"  className="img-fluid h-[200px] object-contain" src={item?.thumbnail} />
                    {isSelected(item?.position) && (
                        <div className="absolute top-0">
                            <FaCircleCheck className="text-success" />
                        </div>
                    )}
                </div>

                <CardBody>
                    <CardTitle tag="h5" className="ebay_image_title">{item?.title}</CardTitle>

                    {/* <CardText className="ebay-listing-box">
                        <Input
                            type="textarea"
                            placeholder="Write Description"
                        ></Input>
                    </CardText> */}
                    {/* <Button>Button</Button> */}
                </CardBody>
            </Card>
        </>
    );
};

export default EbayListingCard;
