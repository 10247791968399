import React from "react";

const WebSearchCard = ({ item }) => {
    return (
        <>
            <div className="mb-5   pb-2">
                <div>
                    <div className="block md:flex items-center mb-3">
                        <span>
                            {item?.profile?.img && (
                                <div className="h-10 w-10 rounded-full p-2 bg-slate-100">
                                    <img
                                        className=" rounded-full img-fluid "
                                        src={item?.profile?.img}
                                        alt="Sunset in the mountains"
                                    />
                                </div>
                            )}
                        </span>
                        <div className="mx-2">
                            <div>{item?.title} </div>
                            <div
                                className=" cursor-pointer text-sm"
                                style={{
                                    wordBreak: "break-word",
                                }}
                            >
                                {item?.url}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <span className=" cursor-pointer">
                        <a
                            style={{
                                wordBreak: "break-word",
                            }}
                            className="url-text"
                            href={item?.url}
                            target="_blank" // Add this line to open the link in a new tab
                            rel="noopener noreferrer" // Add these lines for security reasons
                        >
                            {item?.url}
                        </a>
                    </span>
                </div>
                <div
                    className="text-muted"
                    style={{
                        wordBreak: "break-word",
                    }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: item.description,
                        }}
                    ></span>
                </div>
            </div>
        </>
    );
};

export default WebSearchCard;
