import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
// import blogHead from "../assets/img/news-head.png";
import userImage from "../assets/img/user.jpg";
import BlogCard from "../components/NewsCard";
import NewsCard from "../components/NewsCard";
import { useDispatch, useSelector } from "react-redux";
import { allPublishNewsAction } from "../store/actions/newsAction";

export default function Blog() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(allPublishNewsAction());
    }, []);

    const { newsData } = useSelector((state) => state.news);


    return (
        <div className="vh-100 overflow-auto bg-black ">
            <Container fluid className="blog-container">
                <Row className="d-flex align-items-center  ">

                    {newsData?.length > 0 && newsData?.slice()
                        ?.sort((a, b) => b.Id - a.Id)?.map((items) => {
                            return (
                                <>
                                    <Col md="4" className="my-2">

                                        <div className="main_blog_news">

                                            <NewsCard newsData={items?.News?.AIResponse} />

                                        </div>

                                    </Col>
                                </>
                            );
                        })}












                    {/* <Col md={6} className="my-5">
                        <h2 className="text-light text-center mt-5 fs--30 fw--800">
                            Blog
                        </h2>
                        <article class="flex flex-col items-start justify-between rounded-lg p-3 transition-all duration-300 hover:bg-white/5">
                            <div class="relative w-full">
                                <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                                <img
                                    src={blogHead}
                                    alt=""
                                    class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                            </div>
                            <div class="max-w-xl">
                                <div class="mt-4 flex items-center gap-x-2 text-xs sm:mt-6 sm:text-sm">
                                    <time
                                        datetime="July 17th, 2023"
                                        class="text-gray-300"
                                    >
                                        July 17th, 2023
                                    </time>
                                    <p class="relative z-10 rounded-full bg-gray-300 px-2 py-0.5 font-medium text-gray-600">
                                        Tech
                                    </p>
                                </div>
                                <div class="group relative">
                                    <h3 class="mt-2 text-lg font-semibold leading-6 text-white sm:mt-4">
                                        <span class="absolute inset-0"></span>
                                        Understanding OpenTruth: How we build AI
                                        agents that reason, remember, and
                                        perform.
                                    </h3>
                                </div>
                                <div class="relative mb-10 mt-4 flex items-center gap-x-2 sm:mt-6">
                                    <img
                                        src={userImage}
                                        alt=""
                                        class="h-8 w-8 rounded-full bg-gray-100 sm:h-10 sm:w-10"
                                    />
                                    <div class="text-sm leading-6">
                                        <div class="font-semibold text-white">
                                            <p>
                                                <span class="absolute inset-0"></span>
                                                Arthur Riechert
                                            </p>
                                        </div>
                                        <p class="text-gray-300">Writer</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}
