import { NEWS_LOADER, GET_ALL_PUBLISH_NEWS } from "../types";
const initialState = {
    newsData: [],
    loading: false,
};
const newsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case NEWS_LOADER:
            return {
                ...state,
                loading: payload,
            };
        case GET_ALL_PUBLISH_NEWS:
            return {
                ...state,
                newsData: payload,
            };

        default:
            return state;
    }
};

export default newsReducer;
