import Repository from "./Repository";
const SIGNUP = "user/signup";
const LOGIN = "/User/Login";
const VERIFY = "user/approve-email?OTP=";
// const JOIN = "/waitList/Submit-Form";
const JOIN = "WaitList/Submit-Form";
const UPDATE_PASSWORD = "User/Update-User-Password?Email=";
const ADD_CUSTOMIZATION = "/User/Add-Customization";
const UPDATE_CUSTOMIZATION = "/User/Update-Customization";
const GET_CUSTOMIZATION = "/User/Get-Customization";
const GET_USER = "/User/Get-User-By-Id?UserId=";
const CHANGE_HISTORY_STATUS = "/User/Change-User-Save-History?UserId=";
const FORGET_PASSWORD = "/User/Password-Reset-OTP?Email=";
const CONFIRM_PASSWORD = "/User/Confirm-Password-Reset-OTP?Email=";
const RESET_PASSWORD = "/User/Reset-Password?Email=";
export default {
    UpdatePasswordRep(payload) {
        return Repository.put(
            `${UPDATE_PASSWORD}${payload.Email}&OldPassword=${payload.OldPassword}&NewPassword=${payload.NewPassword}`
        );
    },

    // This repository is for Forget Password .
    ForgetPasswordRep(email) {
        return Repository.post(`${FORGET_PASSWORD}${email}`);
    },
    // This repo is for confirm password
    ConfirmPasswordRep(payload) {
        return Repository.post(`${CONFIRM_PASSWORD}`, payload);
    },
    // This repo is for Reset-password

    ResetPasswordRep(payload) {
        return Repository.post(
            `${RESET_PASSWORD}${payload.email}&Password=${payload.password}`
        );
    },

    UpdateHistoryStatusRep(payload) {
        return Repository.put(
            `${CHANGE_HISTORY_STATUS}${payload.userId}&SaveHistory=${payload.SaveHistory}`
        );
    },
    signuprep(payload) {
        return Repository.post(`${SIGNUP}`, payload);
    },
    loginrep(payload) {
        return Repository.post(`${LOGIN}`, payload);
    },
    joinRep(payload) {
        return Repository.post(`${JOIN}`, payload);
    },
    verifyrep(payload) {
        return Repository.post(
            `${VERIFY}${payload.Otp}&Email=${payload.Email}`
        );
    },
    addCustomizationRep(payload) {
        return Repository.post(`${ADD_CUSTOMIZATION}`, payload);
    },
    updateCustomizationRep(payload) {
        return Repository.put(`${UPDATE_CUSTOMIZATION}`, payload);
    },
    getCustomizationRep(id) {
        return Repository.get(`${GET_CUSTOMIZATION}?UserId=${id}`);
    },
    getUserRep(id) {
        return Repository.get(`${GET_USER}${id}`);
    },
};
