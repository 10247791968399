import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoOpenTruth from "../../assets/img/logoOpenTruth.png";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    LoginAction,
    ForgetPasswordAction,
    ConfirmPasswordAction,
    ResetPasswordAction,
} from "../../store/actions/authAction";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";

export const Login = () => {
    const { loading } = useSelector((state) => state.userData);
    console.log("loading--", loading);
    const [checkStatus, setCheckStatus] = useState("Forget Password");
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [emailForPasswordReset, setEmailForPasswordReset] = useState("");
    const [otpForPasswordReset, setOtpForPasswordReset] = useState("");
    const [passwordShow, setPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [newPasswordForPasswordReset, setNewPasswordForPasswordReset] =
        useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        Email: "",
        Password: "",
        IsAdmin: false,
    });
    const toggleForgotPasswordModal = () => {
        setForgotPasswordModal(!forgotPasswordModal);
    };

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(
            LoginAction(data, () => {
                console.log("helo");
                history.push("/pricing");
            })
        );
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        if (checkStatus == "Forget Password") {
            dispatch(
                ForgetPasswordAction(emailForPasswordReset, (value) => {
                    setCheckStatus(value);
                })
            );
        } else if (checkStatus == "Verify OTP") {
            let data = {
                Email: emailForPasswordReset,
                OTP: otpForPasswordReset,
            };
            dispatch(
                ConfirmPasswordAction(data, (value) => {
                    setCheckStatus(value);
                })
            );
        } else if (checkStatus == "New Password") {
            let data = {
                email: emailForPasswordReset,
                password: newPasswordForPasswordReset,
            };
            dispatch(
                ResetPasswordAction(data, (value) => {
                    setCheckStatus(value);
                    handleCancel();
                })
            );
        }

        // const resetPasswordData = {
        //     email: emailForPasswordReset,
        //     otp: otpForPasswordReset,
        //     newPassword: newPasswordForPasswordReset,
        // };
    };

    const handleCancel = () => {
        setEmailForPasswordReset("");

        setNewPasswordForPasswordReset("");
        setOtpForPasswordReset("");
        setCheckStatus("Forget Password");
        toggleForgotPasswordModal();
    };

    return (
        <>
            <Container fluid className="auth  bg-black">
                <Row className="justify-content-center  py-3  signUp   ">
                    <Col md="5">
                        <Card className="signUp__card shadow p-3 mb-3 bg-white">
                            {/* <div className="text-center my-3">
                                <img
                                    className="signUp__im img-fluid"
                                    src={logoOpenTruth}
                                />
                            </div> */}
                            <div className="fs--24 fw--700 signUp__title text-center">
                                Login
                            </div>

                            <CardBody>
                                <Form onSubmit={handleRegister}>
                                    <FormGroup>
                                        <Label className="signUp__label">
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            name="Email"
                                            placeholder="Email"
                                            className="signUp__input"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mt-2">
                                        <Label className="signUp__label">
                                            Password
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                required
                                                type={`${
                                                    passwordShow
                                                        ? "text"
                                                        : "password"
                                                }`}
                                                className="signUp__input"
                                                placeholder="Password"
                                                name="Password"
                                                onChange={handleChange}
                                            />
                                            <InputGroupText>
                                                {passwordShow ? (
                                                    <>
                                                        <IoEye
                                                            className="fs--18 cursor-pointer"
                                                            onClick={() => {
                                                                setPasswordShow(
                                                                    !passwordShow
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <IoMdEyeOff
                                                            className="fs--18 cursor-pointer"
                                                            onClick={() => {
                                                                setPasswordShow(
                                                                    !passwordShow
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </InputGroupText>
                                        </InputGroup>
                                    </FormGroup>

                                    <Button
                                        className="w-100 mt-3 signUp__button cursor-pointer"
                                        disabled={loading}
                                        type="submit"
                                    >
                                        {loading ? (
                                            <Spinner size="md" />
                                        ) : (
                                            "Login"
                                        )}
                                    </Button>

                                    <p
                                        className="mt-2 fs--14 text-end px-3 text-primary signUp__login cursor-pointer"
                                        onClick={toggleForgotPasswordModal}
                                    >
                                        Forget Password
                                    </p>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    isOpen={forgotPasswordModal}
                    size="md"
                    centered={true}
                    className="modal"
                >
                    {/* <ModalHeader toggle={toggleForgotPasswordModal}>
                        {checkStatus}
                    </ModalHeader> */}

                    <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
                        <div className="fs--18 fw--600">{checkStatus}</div>
                        <div className="cursor-pointer" onClick={handleCancel}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                    fill="#ffff"
                                    fill-opacity="0.4"
                                />
                                <path
                                    d="M9.10205 14.7568L14.8977 9.24228"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>

                    <Form onSubmit={handleForgotPassword}>
                        <ModalBody>
                            {checkStatus == "Forget Password" && (
                                <FormGroup>
                                    <Label>Email</Label>

                                    <Input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={emailForPasswordReset}
                                        onChange={(e) =>
                                            setEmailForPasswordReset(
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                </FormGroup>
                            )}
                            {checkStatus == "Verify OTP" && (
                                <FormGroup>
                                    <Label>Verify OTP</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter OTP sent to your Email"
                                        value={otpForPasswordReset}
                                        required
                                        onChange={(e) =>
                                            setOtpForPasswordReset(
                                                e.target.value
                                            )
                                        }
                                    />
                                </FormGroup>
                            )}
                            {checkStatus == "New Password" && (
                                <FormGroup>
                                    <Label>New Password</Label>
                                    <InputGroup>
                                        <Input
                                            required
                                            type={`${
                                                newPasswordShow
                                                    ? "text"
                                                    : "password"
                                            }`}
                                            max={7}
                                            placeholder="Enter your new password"
                                            value={newPasswordForPasswordReset}
                                            onChange={(e) =>
                                                setNewPasswordForPasswordReset(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputGroupText>
                                            {newPasswordShow ? (
                                                <>
                                                    <IoEye
                                                        className="fs--18 cursor-pointer"
                                                        onClick={() => {
                                                            setNewPasswordShow(
                                                                !newPasswordShow
                                                            );
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <IoMdEyeOff
                                                        className="fs--18 cursor-pointer"
                                                        onClick={() => {
                                                            setNewPasswordShow(
                                                                !newPasswordShow
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" className="signUp__button">
                                {loading ? <Spinner size="md" /> : "Save"}
                            </Button>
                            <Button
                                className="bg-danger"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
        </>
    );
};
