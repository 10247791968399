import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import logoOpenTruth from "../assets/img/logoOpenTruths.png";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CiMail } from "react-icons/ci";
import { LiaGreaterThanSolid } from "react-icons/lia";

export default function Home() {
    const history = useHistory();
    const { uid } = useSelector((state) => state.authUser);
    // useEffect(() => {
    //     if (uid) {
    //         history.push("/mainview");
    //     }
    // }, [uid]);
    return (
        <Container fluid className="home-container overflow-auto 100-vh">
            <Row>
                <Col>
                    <Container className="vh-100">
                        <Row className="mt-md-5 mt-2 ">
                            <Col className="d-md-none d-block">
                                <div className="d-flex align-items-center justify-content-center  pt-2">
                                    <img
                                        src={logoOpenTruth}
                                        className="img-fluid rotating-image"
                                    />
                                </div>
                            </Col>
                            <Col
                                md={6}
                                className="mt-3  mt-md-5 d-flex align-items-center justify-content-center"
                            >
                                <div
                                    data-aos="fade-left"
                                    className="mt-md-5 text-center"
                                >
                                    <h6 className="text-light  heading">
                                        <span className="text-open-truth  bg-gradient-to-r from-[#8e5433] to-[#c88c45] bg-clip-text text-transparent">
                                            OPEN TRUTH
                                        </span>{" "}
                                        <div className="heading-sub">
                                            {" "}
                                            <span className="text-open-truth__center mr-2">
                                                Create,
                                            </span>{" "}
                                            <span className="text-open-truth__center  ">
                                                Explore &
                                            </span>
                                        </div>
                                        <div className=" text-open-truth__discover ">
                                            Research
                                        </div>
                                    </h6>
                                    <p className="text-open-truth__description  text-light mt-2 para">
                                        Participate in creating AI *systems*
                                        <br />
                                        with your live feedback,
                                        <br />
                                        while empowering the best of humanity.
                                    </p>
                                    <div
                                        onClick={() => {
                                            history.push("/join-waitList");
                                        }}
                                        className="cursor-pointer d-flex mt-3 mt-md-2 pt-md-5   justify-content-center align-items-center"
                                    >
                                        <div>
                                            <div className="d-flex rounded-pill align-items-center text-white fs--24 fw--600 active:text-white hover:text-white text-md lg:text-xl px-2   px-md-4 lg:px-5 py-2  py-md-3 mt-4 button-join cursor-pointer hover:shadow-lg border-white/20  transition-all">
                                                <div>
                                                    <CiMail className="fs--22  fw--700 " />
                                                </div>{" "}
                                                <div className="mx-2">
                                                    Join WaitList
                                                </div>
                                                <div>
                                                    <LiaGreaterThanSolid />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="">
                                            <Button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (user?.user_id) {
                                                        history.push(
                                                            "/mainview"
                                                        );
                                                    } else {
                                                        history.push("/signup");
                                                    }
                                                }}
                                                className="fs--24 fw--600 active:text-white hover:text-white text-md lg:text-xl   px-4 lg:px-5 py-3 mt-4 button-design cursor-pointer "
                                            >
                                                Try Demo
                                            </Button>

                                            <Button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push("/signup");
                                                }}
                                                className="ml-5 fs--24 fw--600 active:text-white hover:text-white text-md lg:text-xl   px-5 lg:px-5 py-3 px-4 mt-4 button-design-signup cursor-pointer "
                                            >
                                                Sign Up
                                            </Button>
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="d-none d-md-block">
                                <div className="d-flex align-items-center justify-content-center  pt-4">
                                    <img
                                        src={logoOpenTruth}
                                        className="img-fluid rotating-image"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}
