// Chat.js

import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Input,
    Button,
    InputGroupAddon,
    InputGroup,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { IoSend } from "react-icons/io5";
import { ticketChatAction, getTicketChat } from "../store/actions/ticketAction";

import { useParams } from "react-router-dom";

const Chat = ({ data }) => {
    const dispatch = useDispatch();
    const { getChat } = useSelector((state) => state.ticket);
    const { user } = useSelector((state) => state.authUser);
    const { id } = useParams();

    const [newMessage, setNewMessage] = useState("");

    useEffect(() => {
        let payload = {
            TicketId: id,
        };
        dispatch(getTicketChat(payload));
    }, []);

    const handleSendMessage = () => {
        if (newMessage.trim() === "") return;
        let payload = {
            TicketId: id,
            Role: user.Role,
            Message: newMessage,
        };
        dispatch(
            ticketChatAction(payload, () => {
                let ticketId = {
                    TicketId: id,
                };
                dispatch(getTicketChat(ticketId));
                setNewMessage("");
            })
        );
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSendMessage();
        }
    };
    return (
        <Container>
            <Row className="mt-5">
                <Col md={8} className="mx-auto">
                    <div className="chat-box">
                        <Row className="border bg-dark text-white m-auto py-2">
                            <Col>
                                <div>Ticket Id: {id}</div>
                            </Col>
                        </Row>
                        <div
                            style={{
                                height: "300px",
                                overflowY: "auto",
                                border: "1px solid #ddd",
                                padding: "15px",
                                // width:"500px"
                            }}
                            className="messages border pt-2"
                        >
                            {getChat?.map((item, index) => (
                                <div
                                    className={` pt-3 mt-2 mb-2
                                ${
                                    item.Role === "user"
                                        ? "text-end"
                                        : "text-start"
                                }`}
                                    key={index}
                                >
                                    <small
                                        className={`
                                ${
                                    item.Role === "user"
                                        ? "user-message bg-secondary text-white"
                                        : "admin-message bg-primary text-white"
                                }`}
                                    >
                                        {item.Message}
                                    </small>
                                </div>
                            ))}
                        </div>
                        <InputGroup className="flex items-center">
                            <Input
                                className="rounded shadow-none"
                                type="text"
                                placeholder="Type your message..."
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <InputGroupAddon addonType="append">
                                {newMessage.trim() !== "" && (
                                    <IoSend
                                        className="hover:cursor-pointer text-primary pl-2"
                                        // color="primary"
                                        size={32}
                                        onClick={handleSendMessage}
                                    />
                                )}
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Chat;
