import React from "react";

export const WebSearchDataAI = ({ item }) => {
    return (
        <>
            <div>
                <span
                    className="text-md font-bold"
                    dangerouslySetInnerHTML={{
                        __html: item?.answer?.text,
                    }}
                ></span>
            </div>

            <div
                style={{
                    wordBreak: "break-word",
                }}
                className=" my-3"
            >
                {/* <span className="font-bold  mr-3"> Summary:</span> */}
                <span className="text-justify">{item?.summary}</span>
            </div>
            <div className="">
                <span className="font-bold mr-3">References:</span>

                {item?.references?.map((obj) => (
                    <>
                        <div className="  py-2 ">
                            <div className="flex items-center">
                                <div className="h-10 w-10 rounded-full p-2 bg-slate-100">
                                    <span>
                                        {obj?.img && (
                                            <div className=" ">
                                                <img
                                                    className=" img-fluid "
                                                    src={obj?.img}
                                                    alt="Sunset in the mountains"
                                                />
                                            </div>
                                        )}
                                    </span>
                                </div>
                                <span className="cursor-pointer mx-2">
                                    <a
                                        style={{
                                            wordBreak: "break-word",
                                        }}
                                        className="url-text"
                                        href={obj?.url}
                                        target="_blank" // Add this line to open the link in a new tab
                                        rel="noopener noreferrer" // Add these lines for security reasons
                                    >
                                        {obj?.url}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
};
