import React from "react";

const BraveSearchImages = ({ item }) => {
    return (
        <div className="w-full">
            <div className="m-2  rounded-lg  bg-white shadow-2xl">
                <div className="h-40">
                    <img
                        src={item?.DownloadImage}
                        alt="City"
                        className="h-40 w-full object-cover object-center"
                    />
                </div>
                {/* <div className="h-1/2 p-4">
                    <h3 className="mb-2 text-base font-semibold text-blue-800">
                        <a href="" className="hover:underline">
                            {item.title}
                        </a>
                    </h3>
                    <p className="text-sm font-bold text-orange-500">
                        {item.subtitle} 
                    </p>
                    <div className="flex flex-row justify-between text-xs mt-2">
                        <p>{item.link1Text}</p> 
                        <p>{item.link2Text}</p> 
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default BraveSearchImages;
