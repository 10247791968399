import React, { useMemo, useState } from "react";

import { IoDocumentTextOutline, IoSend } from "react-icons/io5";
import { FaFilePdf, FaFilterCircleXmark, FaRobot } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";
import { MdGraphicEq } from "react-icons/md";
import { SiGooglebigquery } from "react-icons/si";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Spinner,
    Button,
    Form,
    Modal,
    ModalBody,
} from "reactstrap";
import {
    chatDeleteAction,
    chatHistoryAction,
} from "../store/actions/chattingAction";
import HistorySaveModal from "./HistorySaveModal";
import { toast } from "react-toastify";
const initialMessages = [
    {
        id: 1,
        title: "Upload a Document",
        category: "Document",
        icon: <IoDocumentTextOutline className="text-red-500 fs--24" />,
    },
    {
        id: 2,
        title: "Generate A Graphic",
        category: "generateGraphic",
        icon: <MdGraphicEq className="text-blue-600/100 fs--24" />,
    },
    {
        id: 3,
        title: "Brave Uncensored Query",
        category: "BraveSearchAPI",
        icon: <SiGooglebigquery className="text-sky-400 fs--24" />,
    },
];
export const ChatHistory = ({
    data,
    setCategory,
    category,
    setSubCategory,
    subCategory,
    chatId,
    setPlugin,
    setChatId,
    setFile,
    setChats,
    setSaveHistory,
    setChatDetail,
}) => {
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteChat, setDeleteChat] = useState(false);
    const [saveHistoryModal, setSaveHistoryModal] = useState(false);
    const saveHistoryToggle = () => {
        setSaveHistoryModal(!saveHistoryModal);
    };
    const { chat, loading, chattingId, historyChat, delete_loader, sitesData } =
        useSelector((state) => state.chatting);
    const { uid, user } = useSelector((state) => state.authUser);
    const history = useHistory();
    const dispatch = useDispatch();
    const deleteToggle = () => {
        setDeleteChat(!deleteChat);
    };
    const handleDeleteChat = (e) => {
        e.preventDefault();

        dispatch(
            chatDeleteAction(chatId, category, () => {
                deleteToggle();
                dispatch(chatHistoryAction(user?.Email, () => {}));
                setChatId(-1);
                setPlugin("");
                // setChatDetail(false);
                setDeleteTitle("");
            })
        );
    };
    // useMemo(() => {
    //     if (chatId == -1 && uid) {
    //         saveHistoryToggle();
    //     }
    // }, []);
    return (
        <>
            <div className="vh-100  pb-5">
                <div className="h-[180px] ">
                    {initialMessages?.map((item) => {
                        return (
                            <>
                                <div
                                    className=" flex px-2 bg-[#000] my-2  mx-1 py-2 cursor-pointer rounded items-center "
                                    onClick={() => {
                                        if (
                                            item?.category == "BraveSearchAPI"
                                        ) {
                                            if (uid != null) {
                                                history.push("/brave-search");
                                            } else {
                                                toast.error("First Login");
                                            }
                                        } else {
                                            setCategory(item?.category);
                                            // saveHistoryToggle();
                                            {
                                                item?.category == "Document"
                                                    ? setSubCategory("")
                                                    : item?.category ==
                                                      "generateGraphic"
                                                    ? setCategory("Image")
                                                    : setSubCategory(
                                                          "WebSearch"
                                                      );
                                            }
                                        }
                                    }}
                                >
                                    <span
                                        className="mr-2"
                                        // style={{
                                        //     color: data?.CategoryTextColor,
                                        // }}
                                    >
                                        {item?.icon}
                                    </span>
                                    <span
                                        style={{
                                            color: data?.CategoryTextColor,
                                        }}
                                    >
                                        {item?.title}
                                    </span>
                                </div>
                            </>
                        );
                    })}
                    {!uid ? (
                        <>
                            <div
                                onClick={() => {
                                    history.push("/login");
                                }}
                                className={` bg-dark cursor-pointer d-flex align-items-center justify-content-between  px-2  mx-1 py-2 rounded `}
                                style={{
                                    color: data?.SidebarTextColor,
                                }}
                            >
                                <div className="fs--16  fw--600 "> Sign in</div>
                                {/* same */}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    // saveHistoryToggle();
                                    setChatId(-1);
                                    setPlugin("");
                                    // setChatDetail(false);
                                    setChats([]);
                                    setCategory("Chat");
                                    setSubCategory("");
                                    setFile("");
                                }}
                                className={`${
                                    chatId == -1 ? " bg-dark" : " chatting-box"
                                } cursor-pointer d-flex align-items-center justify-content-between  px-2  mx-1 py-2 rounded `}
                                style={{
                                    color: data?.SidebarTextColor,
                                }}
                            >
                                <div className="fs--16  fw--600 ">
                                    {" "}
                                    New Chat
                                </div>
                                <div className="fs--16  fw--600">
                                    <FaPlus />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="h-[calc(100vh-180px)] overflow-auto  pt-4 pb-5 ">
                    {uid &&
                        historyChat.Detail?.slice()
                            ?.sort((a, b) => b.Id - a.Id)
                            ?.map((obj) => (
                                <>
                                    <div
                                        onClick={() => {
                                            setChatId(obj?.Id);
                                            setPlugin("");
                                            // setChatDetail(false);
                                            if (obj?.Category == "Chat") {
                                                setCategory("Chat");
                                                setSubCategory("WebSearch");
                                                setFile("");
                                            } else if (
                                                obj?.Category ==
                                                "BraveSearchAPI"
                                            ) {
                                                setCategory("BraveSearchAPI");
                                                setSubCategory("WebSearch");
                                                setFile("");
                                            } else if (
                                                obj?.Category == "Document"
                                            ) {
                                                setCategory(obj?.Category);
                                                setSubCategory("WebSearch");
                                                setFile("");
                                            }
                                        }}
                                        className={`${
                                            obj?.Id === chatId
                                                ? "text-white bg-dark chatting-box"
                                                : ` chatting-box`
                                        } my-1 py-2 px-2 rounded px-2 mx-1 cursor-pointer`}
                                        style={{
                                            color: data?.SidebarTextColor,
                                        }}
                                    >
                                        <div className="flex justify-between align-center">
                                            <div className="fs--14  fw--400 ">
                                                {obj?.FirstMessage?.slice(
                                                    0,
                                                    30
                                                )}
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setChatId(obj?.Id);
                                                    setPlugin("");
                                                    // setChatDetail(false);
                                                    setDeleteTitle(
                                                        obj?.FirstMessage
                                                    );
                                                    deleteToggle();
                                                    console.log("delete");
                                                }}
                                                className="cursour-pointer"
                                            >
                                                <MdDelete
                                                    className="fs--18"
                                                    style={{
                                                        color: data?.SidebarTextColor,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="fs--12   text-right"
                                            style={{
                                                color: data?.SidebarTextColor,
                                            }}
                                        >
                                            {obj?.DateTime}
                                        </div>
                                        <div className="bg-dark h-25">
                                            <hr />
                                        </div>
                                    </div>
                                </>
                            ))}
                </div>
            </div>

            <Modal
                isOpen={deleteChat}
                toggle={deleteToggle}
                size="md"
                centered={true}
                className="modal"
            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Delete Chat</div>
                    <div className="cursor-pointer" onClick={deleteToggle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <ModalBody className="px-4 py-5">
                    <Row>
                        <Col>
                            <div className="text-center fs--20 fw--500 text-muted  ">
                                Are you sure you want to delete {deleteTitle}?
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <div className="text-right">
                                <Button
                                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                                    onClick={handleDeleteChat}
                                    disabled={delete_loader}
                                >
                                    {delete_loader ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        `${"Yes"}`
                                    )}
                                </Button>
                                <Button
                                    className="bg-gradient-to-t from-sky-500 to-sky-600 program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteToggle();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <HistorySaveModal
                saveHistoryModal={saveHistoryModal}
                setSaveHistory={setSaveHistory}
                saveHistoryToggle={saveHistoryToggle}
            />
        </>
    );
};
