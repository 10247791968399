import AuthView from "../views/auth/AuthView";
import MainView from "../views/MainView";
import Index from "../views";
import Blog from "../views/Blog";
import Pricing from "../views/Pricing";
import { SignUp } from "../views/auth/SignUp";
import { Login } from "../views/auth/Login";
import { JoinWaitList } from "../views/auth/JoinWaitList";
import { UpdatePassword } from "../views/auth/UpdatePassword";
import { ChatbotCustomization } from "../views/auth/ChatbotCustomization";
import TicketDetails from "../views/ticketDetails";
import TicketChat from "../views/ticketChat";
import Ebay from "../views/Ebay";
import BraveSearch from "../views/BraveSearch";
import EbayListing from "../views/EbayListing";
import CompleteListing from "../views/CompleteListing";

let routes = [
    {
        path: "/signup",
        component: SignUp,
        layout: "auth",
    },
    {
        path: "/login",
        component: Login,
        layout: "auth",
    },
    {
        path: "/chatbot-customization",
        component: ChatbotCustomization,
        layout: "auth",
    },
    {
        path: "/update-password",
        component: UpdatePassword,
        layout: "auth",
    },
    {
        path: "/join-waitList",
        component: JoinWaitList,
        layout: "auth",
    },
    {
        path: "/mainview",
        component: MainView,
        layout: "main",
    },
    {
        path: "/brave-search",
        component: BraveSearch,
        layout: "main",
    },
    {
        path: "/ebay-listing",
        component: EbayListing,
        layout: "main",
    },
    {
        path: "/complete-listing",
        component: CompleteListing,
        layout: "main",
    },
    {
        path: "/",
        component: Index,
        layout: "main",
    },
    {
        path: "/news",
        component: Blog,
        layout: "main",
    },
    {
        path: "/pricing",
        component: Pricing,
        layout: "main",
    },
    {
        path: "/ticketDetails",
        component: TicketDetails,
        layout: "main",
    },
    {
        // path: "/ticketChat/:id",
        path: "/ticketChat/:id",
        component: TicketChat,
        layout: "main",
    },
    {
        // path: "/ticketChat/:id",
        path: "/Ebay",
        component: Ebay,
        layout: "main",
    },
];
export default routes;
