import { GET_TICKET, TICKET_CHAT, GET_CHAT, CREATE_TICKET } from "../types";

const initialState = {
    getTicket: [],
    ticketChat: [],
    getChat: [],

    loading: false,
};

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TICKET:
            return {
                ...state,
            };
        case GET_TICKET:
            return {
                ...state,
                getTicket: action.payload,
            };
        case TICKET_CHAT:
            return {
                ...state,
                ticketChat: action.payload,
            };
        case GET_CHAT:
            return {
                ...state,
                getChat: action.payload,
            };
        default:
            return state;
    }
};

export default ticketReducer;
