import React from "react";
import { Col, Row } from "reactstrap";

export const ChatBotInnerDesign = ({ data }) => {
    return (
        <>
            <Row className="justify-content-center   mt-[-40px]">
                <Col lg="5" className="">
                    <div className="py-2 ">
                        <h1
                            className="text-center title fs--30 fw--800"
                            style={{
                                color: data?.ChatbotTextColor,
                            }}
                        >
                            OPEN TRUTH
                        </h1>
                        <p
                            className="text-center mb-0 "
                            style={{
                                color: data?.ChatbotTextColor,
                            }}
                        >
                            Our fastest model, great for the most everyday
                            tasks.
                        </p>
                        <p
                            className="text-center"
                            style={{
                                color: data?.ChatbotTextColor,
                            }}
                        >
                            Available for free and plus users
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-1 chat-initial-body  justify-center">
                <Col lg="4" md="6" className="w-full mt-2">
                    <div className="text-center fs--24 fw--800 ">
                        How can I help you today?
                    </div>
                </Col>
            </Row>
        </>
    );
};
