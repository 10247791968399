import React from "react";
import {
    UpdateHistoryStatusAction,
    getUserAction,
} from "../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const SwitchToggle = ({ SaveHistory, setSaveHistory }) => {
    const dispatch = useDispatch();
    const { uid } = useSelector((state) => state.authUser);
    return (
        <>
            <label className="relative inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    checked={SaveHistory} // Set the 'checked' attribute based on your state
                    onChange={(e) => {
                        dispatch(
                            UpdateHistoryStatusAction(
                                { userId: uid, SaveHistory: e.target.checked },
                                () => {
                                    dispatch(getUserAction(uid));
                                }
                            )
                        );
                    }}
                    className="sr-only peer"
                />
                <div
                    className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 ${
                        SaveHistory
                            ? "peer-checked:bg-blue-600"
                            : "peer-checked:bg-gray-200"
                    } dark:peer-focus:ring-blue-800 dark:border-gray-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                ></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Save Chat History
                </span>
            </label>
        </>
    );
};

export default SwitchToggle;
