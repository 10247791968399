import { EBAY_LOADING, GET_EBAY, EBAY_IMAGES, GET_EBAY_IMAGE, EBAY_DATA, GET_EBAY_CATEGORY, GET_EBAY_CONDITION, DESCRIPTION_AI, CREATE_EBAY_LOADING, GET_SHIPPING, GET_COUNTRY, EBAY_ITEM_SPECIFIC } from "../types";
const initialState = {
    data: [],
    loading: false,
    ebayImagePath: {},
    ebayImageData: [],
    ebayCategory: [],
    ebayCondition:[],
    descriptionAI:null,
    createLoading:false,
    shippingDetail:[],
    countryData:[],
    ebaySpecificItem:[]
};
export default function getEbayReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case GET_EBAY:
            return {
                ...state,
                data: payload,
            };
            case GET_COUNTRY:
                return {
                    ...state,
                    countryData: payload,
                };
                case EBAY_ITEM_SPECIFIC:
                    return {
                        ...state,
                        ebaySpecificItem: payload,
                    };
        case GET_EBAY_CATEGORY:
            return {
                ...state,
                ebayCategory: payload
            }; 
            case GET_SHIPPING:
                return {
                    ...state,
                    shippingDetail: payload
                };                    
            case GET_EBAY_CONDITION:
                return {
                    ...state,
                    ebayCondition: payload
                };
        case EBAY_LOADING:
            return {
                ...state,
                loading: payload,
            };
            case CREATE_EBAY_LOADING:
                return {
                    ...state,
                    createLoading: payload,
                };
            case DESCRIPTION_AI:
                return {
                    ...state,
                    descriptionAI: payload,
                };
        case GET_EBAY_IMAGE:
            return {
                ...state,
                ebayImagePath: payload,
            };
        case EBAY_DATA:
            return {
                ...state,
                ebayImageData: payload,
            };
        //    case EBAY_IMAGES:
        //     return{
        //         ...state,

        //     }
        default:
            return { ...state };
    }
}
