import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    Row,
} from "reactstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { getCustomizationAction, logout } from "../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { GrPowerShutdown } from "react-icons/gr";
import userLogo from "../assets/img/headerUser.png";
import { MdSubscriptions } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { IoIosLogIn } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaSpinner } from "react-icons/fa";
import { FaTicket } from "react-icons/fa6";
import { MdOutlineDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";
import {
    createTicketAction,
    getTicketDetailsAction,
} from "../store/actions/ticketAction";
import EbayModal from "./EbayModal";
// import TogglerDark from "../../src/assets/img/TogglerDark.svg";

const MainHeader = (props) => {
    const [isMobile, setIsMobile] = useState(false);
    console.log("props", props.toggle);
    useEffect(() => {
        updatePredicate();
        window.addEventListener("resize", updatePredicate);
        return () => window.removeEventListener("resize", updatePredicate);
    }, []);
    const [data, setData] = useState({
        SidebarBackgroundColor: "#000",
        SidebarTextColor: "#FFFFFF",
        ChatbotBackgroundColor: "#FFFFFF",
        ChatHistoryBackgroundColor: "#eaeaea",
        ChatbotTextColor: "#000",
        UserMessageColor: "#000",
        ChatbotColor: "#000",
        CategoryTextColor: "#FFFFFF",
        CategoryBackgroundColor: "#30b5f1",
        HeaderBackgroundColor: "#000",
        HeaderTextColor: "#FFFFFF",
    });
    const loader = useSelector((state) => state.userData.loading);

    const { customizationData } = useSelector((state) => state.userData);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (customizationData != null) {
            setData(customizationData);
        }
    }, [customizationData]);

    const updatePredicate = () => {
        setIsMobile(window.innerWidth < 992);
    };
    const history = useHistory();
    const dispatch = useDispatch();

    const [toggleNav, setToggleNav] = useState(false);
    const { user, uid } = useSelector((state) => state.authUser);
    console.log("ticket ID", user);
    const [logouts, setLogouts] = useState(false);
    const logoutToggle = () => {
        setLogouts(!logouts);
    };
    const [ticket, setTicket] = useState(false);
    const [files, setFiles] = useState([]);
    const [ticketTitle, setTicketTitle] = useState("");
    const [ticketDesc, setTicketDecs] = useState("");
    const ticketToggle = () => {
        setTicket(!ticket);
    };
    const ticketHandler = () => {
        ticketToggle();
        setTicketTitle("");
        setTicketDecs("");
        if (document.getElementById("file") !== null) {
            document.getElementById("file").value = "";
        }
    };
    const handleLogout = () => {
        dispatch(
            logout(() => {
                toast.success("logout successfully");
                history.push("/");
                logoutToggle();
            })
        );
    };
    const navCardHandler = () =>
        //NavCard Handler Toggler
        {
            setToggleNav(!toggleNav);
        };

    useEffect(() => {
        if (user?.UserId) {
            dispatch(getCustomizationAction(user?.UserId));
        }
    }, [user?.UserId]);

    const handleFileChange = (e) => {
        // const file = e.target.files[0];
        // setFiles(file);
        const selectedFiles = Array.from(e.target.files);
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        const invalidFiles = selectedFiles.filter(
            (file) => !validImageTypes.includes(file.type)
        );
        if (invalidFiles.length > 0) {
            document.getElementById("file").value = "";
            toast.error("Please select only images.");
            return;
        }
        setFiles([...files, ...selectedFiles]);
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        document.getElementById("file").value = "";
    };

    const submitTicket = (e) => {
        e.preventDefault();
        setPending(true);
        const formData = new FormData();
        let payload = {
            UserId: user?.UserId,
            Title: ticketTitle,
            Description: ticketDesc,
        };

        if (files.length == 0) {
            dispatch(
                createTicketAction(payload, files, () => {
                    // dispatch(getTicketDetailsAction(uid));
                    history.push("/ticketDetails");
                    setTicket(false);
                    setPending(false);
                })
            );
        } else {
            files.forEach((file) => {
                formData.append("Files", file);
            });
            dispatch(
                createTicketAction(payload, formData, () => {
                    // dispatch(getTicketDetailsAction());
                    history.push("/ticketDetails");
                    setTicket(false);
                    setPending(false);
                })
            );
        }
    };
    const [ebayModal, setEbayModal] = useState(false);
    const ebayToggle = () => {
        setEbayModal(!ebayModal);
    };
    return (
        <>
            {isMobile ? (
                <>
                    <Row>
                        <Col className="px-0">
                            <div
                                className="d-flex top-nav  justify-content-between px-3"
                                style={{
                                    background: data?.HeaderBackgroundColor,
                                }}
                            >
                                <div className="d-flex justify-content-between px-2 w-full">
                                    <div
                                        onClick={() => {
                                            history.push("/");
                                        }}
                                        className="flex items-center gap-3 hover:cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M6.82928 9C6.93984 8.68722 6.99999 8.35064 6.99999 8C6.99999 7.64936 6.93984 7.31278 6.82928 7H17C20.3137 7 23 9.68629 23 13C23 16.3137 20.3137 19 17 19H15.8293C15.9398 18.6872 16 18.3506 16 18C16 17.6494 15.9398 17.3128 15.8293 17H17C19.2091 17 21 15.2091 21 13C21 10.7909 19.2091 9 17 9H6.82928Z"
                                                fill="white"
                                            />
                                            <circle
                                                cx="13"
                                                cy="18"
                                                r="3"
                                                fill="white"
                                            />
                                            <circle
                                                cx="4"
                                                cy="8"
                                                r="3"
                                                fill="white"
                                            />
                                        </svg>

                                        <p
                                            className=" text-md   hover:cursor-pointer   fs--22 fw--500"
                                            style={{
                                                color: data?.HeaderTextColor,
                                            }}
                                        >
                                            Open Truth
                                        </p>
                                    </div>
                                    <div>
                                        {user?.UserId && (
                                            <button
                                                className="btn btn-sm text-white btn-outline-dark m-1"
                                                onClick={ticketHandler}
                                            >
                                                Contact Support
                                            </button>
                                        )}
                                        {uid && (
                                            <button
                                                onClick={ebayToggle}
                                                className="btn btn-sm text-white btn-outline-dark m-1 "
                                            >
                                                Ebay
                                            </button>
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center ">
                                        <button
                                            onClick={navCardHandler}
                                            className="flex items-center cursor-pointer gap-x-1 md:pr-4 lg:pr-4 xl:pr-4"
                                        >
                                            <span>
                                                <img
                                                    src={userLogo}
                                                    width={30}
                                                    height={30}
                                                    alt=""
                                                />
                                            </span>
                                            <span
                                                className="font-semibold  hidden lg:block"
                                                style={{
                                                    color: data?.HeaderTextColor,
                                                }}
                                            >
                                                {user?.Email}
                                            </span>
                                            <span>
                                                <IoIosArrowDown
                                                    size={13}
                                                    style={{
                                                        color: data?.HeaderTextColor,
                                                    }}
                                                />
                                            </span>
                                        </button>

                                        {/* Nav Card Toggle */}
                                        <div
                                            className={`${
                                                toggleNav ? "block" : "hidden"
                                            } bg-white flex flex-col items-start md:w-[250px] mr-[2%] sm:mr-0 sm:w-[205px] top-[11%] right-[0%]  sm:right-[3%] gap-y-2 text-[15px] justify-center rounded-xl  py-1 text-[#8d94a5] font-semibold fixed shadow-sm shadow-black`}
                                        >
                                            <div className="flex flex-col items-center w-full py-1 border-b gap-y-1">
                                                <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                                    <MdSubscriptions className="text-[#57595c]" />
                                                    <span>
                                                        <Link
                                                            className="hover:text-[#8d94a5] hover:no-underline"
                                                            to="/pricing"
                                                        >
                                                            Change Subscription
                                                        </Link>
                                                    </span>
                                                </h5>
                                                <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                                    <RiLockPasswordLine
                                                        className="text-[#57595c]"
                                                        size={15}
                                                    />
                                                    <span>
                                                        <Link
                                                            className="hover:text-[#8d94a5] hover:no-underline"
                                                            to="/update-password"
                                                        >
                                                            Change Password
                                                        </Link>
                                                    </span>
                                                </h5>
                                                {user?.UserId && (
                                                    <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                                        <FaTicket
                                                            className="text-[#57595c]"
                                                            size={15}
                                                        />
                                                        <span>
                                                            <Link
                                                                className="hover:text-[#8d94a5] hover:no-underline"
                                                                to="/ticketDetails"
                                                            >
                                                                All Ticket
                                                            </Link>
                                                        </span>
                                                    </h5>
                                                )}
                                            </div>

                                            {user?.UserId && (
                                                <>
                                                    <h5
                                                        role="button"
                                                        onClick={() => {
                                                            history.push(
                                                                "/chatbot-customization"
                                                            );
                                                        }}
                                                        className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                                    >
                                                        {/* <GrPowerShutdown className="text-[#57595c]" />{" "} */}
                                                        <i class="fas fa-palette"></i>
                                                        {""}
                                                        <span>
                                                            ChatBot
                                                            Customization
                                                        </span>
                                                    </h5>
                                                </>
                                            )}
                                            {user?.UserId ? (
                                                <>
                                                    <h5
                                                        role="button"
                                                        onClick={() => {
                                                            logoutToggle();
                                                        }}
                                                        className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                                    >
                                                        <GrPowerShutdown className="text-[#57595c]" />{" "}
                                                        <span>Logout</span>
                                                    </h5>
                                                </>
                                            ) : (
                                                <>
                                                    <h5
                                                        role="button"
                                                        onClick={() => {
                                                            history.push(
                                                                "/login"
                                                            );
                                                        }}
                                                        className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                                    >
                                                        <IoIosLogIn className="text-[#57595c]" />{" "}
                                                        <span>Login</span>
                                                    </h5>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {props.toggle ? (
                                                <AiOutlineClose
                                                    onClick={() => {
                                                        props.setToggle(false);
                                                    }}
                                                    className="cursor-pointer p-1  text-white"
                                                    size={35}
                                                />
                                            ) : (
                                                <GiHamburgerMenu
                                                    onClick={() => {
                                                        props.setToggle(true);
                                                    }}
                                                    className="p-1 cursor-pointer   rounded  text-white"
                                                    size={35}
                                                />
                                            )}
                                            {/* <GiHamburgerMenu
                                            className="cursor-pointer"
                                            onClick={props.toggle}
                                        /> */}
                                            {/* <img
                                            src={TogglerDark}
                                            
                                        /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row className="">
                    <Col>
                        <div
                            className="top-nav shadow-sm "
                            style={{
                                background: data?.HeaderBackgroundColor,
                            }}
                        >
                            <div className="d-flex justify-content-between px-5 w-full">
                                <div
                                    className="flex items-center gap-3 hover:cursor-pointer"
                                    onClick={() => {
                                        history.push("/");
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M6.82928 9C6.93984 8.68722 6.99999 8.35064 6.99999 8C6.99999 7.64936 6.93984 7.31278 6.82928 7H17C20.3137 7 23 9.68629 23 13C23 16.3137 20.3137 19 17 19H15.8293C15.9398 18.6872 16 18.3506 16 18C16 17.6494 15.9398 17.3128 15.8293 17H17C19.2091 17 21 15.2091 21 13C21 10.7909 19.2091 9 17 9H6.82928Z"
                                            fill="white"
                                        />
                                        <circle
                                            cx="13"
                                            cy="18"
                                            r="3"
                                            fill="white"
                                        />
                                        <circle
                                            cx="4"
                                            cy="8"
                                            r="3"
                                            fill="white"
                                        />
                                    </svg>
                                    <p
                                        className=" fs--24 fw--600   hover:cursor-pointer   "
                                        style={{
                                            color: data?.HeaderTextColor,
                                        }}
                                    >
                                        Open Truth
                                    </p>
                                </div>
                                <div>
                                    {user?.UserId && (
                                        <button
                                            className="btn btn-sm text-white btn-outline-dark m-1"
                                            onClick={ticketHandler}
                                        >
                                            Contact Support
                                        </button>
                                    )}
                                    {uid && (
                                        <button
                                            onClick={ebayToggle}
                                            className="btn btn-sm text-white btn-outline-dark"
                                        >
                                            Ebay
                                        </button>
                                    )}
                                </div>

                                <button
                                    onClick={navCardHandler}
                                    className="flex items-center cursor-pointer gap-x-1 md:pr-4 lg:pr-4 xl:pr-4"
                                >
                                    <span>
                                        <img
                                            src={userLogo}
                                            width={30}
                                            height={30}
                                            alt=""
                                        />
                                    </span>
                                    <span
                                        className="font-semibold  hidden lg:block"
                                        style={{
                                            color: data?.HeaderTextColor,
                                        }}
                                    >
                                        {user?.Email}
                                    </span>
                                    <span>
                                        <IoIosArrowDown
                                            size={13}
                                            style={{
                                                color: data?.HeaderTextColor,
                                            }}
                                        />
                                    </span>
                                </button>

                                {/* Nav Card Toggle */}
                                <div
                                    className={`${
                                        toggleNav ? "block" : "hidden"
                                    } bg-white flex flex-col items-start md:w-[250px] mr-[2%] sm:mr-0 sm:w-[205px] top-[11%] right-[0%]  sm:right-[3%] gap-y-2 text-[15px] justify-center rounded-xl  py-1 text-[#8d94a5] font-semibold fixed shadow-sm shadow-black`}
                                >
                                    <div className="flex flex-col items-center w-full py-1 border-b gap-y-1">
                                        <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                            <MdSubscriptions className="text-[#57595c]" />
                                            <span>
                                                <Link
                                                    className="hover:text-[#8d94a5] hover:no-underline"
                                                    to="/pricing"
                                                >
                                                    Change Subscription
                                                </Link>
                                            </span>
                                        </h5>
                                        <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                            <RiLockPasswordLine
                                                className="text-[#57595c]"
                                                size={15}
                                            />
                                            <span>
                                                <Link
                                                    className="hover:text-[#8d94a5] hover:no-underline"
                                                    to="/update-password"
                                                >
                                                    Change Password
                                                </Link>
                                            </span>
                                        </h5>
                                        {user?.UserId && (
                                            <h5 className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4 py-2 transition-all duration-500 cursor-pointer">
                                                <FaTicket
                                                    className="text-[#57595c]"
                                                    size={15}
                                                />
                                                <span>
                                                    <Link
                                                        className="hover:text-[#8d94a5] hover:no-underline"
                                                        to="/ticketDetails"
                                                    >
                                                        All Ticket
                                                    </Link>
                                                </span>
                                            </h5>
                                        )}
                                    </div>
                                    {user?.UserId && (
                                        <>
                                            <h5
                                                role="button"
                                                onClick={() => {
                                                    history.push(
                                                        "/chatbot-customization"
                                                    );
                                                }}
                                                className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                            >
                                                <i class="fas fa-palette"></i>{" "}
                                                <span>Color Customization</span>
                                            </h5>
                                        </>
                                    )}
                                    {user?.UserId ? (
                                        <>
                                            <h5
                                                role="button"
                                                onClick={() => {
                                                    logoutToggle();
                                                }}
                                                className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                            >
                                                <GrPowerShutdown className="text-[#57595c]" />{" "}
                                                <span>Logout</span>
                                            </h5>
                                        </>
                                    ) : (
                                        <>
                                            <h5
                                                role="button"
                                                onClick={() => {
                                                    history.push("/login");
                                                }}
                                                className="flex items-center gap-x-2 hover:bg-[#F8F8F8] hover:text-[#57595c] w-full px-4  transition-all duration-500 cursor-pointer py-2"
                                            >
                                                <IoIosLogIn className="text-[#57595c]" />{" "}
                                                <span>Login</span>
                                            </h5>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}

            <Modal
                isOpen={logouts}
                toggle={logoutToggle}
                size="md"
                centered={true}
                className="modal"
            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Logout</div>
                    <div className="cursor-pointer" onClick={logoutToggle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <ModalBody className="px-4 py-5">
                    <Row>
                        <Col>
                            <div className="text-center fs--20 fw--500 text-muted  ">
                                Are you sure you want to logout?
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <div className="text-right">
                                <Button
                                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                                    onClick={handleLogout}
                                >
                                    Yes
                                </Button>
                                <Button
                                    className="bg-gradient-to-t from-sky-500 to-sky-600 program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        logoutToggle();
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={ticket}
                toggle={ticketToggle}
                size="md"
                centered={true}
                className="modal"
            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Generate Ticket</div>
                    <IoClose
                        onClick={() => ticketToggle()}
                        size={25}
                        className="text-dark hover:cursor-pointer"
                    />
                </div>

                <ModalBody className="px-4 py-5">
                    <Form onSubmit={submitTicket}>
                        <FormGroup className="mt-2">
                            <Input
                                placeholder="Title"
                                type="text"
                                value={ticketTitle}
                                onChange={(e) => setTicketTitle(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Input
                                type="textarea"
                                placeholder="Description"
                                value={ticketDesc}
                                onChange={(e) => setTicketDecs(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Row className="ps-4">
                                <Col lg={78}>
                                    <Input
                                        name="Files"
                                        id="file"
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {files.map((file, index) => (
                                    <>
                                        <Col lg={10}>{file.name}</Col>
                                        <Col lg={2}>
                                            <MdOutlineDeleteForever
                                                className="hover:cursor-pointer text-danger"
                                                size={25}
                                                color="danger"
                                                onClick={() =>
                                                    handleRemoveFile(index)
                                                }
                                            />
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </FormGroup>
                        <Row className="flex justify-center mt-4">
                            <Col lg={3} md={3}>
                                <button
                                    disabled={pending}
                                    // type="submit"
                                    className="btn btn-primary btn-sm"
                                >
                                    CREATE {pending && <FaSpinner />}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <EbayModal ebayToggle={ebayToggle} ebayModal={ebayModal} />
        </>
    );
};
export default MainHeader;
