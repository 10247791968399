import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { sendCodeEbayAction } from "../store/actions/getEbayAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ConnectEbay = ({ buttonShow, setButtonShow }) => {
    const [authUrl, setAuthUrl] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const { uid } = useSelector((state) => state.authUser);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogin = async () => {
        try {
            // Replace 'YOUR_CLIENT_ID' with your actual eBay App Client ID
            const clientId = "DanialRe-OpenTrut-PRD-7835ff3a5-8b5b45cb";
            const redirectUri = "https://opentruth.ai/ebay-listing"; // Replace with your actual redirect URI
            const responseType = "code";
            const scope = "https://api.ebay.com/oauth/api_scope"; // Specify the required scopes
            const url = `https://auth.ebay.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

            window.location.href = url;
        } catch (error) {
            toast.error("Error during login:", error);
        }
    };
    const handleCallback = async () => {
        try {
          
            console.log("window.location.search", window.location);
            const params = new URLSearchParams(window.location.search);
            const code = params.get("code");
            console.log("code-----", code);
            if (code) {
                dispatch(
                    sendCodeEbayAction(
                        {
                            UserId: uid,
                            AccessCode: code,
                        },
                        (value) => {
                            if(value){
                                setButtonShow(false);
                                history.push("/ebay-listing");
                            }else{
    
                                history.push("/ebay-listing");
                            }
                        
                        }
                    )
                );
            }

           
        } catch (error) {
            console.error("Error during callback handling:", error);
        }
    };
    useEffect(() => {
        handleCallback();
    }, [window.location]);
    return (
        <Row className="my-5 flex justify-center">
            <Col className="" md="6">
                <div className="text-center">
                    <Button
                        onClick={handleLogin}
                        className="button-search px-4"
                    >
                        Connect with eBay
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default ConnectEbay;
