import { Container } from "reactstrap";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";

const Main = (props) => {
    const location = useLocation();
    console.log("location-->", location);
    return (
        <>
            {location.pathname != "/mainview" && <Navbar />}

            {props.children}
        </>
    );
};

export default Main;
