import ChattingRepository from "./ChattingRepository";
import authRepository from "./authRepository";
import newsRepository from "./newsRepository";
import CheckoutRepository from "./CheckoutRepository";

import getEbayRepository from "./getEbayRepository";
import ticketRepository from "./ticketRepository";
const repositories = {
    chatting: ChattingRepository,
    auth: authRepository,
    checkout: CheckoutRepository,
    ticket: ticketRepository,
    getEbay: getEbayRepository,
    news: newsRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
