import React, { useEffect, useState } from "react";
import { Col, Container } from "reactstrap";
import { EbayListingUpload } from "../components/EbayListingUpload";
import TitleListing from "../components/completeListing/TitleListing";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CompleteListing = () => {

  
    // const parseStringToObject = (str) => {
    //     const titleMatch = str.match(/Title: (.*?),/);
    //     const sourceIconMatch = str.match(/Source Icon: (.*?),/);
    //     const thumbnailMatch = str.match(/Thumbnail: (.*?)\s*$/);
      
    //     return {
    //       title: titleMatch ? titleMatch[1] : '',
    //       socialLink: sourceIconMatch ? sourceIconMatch[1] : '',
    //       thumbnail: thumbnailMatch ? thumbnailMatch[1] : ''
    //     };
    //   };
      
 


    // useEffect(()=>{
    //  // Convert string to object
    //  let inputString="Position: 1, Title: Nexteam - React Native Mentoring - Technology, Experience, Delivered., Link: https://nexteam.co.uk/services/support/react-native-mentoring, Source: nexteam.co.uk, Source Icon: https://encrypted-tbn0.gstatic.com/favicon-tbn?q=tbn:ANd9GcT1ecacx0Pjaq64ObyM619Y1T40_RIuJwZbTLgIbsA7OwwT0HUNNd_bOMblPzuyD3qZLt-hu5c6-nPGLxqUPI8WIHAHHoahQwkyN0o4h0LYCchY, Thumbnail: https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyF0Ol-Xyly7L4rr_DNvOIGalcozQWs3WLXM1uddXK5AmZDfxm"
    //  const dataObject = parseStringToObject(inputString);
    //  console.log("dataObject",dataObject)
    // },[])
    return (
        <Container>
            <TitleListing />
        </Container>
    );
};
export default CompleteListing;
