// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
const baseDomain = "https://backend.opentruth.ai/";
//const baseDomain = "http://65.2.129.200:7000/";
//const baseDomain = "http://192.168.18.106:9000";
// const baseDomain = "http://192.168.18.106:8000/";
// const baseDomain = "http://192.168.18.106:9000";
export const baseURL = `${baseDomain}`;

// const getToken = () => {
//     let ls = JSON.parse(localStorage.getItem("authUser"));
//     console.log("ls", ls?.user?.AccessToken);
//     if (ls && ls?.user?.AccessToken != null) {
//         return ls?.user?.AccessToken;
//     } else {
//         return "";
//     }
// };

let axiosObj;
axiosObj = axios.create({
    baseURL,
});
axiosObj.interceptors.request.use(
    (config) => {
        // Dynamically set the token for each request
        const ls = JSON.parse(localStorage.getItem("authUser"));
        const token = ls?.token || "";
        console.log("token",token)
        config.headers.token = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosObj;
