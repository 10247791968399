import React from "react";

const Pagination = ({ page, setPage, totalPages }) => {
    const lastPage = totalPages;
    const pagesToShow = 4;
    const generatePages = () => {
        const pages = [];
        if (lastPage <= pagesToShow) {
            for (let i = 1; i <= lastPage; i++) {
                pages.push(i);
            }
        } else {
            if (page <= pagesToShow - 1) {
                for (let i = 1; i <= pagesToShow; i++) {
                    pages.push(i);
                }
            } else if (page >= lastPage - pagesToShow + 1) {
                for (let i = lastPage - pagesToShow + 1; i <= lastPage; i++) {
                    pages.push(i);
                }
            } else {
                for (let i = page - 1; i <= page + 1; i++) {
                    pages.push(i);
                }
            }
            if (pages[0] !== 1) {
                pages.unshift("...");
            }
            if (pages[pages.length - 1] !== lastPage) {
                pages.push("...");
            }
        }
        return pages;
    };
    return (
        <div>
            <nav aria-label="Page navigation">
                <ul className="inline-flex space-x-2">
                    <li>
                        <button
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}
                            disabled={page === 1}
                            className="flex items-center justify-center w-10 h-10 text-primary transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-indigo-100"
                        >
                            <svg
                                className="w-4 h-4 fill-current"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                    fill-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </li>
                    {generatePages()?.map((pageNumber, index) => (
                        <li
                            onClick={() => {
                                pageNumber !== "..." && setPage(pageNumber);
                            }}
                        >
                            <button
                                className={`w-10 h-10 border  duration-150 border-primary transition-colors rounded-full focus:shadow-outline hover:bg-indigo-100${
                                    page === pageNumber
                                        ? " text-white  bg-primary border border-r-0 border-indigo-600  "
                                        : "text-primary"
                                }
               `}
                            >
                                {pageNumber}
                            </button>
                        </li>
                    ))}

                    <li>
                        <button
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}
                            disabled={page === lastPage}
                            className="flex items-center justify-center w-10 h-10 text-primary transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-indigo-100"
                        >
                            <svg
                                className="w-4 h-4 fill-current"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                    fill-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
export default Pagination;
