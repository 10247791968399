import React, { useEffect, useState } from "react";
import { Col, Row, CardText, Button, CardSubtitle, Card, CardBody, CardTitle, Input, Form, Spinner } from "reactstrap";
import { MdOutlineModeEdit } from "react-icons/md";
import ItemSpecificRequired from "./ItemSpecificRequired";
import { IoCloudUploadOutline } from "react-icons/io5";
import EbayDescription from "./EbayDescription";
import EbayPricing from "./EbayPricing";
import EbayShipping from "./EbayShipping";
import { VscSettings } from "react-icons/vsc";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EbayCategoryModal from "./EbayCategoryModal";
// import countryData from "../../json/countryEbay.json"
import currencyData from "../../json/currencies.json"
import sitesData from "../../json/ebay_sites.json"
import paymentMethodData from "../../json/paymentMethod.json"
import { useDispatch, useSelector } from "react-redux";
import { createEbayListingAction, getEbayCategoryAction, getEbayCondition, getEbayCountryAction, getEbayItemSpecificAction, getShippingDetailAction } from "../../store/actions/getEbayAction";
import { list } from "postcss";
import { toast } from "react-toastify";
import { HiOutlineSearch, HiOutlineTrash } from "react-icons/hi";

function TitleListing() {
    const { uid } = useSelector((state) => state.authUser)
    const { state } = useLocation()
    console.log("state__", state)
    const dispatch = useDispatch()
    const { ebayCondition, ebaySpecificItem, loading, descriptionAI, createLoading, countryData,ebayCategory } = useSelector((state) => state.getEbay)

    console.log("ebaySpecificItem", ebaySpecificItem)
    const [titleArr, setTitleArr] = useState([])

    const [images, setImages] = useState([]);
    const [categoryModal, setCategoryModal] = useState(false)
    const categoryToggle = () => {
        setCategoryModal(!categoryModal)
    }


    useEffect(() => {
        if (state?.length > 0) {
            let arrImg = state?.map((value) => value.imgUrl)
            let tittleArray = state?.map((value) => value.title)
            let title = state[state?.length - 1]?.title
            setTitleArr(tittleArray)

            setListingData({
                ...listingData,
                Title: title,
                PictureDetails: {
                    PictureURL: arrImg
                }
            })

        }
    }, [state])





    const [listingData, setListingData] = useState({
        Title: "",
        Description: "",
        PrimaryCategory: { CategoryID: "" },
        StartPrice: "15.00",
        CategoryMappingAllowed: true,
        ConditionID: "",
        Country: "US",
        Currency: "USD",
        DispatchTimeMax: null,
        ListingDuration: "",
        ListingType: "FixedPriceItem",
        PaymentMethods: "",
        PayPalEmailAddress: "userEmail",
        PictureDetails: {
            PictureURL: []
        },
        PostalCode: "95050",
        Quantity: 1,
        ReturnPolicy: {
            ReturnsAcceptedOption: "ReturnsAccepted",
            RefundOption: "Money back",
            ReturnsWithinOption: "30_Days",
            ShippingCostPaidByOption: "Buyer"
        },
        ShippingType: "",
        ShippingService: "",
        ShippingServiceCost:0,



        // ShippingDetails: {
        //     ShippingType: "Flat",
        //     ShippingServiceOptions: {
        //         ShippingServicePriority: 1,
        //         ShippingService: "USPSFirstClass",
        //         ShippingServiceCost: "3.00"
        //     }
        // },
        Site: ""
    })

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imageFiles = files.filter(file => file.type.startsWith('image/'));

        // Read selected image files
        // const imagePreviews = imageFiles.map(file => URL.createObjectURL(file));

        setImages(prevImages => [...prevImages, ...imageFiles]);
    };
    console.log("images==>", images)


    const [categoryName, setCategoryName] = useState("")
    const [formValues, setFormValues] = useState({});
    console.log("listing Data", listingData)
    const handleChange = (e) => {
        setListingData({
            ...listingData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (listingData?.PrimaryCategory?.CategoryID) {
           let  AdditionalKeys=[]
           if(formValues){
            AdditionalKeys = Object.entries(formValues).map(([name, value]) => {
                return { Name: name, Value: value };
            });

           }
           console.log("AdditionalKeys",AdditionalKeys)


            
            if (images?.length > 0) {


                let form = new FormData()
                form.append("UserId", uid)
                form.append("Title", listingData.Title,)
                form.append("Description", listingData.Description)
                form.append("CategoryID", Number(listingData.PrimaryCategory.CategoryID))
                form.append("StartPrice",Number(listingData.StartPrice))
                form.append("ConditionID", Number(listingData.ConditionID))
                form.append("Country", listingData.Country)
                form.append("Currency", listingData.Currency)
                form.append("DispatchTimeMax", Number(listingData.DispatchTimeMax))
                form.append("ListingDuration", listingData.ListingDuration)
                form.append("PaymentMethods", "PayPal")

                form.append("PostalCode", listingData.PostalCode)
                form.append("Quantity", Number(listingData.Quantity))
                form.append("ShippingServiceCost",Number(listingData.ShippingServiceCost))
                // form.append("Site", listingData.Country)
                // form.append("ShippingType", listingData.ShippingType)
                // form.append("ShippingService", listingData.ShippingService)
                images.forEach((item) => (
                    form.append("Images", item)
                ))
                if(AdditionalKeys?.length>0){
                     form.append("AdditionalKeys",JSON.stringify(AdditionalKeys))
                    // AdditionalKeys.forEach((item) => (
                    //     form.append("AdditionalKeys",item)
                    // ))
                    // for (let i = 0; i < AdditionalKeys.length; i++) {
                    //     form.append(
                    //       `AdditionalKeys[${i}].Name`,
                    //       AdditionalKeys[i].Name
                    //     );
                    //     form.append(
                    //       `AdditionalKeys[${i}].Value`,
                    //       AdditionalKeys[i].Value
                    //     );
                    //     }
                }
                console.log("submit payload", form)
                dispatch(createEbayListingAction(form))
            } else {
                toast.error("Upload Image")
            }
        } else {
            toast.error("Add Category")
        }


    }
    useEffect(() => {
        if (descriptionAI != null) {
            setListingData({
                ...listingData,
                Description: descriptionAI
            })
        }


    }, [descriptionAI])
    useEffect(() => {
        if (uid) {
            dispatch(getEbayCountryAction(uid))
        }

    }, [uid])
    useEffect(() => {
        if (listingData?.PrimaryCategory?.CategoryID) {
            let obj = {
                userId: uid,
                categoryId: listingData?.PrimaryCategory?.CategoryID
            }
            dispatch(getEbayCondition(obj))

            dispatch(getEbayItemSpecificAction(obj))
        }
    }, [listingData?.PrimaryCategory?.CategoryID])

    useEffect(() => {
        if (listingData?.Site) {
            let obj = {
                userId: uid,
                siteId: listingData?.Site,
            }
            dispatch(getShippingDetailAction(obj))
        }

    }, [listingData.Site])
    const handleRemoveImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };
    const [keyword, setKeyword] = useState("")
    const handleSearch = (e) => {
        e.preventDefault()
        if(keyword!=""){
            const obj = {
                id: uid,
                keyword
            }
            dispatch(getEbayCategoryAction(obj,()=>{
                setKeyword("")
            }))
        }else{
            toast.warn("Enter Keyword")
        }
       

    }
    return (
        <>
            <div className="ebay_container container">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <div className="title_main d-flex justify-center">Complete your listing</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="img_container">
                                <div className="title_photos">PHOTOS</div>
                                <p>1 of {images?.length} photos</p>
                                {/* MAIN IMG */}
                          

                            </div>

                            <div
                                className="flex gap-3 overflow-x-auto w-full"
                            //   onClick={toggleModal}
                            >
                                {images?.map((screenshot, index) => (
                                    <div
                                        key={index}
                                        className="relative group flex min-w-fit hover:backdrop-brightness-50 p-5 rounded-2xl items-center justify-center dark:border-white dark:text-white"
                                    >
                                        <img
                                            src={URL.createObjectURL(screenshot)}
                                            alt="Uploaded Screenshot"
                                            height={100}
                                            width={100}
                                            className="cursor-pointer object-contain aspect-square"
                                            placeholder="blur"
                                            blurDataURL="/imagePlaceholder.gif"
                                        // onClick={() => {
                                        //   setSelectedScreenshotIndex(index);
                                        //   toggleModal();
                                        // }}
                                        />
                                        {/* {screenshot.isLoading ? (
                <ClipLoader
                  size={15}
                  color="white"
                  className="absolute top-3 right-2 cursor-not-allowed "
                />
              ) : ( */}

                                        <HiOutlineTrash
                                            className="absolute top-3 right-2 text-white cursor-pointer opacity-0 group-hover:opacity-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleRemoveImage(index)
                                            }}
                                        />
                                        <p
                                            className=" absolute bottom-2 left-3 hover:backdrop-brightness-50 text-white text-xs cursor-pointer opacity-0 group-hover:opacity-100"
                                        // onClick={(event) => {
                                        //   event.stopPropagation();
                                        //   handleSetAsPrimary(screenshot.url);
                                        // }}
                                        >

                                        </p>
                                        {/* {primaryScreenshot === screenshot.url && (
                    <FaStar className="absolute top-2 left-2 text-specialOrange" />
                  )} */}

                                        {/* )} */}
                                    </div>
                                ))}
                            </div>

                            <div className="my-3">
                                <input
                                    type="file"
                                    id="screenshot"
                                    hidden
                                    accept="image/*" multiple onChange={handleImageChange}
                                />

                                <label
                                    htmlFor="screenshot"
                                    className={
                                        `p-2 rounded-full border-2 border-black flex justify-center items-center w-36 gap-2 cursor-pointer font-normal dark:border-white dark:text-white`

                                    }
                                >

                                    Upload
                                    <IoCloudUploadOutline />

                                </label>
                                <p className="text-xs text-gray-500 mt-3">
                                    PNG, JPG, JPEG formats. Minimum select 5.
                                </p>

                            </div>

                        </Col>
                    </Row>
                    {/* 2ND EBAY CONTAINER */}
                    <div>
                        <hr style={{ height: '1px' }} />

                    </div>
                    <Row className="second_ebay_container">
                        <Col>
                            <div className="second_container_title">
                                <h3 className="pt-2"> TITLE</h3>
                                {/* <div className='pricing_setting'>

                                    <VscSettings size={'20px'} />
                                    <p>see title option</p>
                                </div> */}
                            </div>
                            <h4 className="second_container_text">Item title</h4>
                            <Input type='text' required value={listingData?.Title} onChange={(e) => {
                                setListingData({
                                    ...listingData,
                                    Title: e.target.value
                                })
                            }} />

                        </Col>
                    </Row>
                    <div>
                        <hr style={{ height: '1px' }} />
                    </div>
                    {/* 3RD CONTA6INER.. OF EABY.. */}
                    <div className="third_container">
                        <div>
                            <h3 className="second_container_title font-bold text-base">ITEM CATEGORY</h3>
                        </div>
                        {/* <div className="edit_text" onClick={categoryToggle}>
                            <MdOutlineModeEdit size={"22px"} />
                            <h4 className="third_container_edit cursor-pointer">Add Category</h4>
                        </div> */}
                    </div>
                    <Row>
                            <Col md="7">
                                <div className="relative w-full">
                                    <div className="flex items-center">
                                        <input
                                            type="text"
                                            value={keyword}
                                            onChange={(e) => {

                                                setKeyword(e.target.value)

                                            }}
                                            placeholder="Enter Keyword"
                                            className="w-full py-2 px-5 pl-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                                        // value={query}
                                        // required
                                        // onChange={handleInputChange}

                                        />
                                        <HiOutlineSearch
                                            size={20}
                                            className="w-5 h-5 text-gray-400 absolute left-3 top-3"
                                        />
                                        <div className="ml-2">
                                            <Button
                                                type="button"
                                                onClick={handleSearch}
                                                className="bg-gradient-to-t from-sky-500 to-sky-600"
                                                disabled={loading}
                                            >

                                                {loading ? (
                                                    <Spinner size="md" />
                                                ) : (
                                                    "Search"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>
                        {
                            ebayCategory?.length>0 && 
                            <Row className='my-3'>
                            <Col md="6">
                                <div>
                                    <h3 className='pb-2 pt-2 text-muted '>Add Category</h3>
                                    <Input
                                        value={listingData?.PrimaryCategory?.CategoryID}
                                        onChange={(e)=>{
                                            setListingData({
                                                ...listingData,
                                                PrimaryCategory:{
                                                    CategoryID:e.target.value
                                                }

                                            })
                                            let v=ebayCategory?.find((obj)=>obj.Category?.CategoryID==e.target.value)
                                           console.log("v",v)
                                            setCategoryName(v.Category.CategoryName)


                                        }}
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                         <option  value="">Select Category</option>
                                        {
                                            ebayCategory?.map((obj) => (
                                                <option value={obj.Category?.CategoryID

                                                }>{obj.Category?.CategoryName}</option>
                                            ))
                                        }


                                    </Input>
                                </div>


                            </Col>
                        </Row>
                        }
                      
                    <div className="shoes_history">
                        <h5>{categoryName}</h5>
                        {/* <p>in Clothing, Shoes & Accessories</p> */}
                    </div>

                    <div>
                        <hr style={{ height: '1px' }} />

                    </div>
                    <Row className="my-4">
                        <Col md="6">
                            <h3 className="font-semibold my-2">Country</h3>
                            <Input type="select" required name="Country" value={listingData.Country} onChange={handleChange}>
                                <option value="">Select Country</option>
                                {
                                    countryData?.map((obj) => (
                                        <>
                                            <option value={obj?.Country}>{obj?.Description}</option>
                                        </>

                                    ))

                                }

                            </Input>


                        </Col>
                        <Col md="6">
                            <h3 className="font-semibold my-2">Currency</h3>
                            <Input required type="select" name="Currency" value={listingData.Currency} onChange={handleChange}>
                                <option value="">Select Currency</option>
                                {
                                    currencyData?.map((obj) => (
                                        <>
                                            <option value={obj.id}>{obj?.name}</option>
                                        </>

                                    ))

                                }

                            </Input>


                        </Col>

                    </Row>

                    <Row className="my-4">
                        {/* <Col md="6">
                            <h3 className="font-semibold my-2" >Sites</h3>
                            <Input type="select" required name="Site" value={listingData.Site} onChange={handleChange}>
                                <option value="">Select Sites</option>
                                {
                                    sitesData?.map((obj) => (
                                        <>
                                            <option value={obj.siteID}>{obj?.siteName}</option>
                                        </>

                                    ))

                                }

                            </Input>


                        </Col> */}
                        {/* <Col md="6">
                            <h3 className="font-semibold my-2">Zip Code</h3>
                            <Input required type="text" name="PostalCode" value={listingData.PostalCode} onChange={handleChange}>


                            </Input>


                        </Col> */}

                    </Row>
                    <Row className="my-4">
                        {/* <Col md="6">
                            <h3 className="font-semibold my-2">Payment method</h3>
                            <Input required type="select" name="PaymentMethods" value={listingData.PaymentMethods} onChange={handleChange}>
                                <option value="">Select Payment Method</option>
                                {
                                    paymentMethodData?.map((obj) => (
                                        <>
                                            <option value={obj.id}>{obj?.name}</option>
                                        </>

                                    ))

                                }

                            </Input>


                        </Col> */}

                        {
                            ebayCondition?.length > 0 &&
                            <Col md="6">
                                <h3 className="font-semibold my-2">Item Condition</h3>
                                <Input required type="select" name="ConditionID" value={listingData.ConditionID} onChange={handleChange}>
                                    <option value="">Select Condition</option>
                                    {
                                        ebayCondition?.map((obj) => (
                                            <>
                                                <option value={obj?.ID}>{obj?.DisplayName}</option>
                                            </>

                                        ))

                                    }

                                </Input>


                            </Col>
                        }


                    </Row>
                    {
                        ebaySpecificItem?.length>0 &&
                        (
                            <ItemSpecificRequired  formValues={formValues} setFormValues={setFormValues}/>
                        )
                    }



                    <EbayPricing listingData={listingData} setListingData={setListingData} />
                    <EbayShipping listingData={listingData} setListingData={setListingData} />
                    <EbayCategoryModal setCategoryName={setCategoryName} listingData={listingData} setListingData={setListingData} categoryModal={categoryModal} categoryToggle={categoryToggle} />
                    <EbayDescription titleArr={titleArr} listingData={listingData} setListingData={setListingData} />
                    <Row className="my-2">
                        <Col className="flex justify-center">
                            <Button className="cursor-pointer button-search" type="submit" disabled={loading || createLoading}>

                                {createLoading ? <Spinner size="md" /> : 'Create Ebay Listing'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}
export default TitleListing;
