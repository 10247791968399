import React, { useState, useRef, useEffect } from "react";

import { UncontrolledTooltip } from "reactstrap";
import { IoIosArrowDown } from "react-icons/io";

export default function RadioButton({
    selectedOption,
    setSelectedOption,
    dropdownData,
}) {
    const [isOpen, setIsOpen] = useState(false);
    console.log("selectedOption", selectedOption);
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (event, item) => {
        if (selectedOption && selectedOption.id === item.id) {
            // Uncheck the checkbox if it's already checked
            setSelectedOption(null);
        } else {
            // Check the checkbox and show tooltip
            setSelectedOption(item);
        }
    };

    const handleItemClick = (item) => {
        setSelectedOption(item);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div className="gptdropdown ">
            <div className="custom-dropdown " ref={dropdownRef}>
                <div
                    className="selected-item flex justify-between items-center "
                    onClick={handleToggle}
                >
                    <span className="mr-3">
                        {selectedOption ? selectedOption.key : "GPt-3"}
                    </span>
                    <span>
                        <IoIosArrowDown
                            style={{
                                fontSize: "18px",
                            }}
                        />
                    </span>
                </div>
                {isOpen && (
                    <div className="dropdown-content shadow-lg w-[400px]">
                        {dropdownData?.map((item) => {
                            return (
                                <div
                                    // key={item.id}

                                    className={`dropdown-item flex bg-[#ffff]  items-center justify-between `}
                                    onClick={() => handleItemClick(item)}
                                >
                                    {/* {isDisabled && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white text-center">
                                            <span>Coming Soon...</span>
                                        </div>
                                    )} */}
                                    <div className="mx-2 text-left">
                                        {item.text}
                                    </div>
                                    <div>
                                        <input
                                            className="radio-btn"
                                            type="radio"
                                            checked={
                                                selectedOption &&
                                                selectedOption.id === item.id
                                            }
                                            onChange={(e) =>
                                                handleCheckboxChange(e, item)
                                            }
                                        />
                                        <label for="session1"></label>
                                    </div>

                                    {/* {selectedOption &&
                                    selectedOption.id === item.id && (
                                        <div className="tooltip">
                                            {item.tooltip}
                                        </div>
                                    )} */}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
