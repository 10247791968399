// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-message {
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  .user-message {
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: end !important;
  }

`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,0BAA0B;EAC5B","sourcesContent":[".admin-message {\n    padding: 8px;\n    border-radius: 8px;\n    margin-bottom: 8px;\n  }\n  \n  .user-message {\n    padding: 8px;\n    border-radius: 8px;\n    margin-bottom: 8px;\n    text-align: end !important;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
