import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { GrFormView } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTicketDetailsAction } from "../store/actions/ticketAction";

const TicketDetails = () => {
    const {uid} = useSelector((state) => state.authUser);
    const {getTicket} = useSelector((state) => state.ticket);
    const dispatch = useDispatch();
    console.log("userId",uid)
   
    useEffect( async() => {
       await dispatch(getTicketDetailsAction(uid));
    }, [uid]);

    return (
        <Container>
            <Row className="mt-5">
                <Col lg={12} md={12} sm={12}>
                    <Table bordered responsive striped>
                        <thead className="bg-dark text-white">
                            <tr>
                                <th>Ticket Id</th>
                                <th>Title</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTicket?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.TicketId}</td>
                                    <td>{item.Title}</td>
                                    <td>{item.Status}</td>
                                    <td>
                                        {item.Status === "In Progress" && (
                                            <Link to={"/ticketChat/" + item.TicketId}>
                                                <button className="btn btn-sm btn-success">Chat</button>
                                                {/* <GrFormView
                                                    size={30}
                                                    className="text-success hover:cursor-pointer"
                                                /> */}
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            {/* <tr>
                                <th>1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>
                                    <Link to={"/ticketChat"}>
                                        <GrFormView
                                            size={30}
                                            className="text-success hover:cursor-pointer"
                                        />
                                    </Link>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};
export default TicketDetails;
