import React from 'react'
import { VscSettings } from "react-icons/vsc";
import { useState } from 'react';
import Select from 'react-select';
import { Switch } from '@headlessui/react'
import listingDuration from "../../json/listingDuration.json"
import { Col, Input, Row } from 'reactstrap';

const optionsone = [
    { value: 'auction', label: 'Auction' },
    { value: 'FixedPriceItem', label: 'Buy it Now' },
];
const optionstwo = [
    { value: '3 days', label: '3 Days' },
    { value: '5 days', label: '5 Days' },
    { value: '7 days', label: '7 Days' },
    { value: '10 days', label: '10 Days' },
];
const EbayPricing = ({ listingData, setListingData }) => {
    const [selectedOptionOne, setSelectedOptionOne] = useState(optionsone[1]);
    const [selectedOptionTwo, setSelectedOptionTwo] = useState(optionstwo[0])
    const [schedule, setSchedule] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 400
        }),
        menu: (provided) => ({
            ...provided,
            width: 400
        })
    };
    return (
        <>
            <div className='main_pricing_container'>
                <h3>PRICING</h3>
                {/* <div className='pricing_setting'>
                    <VscSettings size={'20px'} />
                    <p>see pricing option</p>
                </div> */}
            </div>
            <div className='main_ebay_pricing_container pb-[30px]'>
                <Row>
                    <Col>
                        <div className='select_price_dropdown'>
                            <h5 className='pb-2'>Format</h5>
                            <Select
                                required
                                defaultValue={selectedOptionOne}
                                onChange={setSelectedOptionOne}
                                options={optionsone}
                                styles={customStyles}
                            />
                            {selectedOptionOne.value === 'FixedPriceItem' && (
                                <div>
                                    <h3 className='pb-2 pt-2'>Price</h3>
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>$</span>
                                        <Input required type="text" value={listingData?.StartPrice} onChange={(e) => {
                                            setListingData({
                                                ...listingData,
                                                StartPrice: e.target.value
                                            })
                                        }} style={{ paddingLeft: '20px', width: '192px' }} className='input_handled' />
                                    </div>
                                    <h3 className='pt-2 pb-2'>Quantity</h3>
                                    <Input required value={listingData?.Quantity} onChange={(e) => {
                                        setListingData({
                                            ...listingData,
                                            Quantity: e.target.value
                                        })
                                    }} type="text" style={{ paddingLeft: '8px', width: '192px' }} />
                                </div>
                             )}
                            {/* {selectedOptionOne.value === 'auction' && (
                                <>
                                    <h5 className='pb-2 pt-2'>Auction duration</h5>
                                    <Select
                                        defaultValue={selectedOptionTwo}
                                        onChange={setSelectedOptionTwo}
                                        options={optionstwo}
                                        styles={customStyles}
                                    />
                                    <div className='price_input'>
                                        <div className='price_input_first'>
                                            <h3 className='pt-[13px] pb-2'>Starting bid</h3>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                                <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>$</span>
                                                <Input type="text" style={{ paddingLeft: '20px', width: '192px' }} />
                                            </div>
                                        </div>
                                        <div className='price_input_second'>
                                            <span className='d-flex pt-2 pb-2'>Buy it Now<p className='text-gray-500 text-sm pt-[2px]'> (Optional)</p></span>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                                <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>$</span>
                                                <Input type="text" style={{ paddingLeft: '20px' }} />
                                            </div>
                                            <p className='text-gray-500 text-sm pb-2'>Minimum: 30% more than starting bid</p>
                                        </div>
                                    </div>
                                    <span className='d-flex' >Reserve price<p className='text-gray-500 text-sm pt-[2px] '>(optional) — fees apply</p></span>
                                    <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                        <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>$</span>
                                        <Input type="text" style={{ paddingLeft: '20px', width: '192px' }} />
                                    </div>
                                    <div className="opacity-50 pointer-events-none ">
                                        <h3 className='pt-2 pb-2'>Quantity</h3>
                                        <Input type="text" value={'1'} style={{ width: '192px' }} disabled />
                                    </div>
                                </>
                            )} */}
                        </div>
                    </Col>
                    {/* <Col>
                        <div className='pricing_second_container mt-3'>
                            <h4 className='mb-3 font-bold'>Sold listings in the last 90 days</h4>

                            <div className="flex justify-between">
                                <div className="mr-4">
                                    <p className="text-base mb-0">Suggested starting bid</p>
                                    <p className="text-base mb-0">Median sold price</p>
                                    <p className="text-base mb-0">Free shipping</p>
                                </div>
                                <div className="pt-0">
                                    <h6 className="text-base m-0">$10.00</h6>
                                    <h6 className="text-base m-0">$10.00</h6>
                                    <h6 className="text-base m-0">$10.00</h6>
                                </div>
                            </div>


                            <div>
                                <h4 className='underline cursor-pointer pt-3 hover:text-gray-400'>see similar listings</h4>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col>
                        <div className='pricing_second_container mt-3'>
                            <h4 className='mb-3 font-bold'>Sold listings in the last 90 days</h4>

                            <div className="flex justify-between">
                                <div className="mr-4">

                                    <p className="text-base mb-0">Median sold price</p>
                                    <p className="text-base mb-0">Free shipping</p>
                                </div>
                                <div className="pt-0">

                                    <h6 className="text-base m-0">$10.00</h6>
                                    <h6 className="text-base m-0">0%</h6>
                                </div>
                            </div>


                            <div>
                                <h4 className='underline cursor-pointer pt-3 hover:text-gray-400'>see similar listings</h4>
                            </div>
                        </div>
                    </Col> */}
                    <div className='main_schedule'>
                        <h3>Schedule your listing</h3>
                        {/* <div className="flex justify-between items-center">
                            <p className='text-gray-500 text-sm'>Your listing goes live immediately,unless you select a time and date you want it to start.</p>
                            <div>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={schedule} // Set the 'checked' attribute based on your state
                                        onChange={(e) => {
                                            setSchedule(e.target.checked)
                                        }}
                                        className="sr-only peer"
                                    />
                                    <div
                                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 ${schedule
                                            ? "peer-checked:bg-blue-600"
                                            : "peer-checked:bg-gray-200"
                                            } dark:peer-focus:ring-blue-800 dark:border-gray-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                                    ></div>

                                </label>
                            </div>
                        </div> */}


                    </div>
                    {/* {
                        schedule && ( */}
                    <Col xs="12" >
                        <Row>
                            <Col>
                                <h3 className='pt-2 pb-2'>Listing Duration</h3>
                                <Input
                                    required
                                    type="select" value={listingData.ListingDuration} onChange={(e) => {
                                        setListingData({
                                            ...listingData,
                                            ListingDuration: e.target.value
                                        })
                                    }}>
                                    <option value="">
                                        Select listing duration

                                    </option>
                                    {
                                        listingDuration?.map((obj) => (
                                            <option value={obj.id}>
                                                {obj.name}


                                            </option>
                                        ))
                                    }

                                </Input>
                            </Col>
                            <Col>
                                <h3 className='pt-2 pb-2'>Dispatch time max</h3>
                                <Input required type="number" value={listingData.DispatchTimeMax}
                                    onChange={(e) => {
                                        setListingData({
                                            ...listingData,
                                            DispatchTimeMax: e.target.value
                                        })
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    {/* )
                    } */}

                </Row>
            </div>
            <div>
                <hr style={{ height: '1px' }} />
            </div>
        </>
    )
}

export default EbayPricing
