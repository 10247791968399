import React from 'react'
import { Row, Col, Input } from 'reactstrap'
import { VscSettings } from "react-icons/vsc";

import { useState } from 'react';
import { LuPencil } from "react-icons/lu";

import ReturnPolicyModal from './ReturnPolicyModal';
import { useSelector } from 'react-redux';

const EbayShipping = ({ listingData, setListingData }) => {



    const [returnModal, setReturnModal] = useState(false)

    const returnToggle = () => {
        setReturnModal(!returnModal)
    }
    const { shippingDetail } = useSelector((state) => state.getEbay)
    return (
        <>
            <div className='main_ebay_shipping_container pt-5'>
                {/* <div className='main_pricing_container'>
                    <h2 className='text-base font-bold '>SHIPPING</h2>
                    <div className='pricing_setting'>
                        <VscSettings size={'20px'} />
                        <p>see shipping option</p>
                    </div>
                </div> */}
                <Row>
                    <Col>
                        {/* <Row>
                        <Col md="5">
                        <h3 className='pb-2 pt-2'>Shipping method</h3>
                        <Input
                            value={listingData?.ReturnPolicy?.ReturnsWithinOption}
                            onChange={(e) => {
                                setListingData({
                                    ...listingData,
                                    ReturnPolicy: {
                                        ...listingData.ReturnPolicy,
                                        ReturnsWithinOption: e.target.value
                                    }

                                })

                            }}
                            // className=""

                            className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                            // value={Category}
                            type="select"
                        >
                            <option value='globalShipping'>Standard shipping: Small to medium items</option>
                            <option value="freightShipping">Freight: Oversized items</option>
                            <option value="localPickup">Local pickup only: Sell to buyers near you</option>
                        </Input>
                        </Col>
                  
                    </Row> */}


                        {/* <EbayDropDown selectedOption={selectedOption} setSelectedOption={setSelectedOption}/> */}

                        {/* <div className='d-flex gap-7'>
                            <div>
                                <span className='d-flex pt-1 pb-1'>Package weight<h3 className='text-gray-500 text-sm pt-[2px] '>(optional)</h3></span>
                                <div style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }} className='input_handled'>
                                    <span style={{ position: 'absolute', left: '50px', top: '50%', transform: 'translateY(-50%)', fontSize: '14px', color: 'gray' }}>Ibs.</span>
                                    <Input type="text" style={{ paddingRight: '42px', paddingLeft: '16px', width: '80px' }} />
                                </div>
                                <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                    <span style={{ position: 'absolute', left: '50px', top: '50%', transform: 'translateY(-50%)', fontSize: '14px', color: 'gray' }}>oz.</span>
                                    <Input type="text" style={{ paddingRight: '42px', paddingLeft: '16px', width: '80px' }} />
                                </div>
                            </div>

                            <div>
                                <span className='d-flex pt-1 pb-1'>Package dimensions<h3 className='text-gray-500 text-sm pt-[2px] '>(optional)</h3></span>
                                <div style={{ position: 'relative', display: 'inline-block', marginRight: '5px' }} className='input_handled'>
                                    <span style={{ position: 'absolute', left: '50px', top: '50%', transform: 'translateY(-50%)', fontSize: '14px', color: 'gray' }} >in.</span>
                                    <Input type="text" style={{ paddingRight: '42px', paddingLeft: '16px', width: '80px' }} />
                                </div>
                                <span className='mr-1'>x</span>
                                <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                    <span style={{ position: 'absolute', left: '50px', top: '50%', transform: 'translateY(-50%)', fontSize: '14px', color: 'gray' }} >in.</span>
                                    <Input type="text" style={{ paddingRight: '42px', paddingLeft: '16px', width: '80px', marginRight: '5px' }} />
                                </div>
                                <span className='mr-1'>x</span>
                                <div style={{ position: 'relative', display: 'inline-block' }} className='input_handled'>
                                    <span style={{ position: 'absolute', left: '50px', top: '50%', transform: 'translateY(-50%)', fontSize: '14px', color: 'gray' }}>in.</span>
                                    <Input type="text" style={{ paddingRight: '42px', paddingLeft: '16px', width: '80px' }} />
                                </div>

                            </div>
                        </div> */}
                        <Row className='my-4'>
                            <Col xs="12">
                                <div className='font-semibold'>
                                    Domestic shipping
                                </div>
                            </Col>
                            <Col md="5">
                                <h3 className='pb-2 pt-2'>Shipping Cost </h3>
                                <Input
                                required
                                    value={listingData?.ShippingServiceCost}
                                    onChange={(e) => {
                                        setListingData({
                                            ...listingData,
                                            ShippingServiceCost: e.target.value


                                        })

                                    }}
                                    // className=""

                                    className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                    // value={Category}
                                    type="number"
                                >


                                </Input>
                            </Col>

                            {/* <Col md="5">
                                <h3 className='pb-2 pt-2'>Shipping type</h3>
                                <Input
                                required
                                    value={listingData?.ShippingType}
                                    onChange={(e) => {
                                        setListingData({
                                            ...listingData,
                                            ShippingType: e.target.value


                                        })

                                    }}
                                    // className=""

                                    className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                    // value={Category}
                                    type="select"
                                >
                                     <option value=''>Select Shipping Type</option>
                                    <option value='Flat'>Flat rate: Same cost regardless of buyer location</option>
                                    <option value="Calculated">Calculated: Cost varies based on buyer location</option>

                                </Input>
                            </Col>
                            {
                                shippingDetail?.length > 0 &&
                                (
                                    <>
                                        <Col md="5">
                                            <h3 className='pb-2 pt-2'>Shipping Service</h3>
                                            <Input
                                            required
                                                value={listingData?.ShippingService}
                                                onChange={(e) => {
                                                    setListingData({
                                                        ...listingData,
                                                        ShippingService: e.target.value


                                                    })

                                                }}
                                                // className=""

                                                className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                                // value={Category}
                                                type="select"
                                            >
                                                <option value="">Select Shipping Service</option>
                                                {
                                                    shippingDetail?.map((obj) => (
                                                        <option value={obj?.ShippingService}>{obj?.ShippingService}</option>

                                                    ))
                                                }


                                            </Input>
                                        </Col>
                                    </>
                                )
                            } */}



                        </Row>

                        {/* <Row>
                            <Col md="3">
                                <div className='px-3 py-2 bg-gray-200 rounded-lg cursor-pointer w-max'>
                                    <span className='mr-2 text-lg'>+</span><span> Add Primary Services</span>
                                </div>
                            </Col>
                        </Row> */}

{/* 
                        <div>
                            <h3 className='text-base font-bold pt-[10px] pb-2 '>Preferences</h3>
                        </div>
                        <div className='shipping_setting'>
                            <div className='d-flex justify-between'>
                                <h3 className='font-medium pb-3 pt-2'> Your settings</h3>
                                <div className='shipping_edit_pencil pb-3 pt-3' onClick={returnToggle}>
                                    <LuPencil />
                                </div>
                            </div>
                            <h3 className='pt-2 pb-1'>{listingData?.ReturnPolicy?.ReturnsWithinOption}</h3>
                            <h3 className='pt-2 pb-1'>{listingData?.ReturnPolicy?.RefundOption}</h3>
                            <p className='text-sm text-gray-500 pb-3'>{listingData?.ReturnPolicy?.ShippingCostPaidByOption}</p>
                        </div> */}
                    </Col>


                </Row>






            </div>

            <ReturnPolicyModal returnModal={returnModal} returnToggle={returnToggle} listingData={listingData} setListingData={setListingData} />

        </>

    )
}

export default EbayShipping
