import React, { useState } from 'react'
import { Col, Input, Modal, ModalBody, Row } from 'reactstrap'
import { Switch } from '@headlessui/react'

const ReturnPolicyModal = ({ returnModal, returnToggle,listingData,setListingData  }) => {


    const [domestic, setDomestic] = useState(false)
    const [international,setInternational]=useState(false)
    return (
        <>
            <Modal
                isOpen={returnModal}
                toggle={returnToggle}
                size="md"
                centered={true}

            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Your Setting</div>
                    <div className="cursor-pointer" onClick={returnToggle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>

                </div>

                <ModalBody className="px-4 py-5">
                    {/* <Row>
                        <Col>
                            <div className='font-semibold'>
                                Item location
                            </div>

                        </Col>

                    </Row> */}
                    {/* <Row>
                        <Col>
                            <div>
                                <h3 className='pb-2 pt-2'>Country or region</h3>
                                <Input
                                    // className=""

                                    className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                    // value={Category}
                                    type="select"
                                // onChange={(e) => {
                                //     setCategory(e.target.value);
                                // }}
                                >
                                    <option value="WebSearch">PK</option>
                                    <option value="SpellCheck">UK</option>

                                </Input>
                            </div>


                        </Col>

                    </Row> */}

                    <Row className='my-4'>
                        <Col>
                            <div className='font-semibold'>
                                Domestic returns
                            </div>
                        </Col>
                    </Row>
                    <div className='px-4 py-2 border-2 rounded-sm border-solid  border-gray-300 '>
                        <Row>
                            <Col>
                                <div className='flex my-2   justify-between items-center'>

                                    <div className='font-medium text-md'>
                                        Accept returns

                                    </div>
                                    <div>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={domestic} // Set the 'checked' attribute based on your state
                                                onChange={(e) => {
                                                    setDomestic(e.target.checked)
                                                }}
                                                className="sr-only peer"
                                            />
                                            <div
                                                className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 ${domestic
                                                        ? "peer-checked:bg-blue-600"
                                                        : "peer-checked:bg-gray-200"
                                                    } dark:peer-focus:ring-blue-800 dark:border-gray-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                                            ></div>

                                        </label>
                                    </div>
                                </div>
                                <div className='py-2'>
                                    Allow returns for items purchased domestically.
                                </div>
                            </Col>

                        </Row>
                       {
                        domestic && (
                            <>
                             <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Allowed within</h3>
                                    <Input
                                    value={listingData?.ReturnPolicy?.ReturnsWithinOption}
                                    onChange={(e)=>{
                                        setListingData({
                                            ...listingData,
                                            ReturnPolicy:{
                                                ...listingData.ReturnPolicy,
                                                ReturnsWithinOption:e.target.value
                                            }

                                        })

                                    }}
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                        <option value="30_Days">30 Days</option>
                                        <option value="60_Days">60 Days</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Return shipping paid by</h3>
                                    <Input
                                     value={listingData?.ReturnPolicy?.ShippingCostPaidByOption}
                                     onChange={(e)=>{
                                         setListingData({
                                             ...listingData,
                                             ReturnPolicy:{
                                                ...listingData.ReturnPolicy,
                                                ShippingCostPaidByOption:e.target.value
                                             }
 
                                         })
 
                                     }}
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                        
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                        <option value="Buyer">Buyer</option>
                                        <option value="Free for buyer,you pay">Free for buyer,you pay</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Refund method</h3>
                                    <Input
                                     value={listingData?.ReturnPolicy?.RefundOption}
                                     onChange={(e)=>{
                                         setListingData({
                                             ...listingData,
                                             ReturnPolicy:{
                                                ...listingData.ReturnPolicy,
                                                RefundOption:e.target.value
                                             }
 
                                         })
 
                                     }}
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                        <option value="Money back">Money back</option>
                                        <option value="Money back  or replacement">Money back  or replacement</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                            </>
                        )
                       }
                    </div>


                    {/* <Row className='my-4'>
                        <Col>
                            <div className='font-semibold'>
                            International returns
                            </div>
                        </Col>
                    </Row>
                    <div className='px-4 py-2 border-2 rounded-sm border-solid  border-gray-300 '>
                        <Row>
                            <Col>
                                <div className='flex my-2   justify-between items-center'>

                                    <div className='font-medium text-md'>
                                    Accept returns

                                    </div>
                                    <div>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={international} // Set the 'checked' attribute based on your state
                                                onChange={(e) => {
                                                    setInternational(e.target.checked)
                                                }}
                                                className="sr-only peer"
                                            />
                                            <div
                                                className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 ${international
                                                        ? "peer-checked:bg-blue-600"
                                                        : "peer-checked:bg-gray-200"
                                                    } dark:peer-focus:ring-blue-800 dark:border-gray-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                                            ></div>

                                        </label>
                                    </div>
                                </div>
                                <div className='py-2'>
                                Allow returns for items purchased internationally.
                                </div>
                            </Col>

                        </Row>
                       {
                        international && (
                            <>
                             <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Allowed within</h3>
                                    <Input
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                         <option value="14_Days">14 Days</option>
                                        <option value="30_Days">30 Days</option>
                                        <option value="60_Days">60 Days</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Return shipping paid by</h3>
                                    <Input
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                        <option value="Buyer">Buyer</option>
                                        <option value="Free for buyer,you pay">Free for buyer,you pay</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2'>Refund method</h3>
                                    <Input
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                        <option value="Money back">Money back</option>
                                        <option value="Money back  or replacement">Money back  or replacement</option>

                                    </Input>
                                </div>


                            </Col>
                        </Row>
                            </>
                        )
                       }
                    </div> */}




                </ModalBody>
            </Modal>
        </>
    )
}

export default ReturnPolicyModal
