import React, { useState, useRef, useEffect } from "react";
import bravelogo from "../../src/assets/img/bravelogo.png";
import news from "../../src/assets/img/news.jpg";
import pubmed from "../../src/assets/img/pubmed.jpg";
import { UncontrolledTooltip } from "reactstrap";
import { IoIosArrowDown } from "react-icons/io";

export const PluginDropDown = ({ Plugin, setPlugin }) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    const dropdownData = [
        {
            id: 1,
            image: bravelogo,
            text: "Brave Search",
            key: "BraveSearch",
            tooltip: "Tooltip for Option 1",
        },
        {
            id: 2,
            image: news,
            text: "News",
            key: "News",
            tooltip: "Tooltip for Option 2",
        },
        {
            id: 3,
            image: pubmed,
            text: "Pubmed",
            key: "Pubmed",
            tooltip: "Tooltip for Option 3",
        },
    ];

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (event, item) => {
        event.stopPropagation();

        if (Plugin && Plugin.id === item.id) {
            // Uncheck the checkbox if it's already checked
            setPlugin(null);
        } else {
            // Check the checkbox and show tooltip
            setPlugin(item);
        }
    };

    const handleItemClick = (item) => {
        setPlugin(item);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div
                className="selected-item flex justify-between items-center"
                onClick={handleToggle}
            >
                <span className="w-[30px] h-[30px]">
                    <img src={Plugin?.image} />
                </span>
                <span>{Plugin ? Plugin.text : "Select Plugin"}</span>
                <span>
                    <IoIosArrowDown
                        style={{
                            fontSize: "18px",
                        }}
                    />
                </span>
            </div>
            {isOpen && (
                <div className="dropdown-content">
                    {dropdownData.map((item) => {
                        const isDisabled = item?.id == 3 || item?.id == 2;
                        return (
                            <div
                                id={`Tooltip-${item.id}`}
                                className={`dropdown-item flex bg-[#ffff]  items-center justify-between  ${
                                    isDisabled &&
                                    "opacity-50 pointer-events-none"
                                }`}
                                onClick={() => handleItemClick(item)}
                            >
                                <div>
                                    <img src={item.image} alt={item.text} />
                                </div>
                                <div className="mx-2 text-left">
                                    {item.text}
                                </div>
                                <div>
                                    <input
                                        className="dropdown-checkbox"
                                        type="checkbox"
                                        checked={
                                            Plugin && Plugin.id === item.id
                                        }
                                        onChange={(e) =>
                                            handleCheckboxChange(e, item)
                                        }
                                    />
                                </div>
                                <UncontrolledTooltip
                                    placement="right"
                                    target={`Tooltip-${item.id}`}
                                    autohide={false}
                                >
                                    <div className="tooltip-main-div">
                                        <img
                                            src={item.image}
                                            alt={item.text}
                                            className="tooltip-img"
                                        />
                                    </div>
                                    <div className="text-left fs--22 fw--500 ">
                                        {item.text}
                                    </div>
                                    <div className="text-justify">
                                        Ensuring private,unbiased browsing with
                                        no user tracking.A secure search
                                        experience integrated seamlessly into
                                        your chatbot
                                    </div>
                                </UncontrolledTooltip>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
