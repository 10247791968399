import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    LoginAction,
    addCustomizationAction,
    getCustomizationAction,
    updateCustomizationAction,
} from "../../store/actions/authAction";

export const ChatbotCustomization = () => {
    const { loading } = useSelector((state) => state.userData);
    const { user } = useSelector((state) => state.authUser);
    const { customizationData } = useSelector((state) => state.userData);
    console.log("loading--", loading);
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        // UserId: user?.UserId,
        // SidebarBackgroundColor: "#000",
        // SidebarTextColor: "#FFFFFF",
        // ChatbotBackgroundColor: "#444446",
        // ChatHistoryBackgroundColor: "#000",
        // ChatbotTextColor: "#FFFFFF",
        // UserMessageColor: "#FFFFFF",
        // ChatbotColor: "#FFFFFF",
        // CategoryTextColor: "#FFFFFF",
        // CategoryBackgroundColor: "#30b5f1",
        // HeaderBackgroundColor: "#000",
        // HeaderTextColor: "#FFFFFF",
        UserId: user?.UserId,
        SidebarBackgroundColor: "#000",
        SidebarTextColor: "#FFFFFF",
        ChatbotBackgroundColor: "#FFFFFF",
        ChatHistoryBackgroundColor: "#eaeaea",
        ChatbotTextColor: "#000",
        UserMessageColor: "#000",
        ChatbotColor: "#000",
        CategoryTextColor: "#FFFFFF",
        CategoryBackgroundColor: "#30b5f1",
        HeaderBackgroundColor: "#000",
        HeaderTextColor: "#FFFFFF",
    });

    useEffect(() => {
        if (customizationData != null) {
            setData({ UserId: user?.UserId, ...customizationData });
        }
    }, [customizationData]);
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleCustomization = (e) => {
        e.preventDefault();
        if (customizationData != null) {
            dispatch(
                updateCustomizationAction(data, () => {
                    history.push("/mainview");
                })
            );
        } else {
            dispatch(
                addCustomizationAction(data, () => {
                    history.push("/mainview");
                })
            );
        }
    };

    useEffect(() => {
        if (user?.UserId) {
            dispatch(getCustomizationAction(user?.UserId));
        }
    }, [user?.UserId]);
    return (
        <>
            <Container fluid className="auth  bg-black">
                <Row className="justify-content-center   py-3  signUp   ">
                    <Col md="8" className="bg-white  rounded-[12px] mx-2">
                        <Row className="d-flex justify-content-center">
                            <Col xs="12">
                                <div className="fs--24 fw--700 signUp__title text-center mt-3">
                                    Chat Bot Customization
                                </div>
                            </Col>
                            <Col md="9" className="">
                                <Card className=" signUp__card shadow p-3 mb-3 bg-white">
                                    <CardBody>
                                        <Form onSubmit={handleCustomization}>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Sidebar Background
                                                            Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="SidebarBackgroundColor"
                                                            value={
                                                                data?.SidebarBackgroundColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Sidebar Text Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="SidebarTextColor"
                                                            value={
                                                                data.SidebarTextColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Chatbot Background
                                                            Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="ChatbotBackgroundColor"
                                                            value={
                                                                data.ChatbotBackgroundColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Chatbot Text Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="ChatbotTextColor"
                                                            value={
                                                                data.ChatbotTextColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow"
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Chat History
                                                            Background Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="ChatHistoryBackgroundColor"
                                                            value={
                                                                data.ChatHistoryBackgroundColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            User Message Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="UserMessageColor"
                                                            value={
                                                                data.UserMessageColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Bot Message Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="ChatbotColor"
                                                            value={
                                                                data.ChatbotColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Category Text Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="CategoryTextColor"
                                                            value={
                                                                data.CategoryTextColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Category Background
                                                            Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="CategoryBackgroundColor"
                                                            value={
                                                                data.CategoryBackgroundColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            Header Background
                                                            Color
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="HeaderBackgroundColor"
                                                            value={
                                                                data.HeaderBackgroundColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label className="signUp__label">
                                                            HeaderTextColor
                                                        </Label>
                                                        <Input
                                                            type="color"
                                                            name="HeaderTextColor"
                                                            value={
                                                                data.HeaderTextColor
                                                            }
                                                            className="p-0 border-0 rounded-pillow "
                                                            required
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="3">
                                                    <Button
                                                        className="px-4 py-2 mt-3 signUp__button cursor-pointer"
                                                        disabled={loading}
                                                        type="submit"
                                                    >
                                                        {loading ? (
                                                            <Spinner size="md" />
                                                        ) : customizationData !=
                                                          null ? (
                                                            "Update"
                                                        ) : (
                                                            "Save"
                                                        )}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
