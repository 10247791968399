import { LOADER, LOGIN, LOGOUT, SINGLE_USER } from "../types";

import { toast } from "react-toastify";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var auth = RepositoryFactory.get("auth");

export const setAuthLoading = (val) => async (dispatch) => {
    dispatch({ type: LOADER, payload: val });
};
export const SignUpAction = (payload, onSuccess) => async (dispatch) => {
    console.log("payload", payload);
    await dispatch(setAuthLoading(true));
    try {
        let { data } = await auth.signuprep(payload);
        if (data?.detail == "Email already Registered") {
            toast.success(data?.detail);
            await dispatch(setAuthLoading(false));
        } else {
            await dispatch(setAuthLoading(false));
            onSuccess();
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setAuthLoading(false));
        dispatch(setAuthLoading(false));
    }
};
export const LoginAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
        let { data } = await auth.loginrep(payload);
        console.log("data===>", data);
        if (data?.detail?.UserId) {
            dispatch({ type: LOGIN, payload: data?.detail });
            onSuccess();
            await dispatch(setAuthLoading(false));
        } else {
            toast.warning(data?.detail);
            await dispatch(setAuthLoading(false));
        }
    } catch (e) {
        toast.error(e);
        await dispatch(setAuthLoading(false));
    }
};

export const UpdatePasswordAction =
    (payload, onSuccess) => async (dispatch) => {
        await dispatch(setAuthLoading(true));
        try {
            let { data } = await auth.UpdatePasswordRep(payload);

            if (data?.Detail == "User Password Updated Successfully") {
                onSuccess();
                toast.success(data?.Detail);
                await dispatch(setAuthLoading(false));
            } else {
                toast.error(data?.Detail?.Detail);
                await dispatch(setAuthLoading(false));
            }
        } catch (e) {
            toast.error(e);
            await dispatch(setAuthLoading(false));
        }
    };

// Forget Password Action is here

export const ForgetPasswordAction =
    (payload, onSuccess) => async (dispatch) => {
        await dispatch(setAuthLoading(true));
        try {
            let { data } = await auth.ForgetPasswordRep(payload);
            console.log("data,,,", data);

            if (data?.status_code == 200) {
                onSuccess("Verify OTP");
                toast.success(data?.detail);
                await dispatch(setAuthLoading(false));
            } else {
                toast.error(data?.detail);
                await dispatch(setAuthLoading(false));
            }
        } catch (e) {
            toast.error(e);
            await dispatch(setAuthLoading(false));
        }
    };

// Confirm Password Action

export const ConfirmPasswordAction =
    (payload, onSuccess) => async (dispatch) => {
        await dispatch(setAuthLoading(true));
        try {
            let { data } = await auth.ConfirmPasswordRep(payload);

            if (data?.status_code == 200) {
                onSuccess("New Password");
                toast.success(data?.detail);
                await dispatch(setAuthLoading(false));
            } else {
                toast.error(data?.detail);
                await dispatch(setAuthLoading(false));
            }
        } catch (e) {
            toast.error(e);
            await dispatch(setAuthLoading(false));
        }
    };

// This action is for Reset Password

export const ResetPasswordAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
        let { data } = await auth.ResetPasswordRep(payload);

        if (data?.status_code == 200) {
            onSuccess("Forget Password");
            toast.success(data?.detail);
            await dispatch(setAuthLoading(false));
        } else {
            toast.error(data?.detail);
            await dispatch(setAuthLoading(false));
        }
    } catch (e) {
        toast.error(e);
        await dispatch(setAuthLoading(false));
    }
};

export const UpdateHistoryStatusAction =
    (payload, onSuccess) => async (dispatch) => {
        try {
            let { data } = await auth.UpdateHistoryStatusRep(payload);

            if (data?.status_code) {
                onSuccess();
            } else {
                toast.error(data?.detail);
            }
        } catch (e) {
            toast.error(e);
        }
    };

export const JoinAction = (payload, onSuccess) => async (dispatch) => {
    console.log("payload", payload);
    await dispatch(setAuthLoading(true));
    try {
        let { data } = await auth.joinRep(payload);
        console.log("data", data);

        if (data?.Detail) {
            toast.success(data?.Detail);
            onSuccess();
            dispatch(setAuthLoading(false));
        } else {
            toast.error(data?.detail);
            dispatch(setAuthLoading(false));
        }
        // if (data?.user_id) {
        //     dispatch({ type: LOGIN, payload: data });
        //     onSuccess();
        //     await dispatch(setAuthLoading(false));
        // } else {
        //     toast.error(data?.detail);
        //     await dispatch(setAuthLoading(false));
        // }
    } catch (e) {
        toast.error(e.message);

        dispatch(setAuthLoading(false));
    }
};
export const VerifyAction = (payload, onSuccess) => async (dispatch) => {
    console.log("payload", payload);
    await dispatch(setAuthLoading(true));
    try {
        let { data } = await auth.verifyrep(payload);
        toast.success(data.detail);
        await dispatch(setAuthLoading(false));
        if (data?.user_id) {
            dispatch({ type: LOGIN, payload: data });
            onSuccess();
        } else {
            toast.error(data?.detail);
            await dispatch(setAuthLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setAuthLoading(false));
        dispatch(setAuthLoading(false));
    }
};

export const addCustomizationAction =
    (payload, onSuccess) => async (dispatch) => {
        await dispatch(setAuthLoading(true));
        try {
            let { data } = await auth.addCustomizationRep(payload);
            console.log("post data", data);
            if (data?.status_code == 200) {
                onSuccess();
                toast.success("customize successfully");
                await dispatch(setAuthLoading(false));
            } else {
                toast.warn(data?.detail);
                await dispatch(setAuthLoading(false));
            }
        } catch (e) {
            toast.error(e);
            await dispatch(setAuthLoading(false));
        }
    };
export const updateCustomizationAction =
    (payload, onSuccess) => async (dispatch) => {
        await dispatch(setAuthLoading(true));
        try {
            let { data } = await auth.updateCustomizationRep(payload);

            console.log("post data", data);
            if (data?.status_code == 200) {
                onSuccess();
                toast.success("settings saved!");
                await dispatch(setAuthLoading(false));
            } else {
                toast.warn(data?.detail);
                await dispatch(setAuthLoading(false));
            }
        } catch (e) {
            toast.error(e);
            await dispatch(setAuthLoading(false));
        }
    };

export const getCustomizationAction = (id) => async (dispatch) => {
    try {
        let { data } = await auth.getCustomizationRep(id);

        if (data?.detail?.ChatbotColor) {
            dispatch({ type: "CUSTOMIZE", payload: data?.detail });
        } else {
        }
    } catch (e) {
        toast.error(e);
    }
};
export const getUserAction = (id) => async (dispatch) => {
    try {
        let { data } = await auth.getUserRep(id);
        if (data?.status_code == 200) {
            dispatch({ type: SINGLE_USER, payload: data?.detail });
        } else {
            dispatch({ type: SINGLE_USER, payload: {} });
        }
    } catch (e) {
        toast.error(e);
    }
};

export const login = () => async (dispatch) => {
    dispatch({
        type: LOGIN,
        payload: { id: 123, first_name: "abc", last_name: "def" },
    });
};

export const logout = (onSuccess) => async (dispatch) => {
    localStorage.clear();
    onSuccess();
    dispatch({ type: "CUSTOMIZE", payload: null });
    dispatch({
        type: LOGOUT,
    });
};
