import React from 'react';

function MarkDownRenderer({ newsData, showMore, toggleShowMore }) {
  return (
    <div className="overflow-auto">
      <div dangerouslySetInnerHTML={{ __html: showMore ? newsData : newsData.slice(0, 200) }}></div>
    </div>
  );
}

export default MarkDownRenderer;