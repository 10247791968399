import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Step, Stepper } from "react-form-stepper";
import StripeCheckout from "../components/StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { TEST_CLIENT_KEY } from "../constants";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function Pricing() {
    const [activeStep, setActiveStep] = useState(0);
    const [stepComplete, setStepComplete] = React.useState(0);
    const [planData, setPlanData] = useState({
        price: "",
        plan: "",
    });
    const { user } = useSelector((state) => state.authUser);

    let stripePromise = loadStripe(TEST_CLIENT_KEY);

    useEffect(() => {
        if (planData.price) {
            setActiveStep(1);
        }
    }, [planData]);
    const history = useHistory();
    return (
        <div className="pricing-container overflow-auto vh-100  ">
            <Container className=" ">
                <Row className="mt-3">
                    <Col xs="12" className="text-white fs--16">
                        <Stepper activeStep={activeStep}>
                            <Step
                                label="Subscription"
                                styleConfig={{
                                    activeBgColor: "#c18542",
                                    inactiveBgColor: "#fff",
                                    activeTextColor: "#FFFF",
                                    inactiveTextColor: "#000",
                                    completedTextColor: "#FFFF",
                                    labelFontSize: "1.2rem",
                                    completedBgColor: "#008000",
                                    completedTextColor: "#FFFF",
                                    fontWeight: 500,
                                }}
                            />
                            <Step
                                label="Payment"
                                styleConfig={{
                                    activeBgColor: "#c18542",
                                    inactiveBgColor: "#fff",
                                    completedBgColor: "#00cc00",
                                    activeTextColor: "#FFFF",
                                    inactiveTextColor: "#000",
                                    completedTextColor: "#FFFF",

                                    labelFontSize: "1.2rem",
                                    fontWeight: 500,
                                }}
                            />
                        </Stepper>
                    </Col>
                </Row>
                {activeStep == 0 && (
                    <Row className="">
                        <Col className="my-5">
                            <div className=" flex flex-col items-center justify-center gap-3 font-mono text-white md:flex-row">
                                <div
                                    className="card-design md:w-[calc(100%/3)] max-w-[calc(100%-1rem)] border p-4 rounded-md bg-black w-72 border-2 flex flex-col border-white/30  h-[30em] lg:h-[25em]"
                                    delay="0.25"
                                    // style="transform: none;"
                                >
                                    <div className="mb-1 flex items-center gap-2">
                                        <h2 className="flex-grow text-xl font-semibold">
                                            Free Trial
                                        </h2>
                                    </div>
                                    <p className="mb-4 text-xs text-gray-400">
                                        A small taste of what AgentGPT offers.
                                    </p>
                                    <div className="flex items-center gap-2">
                                        <p className="text-4xl font-black">
                                            $0
                                        </p>
                                        <div className="flex flex-col">
                                            <p className="text-xs text-gray-400">
                                                / month
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="my-3 border-[1.5px] border-white/30" />
                                    <p className="mb-2 font-black">Features:</p>
                                    <div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                10 demo agents a day using
                                                GPT-3.5-Turbo
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Limited plugin integrations
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Limited web search capabilities
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow"></div>
                                    <div className="flex justify-center py-3">
                                        <Button
                                            onClick={() => {
                                                history.push("/mainview");
                                            }}
                                            className="button-design relative rounded-lg border-2 border-black/20 px-4 py-1 font-bold text-white transition-all duration-300 sm:px-10 sm:py-3 cursor-pointer bg-[#1E88E5]/80 text-white hover:bg-[#0084f7] hover:shadow-lg bg-zinc-600"
                                        >
                                            <div className="relative">
                                                <div className="flex h-full w-full items-center justify-center">
                                                    Continue
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    className="card-design  relative  md:w-[calc(100%/3)] max-w-[calc(100%-1rem)] border p-4 rounded-md bg-black w-72 border-2 flex flex-col border-sky-500 sm:h-[32em] lg:h-[27em]"
                                    // style="transform: none;"
                                >
                                    <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white text-center">
                                        <span>Coming Soon...</span>
                                    </div>

                                    <div className="mb-1 flex items-center gap-2">
                                        <h2 className="flex-grow text-xl font-semibold">
                                            PRO
                                        </h2>
                                        <div className="bg-blue-600 border-2 border-white/20 bg-gradient-to-t from-sky-500 to-sky-600 rounded-full px-2 py-1 text-xs font-semibold text-gray-100 transition-all hover:scale-110 sm:px-3 sm:py-1 sm:text-sm">
                                            Most Popular
                                        </div>
                                    </div>
                                    <p className="mb-4 text-xs text-gray-400">
                                        Powerful AI agents at your fingertips.
                                    </p>
                                    <div className="flex items-center gap-2">
                                        <p className="text-4xl font-black">
                                            $40
                                        </p>
                                        <div className="flex flex-col">
                                            <p className="text-xs text-gray-400">
                                                / month
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="my-3 border-[1.5px] border-sky-500" />
                                    <p className="mb-2 font-black">Features:</p>
                                    <div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                50 Agents per day
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                GPT-3.5-Turbo 16k access
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                GPT-4 access
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Unlimited web search
                                                capabilities
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Access to the latest AgentGPT
                                                plugins
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Priority support
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow"></div>
                                    <div className="flex justify-center py-3">
                                        <Button
                                            disabled={true}
                                            onClick={() => {
                                                if (user?.user_id) {
                                                    setPlanData({
                                                        ...planData,
                                                        price: 40,
                                                        plan: "PRO",
                                                    });
                                                } else {
                                                    toast.warn(
                                                        "First  you sign Up  Account"
                                                    );
                                                }
                                                // setActiveStep(1);
                                            }}
                                            className="button-design relative rounded-lg border-2 border-black/20 px-4 py-1 font-bold text-white transition-all duration-300 sm:px-10 sm:py-3 cursor-pointer  text-white  hover:shadow-lg border-white/20  transition-all"
                                        >
                                            <div className="relative">
                                                <div className="flex h-full w-full items-center justify-center">
                                                    Subscribe
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    className="card-design  relative md:w-[calc(100%/3)] max-w-[calc(100%-1rem)] border p-4 rounded-md bg-black w-72 border-2 flex flex-col border-white/30 h-[30em] lg:h-[25em]"
                                    delay="0.25"
                                    // style="transform: none;"
                                >
                                    <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white text-center">
                                        <span>Coming Soon...</span>
                                    </div>
                                    <div className="mb-1 flex items-center gap-2">
                                        <h2 className="flex-grow text-xl font-semibold">
                                            Enterprise
                                        </h2>
                                    </div>
                                    <p className="mb-4 text-xs text-gray-400">
                                        AI agents tailored to automate your
                                        business.
                                    </p>
                                    <div className="flex items-center gap-2">
                                        <p className="text-4xl font-black">
                                            Custom
                                        </p>
                                        <div className="flex flex-col">
                                            <p className="text-xs text-gray-400">
                                                / month
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="my-3 border-[1.5px] border-white/30" />
                                    <p className="mb-2 font-black">Features:</p>
                                    <div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Everything in the Pro plan
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Features based on enterprise
                                                requirements
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                SAML single sign-on
                                            </div>
                                        </div>
                                        <div className="mb-2 ml-2 flex gap-2 text-xs">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                className="flex-shrink-0 text-green-500"
                                                height="15"
                                                width="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                            </svg>
                                            <div className="text-gray-400">
                                                Dedicated account manager
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow"></div>
                                    <div className="flex justify-center py-3">
                                        <Button
                                            disabled={true}
                                            className="button-design relative rounded-lg border-2 border-black/20 px-4 py-1 font-bold text-white transition-all duration-300 sm:px-10 sm:py-3 cursor-pointer bg-[#1E88E5]/80 text-white hover:bg-[#0084f7] hover:shadow-lg bg-zinc-600"
                                        >
                                            <div className="relative">
                                                <div className="flex h-full w-full items-center justify-center">
                                                    Contact Us
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {activeStep == 1 && (
                    <>
                        <Col className="m-auto" md={8}>
                            <Elements stripe={stripePromise}>
                                <StripeCheckout planData={planData} />
                            </Elements>
                        </Col>
                    </>
                )}
            </Container>
        </div>
    );
}
