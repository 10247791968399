import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ebayDescriptionAction } from '../../store/actions/getEbayAction'
import { Spinner } from 'reactstrap'
const EbayDescription = ({listingData,setListingData}) => {
const {loading}=useSelector((state)=>state.getEbay)

    const dispatch = useDispatch()
    const generateDescription = (e) => {
        e.preventDefault()

        let payload= {
                Title:listingData.Title,
          }


        dispatch(ebayDescriptionAction(payload))
    }
    return (
        <>
            <div className='main_desc_container '>
                <h3>DESCRIPTION</h3>
                <textarea className='ebay_desc ' value={listingData.Description} onChange={(e)=>{
                    setListingData(e.target.value)
                }}>
                </textarea>
                <div className='btn_ai_desc'>
                    <button onClick={
                        generateDescription
                    }
                    disabled={loading}
                    >
                        {
                            loading ? <Spinner/> : "Use AI description"
                        }
                        </button>
                </div>
            </div>
            <div>
                <hr style={{ height: '1px' }} />
            </div>
        </>
    )
}

export default EbayDescription
