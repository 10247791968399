import { useEffect } from "react";
import AuthNavbar from "../components/AuthNavbar";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Auth = (props) => {
    const history = useHistory();
    const { uid } = useSelector((state) => state.authUser);
    // useEffect(() => {
    //     if (uid) {
    //         history.push("/mainview");
    //     }
    // }, [uid]);
    return (
        <>
            <AuthNavbar />
            {props.children}
        </>
    );
};

export default Auth;
