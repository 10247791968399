import React from "react";
import SearchBar from "../components/SearchBar";
import { Col, Row } from "reactstrap";
import { useState } from "react";
import { ChatBotMessages } from "../components/ChatBotMessages";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { BraveSearchMessage } from "../components/BraveSearchMessage";
import { getBraveSearchHistory } from "../store/actions/chattingAction";

const BraveSearch = () => {
    const [query, setQuery] = useState("");

    const [Category, setCategory] = useState("WebSearch");
    const [chatId, setChatId] = useState(-1);
    const [SubCategory, setSubCategory] = useState("AIPRO");
    const { uid } = useSelector((state) => state.authUser);

    const dispatch = useDispatch();
    useEffect(() => {
        let payload = {
            UserId: uid,
            Category: Category,
        };
        dispatch(getBraveSearchHistory(payload, true));
        setQuery("");
        setShowDropdown(false);
    }, [Category]);

    const { chat, braveSearchChat } = useSelector((state) => state.chatting);
    const [chats, setChats] = useState([]);
    const history = useHistory();

    const [visibleItems, setVisibleItems] = useState();
    const [showDropdown, setShowDropdown] = useState(false);
    const handleShowMore = () => {
        setVisibleItems((prev) => prev + 3);
    };
    const chatContainerRef = useRef(null);
    const chatBodyRef = useRef(null);

    useEffect(() => {
        setChats(braveSearchChat);
        setVisibleItems(3);
    }, [braveSearchChat]);

    return (
        <>
            <Row className="justify-center">
                <Col xs="12" md="10" className=" mt-4 mb-1 ">
                    <SearchBar
                        query={query}
                        setQuery={setQuery}
                        Category={Category}
                        setCategory={setCategory}
                        setSubCategory={setSubCategory}
                        SubCategory={SubCategory}
                        setChatId={setChatId}
                        chatId={chatId}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <BraveSearchMessage
                        chats={chats}
                        visibleItems={visibleItems}
                        handleShowMore={handleShowMore}
                        chatContainerRef={chatContainerRef}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BraveSearch;
