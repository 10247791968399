// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import chattingReducer from "./chattingReducer";
import authReducer from "./authReducer";
import getEbayReducer from "./getEbayReducer";
import ticketReducer from "./ticketReducer";
import newsReducer from "./newsReducer";
export let rootReducer = combineReducers({
    authUser: authUserReducer,
    userData: authReducer,
    chatting: chattingReducer,
    getEbay: getEbayReducer,
    ticket: ticketReducer,
    news: newsReducer,
});

export default rootReducer;
