import React, { useState } from 'react'
import { Button, Col, Form, Input, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import { Switch } from '@headlessui/react'
import { HiOutlineSearch } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { getEbayCategoryAction } from '../../store/actions/getEbayAction'
import { toast } from 'react-toastify'

const EbayCategoryModal = ({setCategoryName, categoryModal, categoryToggle,listingData, setListingData }) => {

    const [keyword, setKeyword] = useState("")
    const { uid } = useSelector((state) => state.authUser)

    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault()
        if(keyword!=""){
            const obj = {
                id: uid,
                keyword
            }
            dispatch(getEbayCategoryAction(obj,()=>{
                setKeyword("")
            }))
        }else{
            toast.warn("Enter Keyword")
        }
       

    }
    const handleCancel=()=>{
        setListingData({
            ...listingData,
            PrimaryCategory:{
                CategoryID:""
            }

        })
        categoryToggle()
    }
    const addCategory=()=>{
        categoryToggle()
    }
    const { ebayCategory, loading } = useSelector((state) => state.getEbay)

    return (
        <>
            <Modal
                isOpen={categoryModal}
                toggle={categoryToggle}
                size="md"
                centered={true}

            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Add Category</div>
                    <div className="cursor-pointer" onClick={categoryToggle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>

                </div>

                <ModalBody className="px-4 py-5">
                    {/* <Form onSubmit={handleSubmit}> */}
                        <Row>
                            <Col>
                                <div className="relative w-full">
                                    <div className="flex items-center">
                                        <input
                                            type="text"
                                            value={keyword}
                                            onChange={(e) => {

                                                setKeyword(e.target.value)

                                            }}
                                            placeholder="Enter Keyword"
                                            className="w-full py-2 px-5 pl-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                                        // value={query}
                                        // required
                                        // onChange={handleInputChange}

                                        />
                                        <HiOutlineSearch
                                            size={20}
                                            className="w-5 h-5 text-gray-400 absolute left-3 top-3"
                                        />
                                        <div className="ml-2">
                                            <Button
                                                type="button"
                                                onClick={handleSubmit}
                                                className="bg-gradient-to-t from-sky-500 to-sky-600"
                                                disabled={loading}
                                            >

                                                {loading ? (
                                                    <Spinner size="md" />
                                                ) : (
                                                    "Search"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>
                        <Row className='my-3'>
                            <Col>
                                <div>
                                    <h3 className='pb-2 pt-2 text-muted '>Add Category</h3>
                                    <Input
                                        value={listingData?.PrimaryCategory?.CategoryID}
                                        onChange={(e)=>{
                                            setListingData({
                                                ...listingData,
                                                PrimaryCategory:{
                                                    CategoryID:e.target.value
                                                }

                                            })
                                            let v=ebayCategory?.find((obj)=>obj.Category?.CategoryID==e.target.value)
                                           console.log("v",v)
                                            setCategoryName(v.Category.CategoryName)


                                        }}
                                        // className=""

                                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                                        // value={Category}
                                        type="select"
                                    // onChange={(e) => {
                                    //     setCategory(e.target.value);
                                    // }}
                                    >
                                         <option disabled value="">Select Category</option>
                                        {
                                            ebayCategory?.map((obj) => (
                                                <option value={obj.Category?.CategoryID

                                                }>{obj.Category?.CategoryName}</option>
                                            ))
                                        }


                                    </Input>
                                </div>


                            </Col>
                        </Row>
                    {/* </Form> */}


                    <div className='my-3  text-right'>
                    <Button className='bg-gradient-to-t from-sky-500 to-sky-600 mr-2 px-2 py-2' onClick={addCategory}>
                            Add 
                        </Button>
                        <Button className='bg-danger px-2 py-2' onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>






                </ModalBody>
            </Modal>
        </>
    )
}

export default EbayCategoryModal
