import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoOpenTruth from "../../assets/img/logoOpenTruth.png";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    LoginAction,
    UpdatePasswordAction,
} from "../../store/actions/authAction";

export const UpdatePassword = () => {
    const { loading } = useSelector((state) => state.userData);
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        Email: "",
        NewPassword: "",
        OldPassword: "",
    });
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(
            UpdatePasswordAction(data, () => {
                history.push("/login");
            })
        );
    };
    return (
        <>
            <Container fluid className="auth  bg-black">
                <Row className="justify-content-center  py-3  signUp   ">
                    <Col md="5">
                        <Card className=" signUp__card shadow p-3 mb-3 bg-white">
                            {/* <div className="text-center my-3">
                                <img
                                    className="signUp__im img-fluid"
                                    src={logoOpenTruth}
                                />
                            </div> */}
                            <div className="fs--24 fw--700 signUp__title text-center">
                                Update Password
                            </div>

                            <CardBody>
                                <Form onSubmit={handleRegister}>
                                    <FormGroup>
                                        <Label className="signUp__label">
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            name="Email"
                                            placeholder="Email"
                                            className="signUp__input"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mt-2">
                                        <Label className="signUp__label">
                                            Old Password
                                        </Label>
                                        <Input
                                            required
                                            type="password"
                                            className="signUp__input"
                                            placeholder="Old Password"
                                            name="OldPassword"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mt-2">
                                        <Label className="signUp__label">
                                            New Password
                                        </Label>
                                        <Input
                                            required
                                            type="password"
                                            className="signUp__input"
                                            placeholder="New Password"
                                            name="NewPassword"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <Button
                                        className="w-100 mt-3 signUp__button cursor-pointer"
                                        disabled={loading}
                                        type="submit"
                                    >
                                        {loading ? (
                                            <Spinner size="md" />
                                        ) : (
                                            "Save"
                                        )}
                                    </Button>
                                    {/* <p className="mt-2 fs--18 fw--700 text-center signUp__login">
                                        <Link to="/signup">Sing Up</Link>
                                    </p> */}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
