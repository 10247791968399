import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

const EbayModal = ({ ebayModal, ebayToggle }) => {
    const history = useHistory();
    return (
        <>
            <Modal
                isOpen={ebayModal}
                toggle={ebayToggle}
                size="md"
                centered={true}
                className="modal"
            >
                <div className="bg-gradient-info modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Ebay</div>
                    <div className="cursor-pointer" onClick={ebayToggle}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <ModalBody className="px-4 py-5">
                    <Row>
                        <Col>
                            <div>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        ebayToggle();
                                        history.push("/ebay-listing");
                                    }}
                                    className="signUp__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                                >
                                    Ebay Listing
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        ebayToggle();
                                        history.push("/Ebay");
                                    }}
                                    className="signUp__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                                >
                                    View Ebay Sales
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <div className="text-right">
                                <Button
                                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        ebayToggle();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

export default EbayModal;
