import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoOpenTruth from "../../assets/img/logoOpenTruth.png";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { JoinAction, LoginAction } from "../../store/actions/authAction";

export const JoinWaitList = () => {
    const { loading } = useSelector((state) => state.userData);
    console.log("loading--", loading);
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        Company: "",
    });

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(
            JoinAction(data, () => {
                history.push("/");
            })
        );
    };
    return (
        <>
            <div className="vh-100   overflow-auto bg-black ">
                <Container>
                    <Row className="justify-content-center  py-3  signUp  ">
                        <Col md="11" className="bg-transparent">
                            <Form onSubmit={handleRegister}>
                                <div className="text-white fs--22 fw--500  mt-2 mb-4">
                                    Please fill in your name and email address.
                                    We will notify you when you have access.
                                </div>

                                <FormGroup className="my-4">
                                    <Label className="text-left fs--20 fw--500 text-white">
                                        First name *
                                    </Label>
                                    <Input
                                        type="text"
                                        name="FirstName"
                                        placeholder="John"
                                        className="join__input bg-transparent"
                                        required
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                <FormGroup className="my-4">
                                    <Label className="text-left fs--20 fw--500 text-white">
                                        Last name
                                    </Label>
                                    <Input
                                        type="text"
                                        name="LastName"
                                        placeholder="Smith"
                                        className="join__input bg-transparent"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="my-4">
                                    <Label className="text-left fs--20 fw--500 text-white">
                                        Email *
                                    </Label>
                                    <Input
                                        type="email"
                                        name="Email"
                                        placeholder="john@gmail.com"
                                        className="join__input bg-transparent"
                                        required
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="my-4">
                                    <Label className="text-left fs--20 fw--500 text-white">
                                        Company
                                    </Label>
                                    <Input
                                        type="text"
                                        name="Company"
                                        placeholder="Aceme"
                                        className="join__input bg-transparent"
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {/* <FormGroup className="mt-2">
                                    <Label className="signUp__label">
                                        Password
                                    </Label>
                                    <Input
                                        required
                                        type="password"
                                        className="signUp__input"
                                        placeholder="Password"
                                        name="Password"
                                        onChange={handleChange}
                                    />
                                </FormGroup> */}
                                <div className="text-left">
                                    <Button
                                        className="px-3 py-2 mt-3 signUp__button cursor-pointer"
                                        disabled={loading}
                                        type="submit"
                                    >
                                        {loading ? (
                                            <Spinner size="md" />
                                        ) : (
                                            "Save"
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
