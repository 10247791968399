import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Spinner,
    Button,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
} from "reactstrap";
import {
    ebayImagesAction,
    getEbayAction,
    getEbayDataAction,
    getEbayTokenAction,
    logoutEbayAction,
} from "../store/actions/getEbayAction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import dayjs from "dayjs";

import { FaEye } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import EbayListingCard from "../components/EbayListingCard";
import { EbayListingUpload } from "../components/EbayListingUpload";
import ConnectEbay from "../components/ConnectEbay";
import { toast } from "react-toastify";

const EbayListing = () => {
    const { data, loading, ebayImagePath, ebayImageData,createLoading } = useSelector((state) => state.getEbay);
    const { uid } = useSelector((state) => state.authUser);
    const history = useHistory()
    // const [Ebayitems, setEbayitems] = useState([]);
    // const [filterItems, setFilterItems] = useState([]);
    const dispatch = useDispatch();
    const [list, setList] = useState(12);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [keyword, setKeyword] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [buttonShow, setButtonShow] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null)
    console.log("ebayImagePath__", ebayImagePath)
    const [apiStatus, setApiStatus] = useState(true)
    useEffect(() => {
        let timeoutId;
        const callApi = () => {

            dispatch(getEbayDataAction(ebayImagePath?.ResponseId, (value) => {
      
                setApiStatus(value);
            }));

            // Set a new timeout for the next API call
            timeoutId = setTimeout(callApi, 1000);
        };
        if (ebayImagePath?.ResponseId && apiStatus) {

            callApi();
        }

        // Cleanup to clear the timeout when the component unmounts or conditions change
        return () => clearTimeout(timeoutId);

    }, [ebayImagePath?.ResponseId, apiStatus, dispatch]);

    useEffect(() => {
        if (uid) {
            // Function to fetch eBay token
            const fetchToken = () => {
                dispatch(
                    getEbayTokenAction(uid, () => {
                        setButtonShow(false);
                    })
                );
            };

            // Call fetchToken immediately (first time)
            fetchToken();

            // Set an interval to call fetchToken every 2 hours
            const intervalId = setInterval(fetchToken, 2 * 60 * 60 * 1000);

            // Clean up the interval if the component unmounts or uid changes
            return () => clearInterval(intervalId);
        }
    }, [uid, buttonShow]);

    const toggleSelect = (id, title, imgUrl) => {
        const selectedItem = { id, title, imgUrl };
        if (isSelected(id)) {
            setSelectedItems(selectedItems.filter((item) => item.id !== id));
        } else {
            setSelectedItems([...selectedItems, selectedItem]);
        }
    };

    // Function to check if item is selected
    const isSelected = (id) => {
        return selectedItems.some((item) => item.id === id);
    };
    const handleListing = (e) => {
        e.preventDefault()
         if (selectedItems.length >= 1) {
            history.push({
                pathname: "/complete-listing",
                state: selectedItems
            });
         } else {
             toast.error("Select Minimum 1 Images")
         }
    }
    const logoutEbay=(e)=>{
        e.preventDefault()
        dispatch(logoutEbayAction(uid,()=>{
            setButtonShow(true);
        }))
    }
    return (
        <Container>
            <Row className="my-3">
                <Col  >
                    <div className="text-center text-2xl font-semibold ">
                        Ebay Listing
                    </div>


                </Col>
               
               
            </Row>
            {buttonShow ? (
                <ConnectEbay
                    buttonShow={buttonShow}
                    setButtonShow={setButtonShow}
                />
            ) : (
                <>
                        <div className="text-center">
                            <Button
                                 onClick={logoutEbay}
                                className="button-search px-4"
                                disabled={createLoading}
                            >
                                {
                                    createLoading ?  <Spinner size="sm" /> : "Logout Ebay"
                                } 
                            </Button>
                        </div>
                    <EbayListingUpload setApiStatus={setApiStatus} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    {
                        selectedItems?.length > 0 && (
                            <Row>
                                <Col className="flex justify-end">
                                    <Button onClick={handleListing}
                                        className="cursor-pointer button-search">
                                        Save
                                    </Button>

                                </Col>
                            </Row>

                        )
                    }

                    {

                        loading ?
                            <>
                                <div className="flex justify-center my-5 ">
                                    <Spinner size="lg" />
                                </div>
                            </> :
                            <>
                                <Row>
                                    {
                                        ebayImageData?.length > 0 &&
                                        <Col>

                                            <div className="text-center text-muted">
                                                Minimum  Select 5 Images
                                            </div>
                                        </Col>
                                    }

                                </Row>

                                <Row className="mt-1 my-4 ">

                                    {ebayImageData?.map((item, index) => (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={3}
                                            className="my-2 cursor-pointer ebay_card"
                                        >
                                            <EbayListingCard
                                                item={item}
                                                isSelected={isSelected}
                                                toggleSelect={toggleSelect}
                                            />
                                        </Col>




                                    ))}
                                </Row>
                            </>


                    }



                </>

            )}



        </Container>
    );
};
export default EbayListing;
