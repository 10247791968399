import Repository from "./Repository";
const CREATETICKET = "/Ticket/CreateTicket-No-File";
const CREATETICKETFILE = "/Ticket/CreateTicket-Files";
const GET_TICKET_DETAILS = "/Ticket/Get-User-Tickets";
const TICKETCHAT = "/Ticket/Ticket-Chat";
const GET_CHAT = "/Ticket/Get-Ticket-Chat";


export default {
    createTicket(payload, formData) {
        if (formData.length == 0) {
            return Repository.post(
                `${CREATETICKET}?UserId=${payload.UserId}&Title=${payload.Title}&Description=${payload.Description}`
            );
        } else {
            return Repository.post(
                `${CREATETICKETFILE}?UserId=${payload.UserId}&Title=${payload.Title}&Description=${payload.Description}`,
                formData
            );
        }
    },

    getTicketRepo(ID) {
        return Repository.get(`${GET_TICKET_DETAILS}?UserId=${ID}`);
    },

    ticketChat(payload) {
        return Repository.post(`${TICKETCHAT}`, payload);
    },
    
    getChat(payload) {
        return Repository.get(`${GET_CHAT}?TicketId=${payload.TicketId}`);
    },
};
