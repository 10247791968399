import React, { useState } from 'react';
import Select from 'react-select';



const EbayDropDown = ({selectedOption, setSelectedOption}) => {
    

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 400
        }),
        menu: (provided) => ({
            ...provided,
            width: 400
        })
    };

    return (
        <div className='dropdown_main'>
            <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                // options={options}
                styles={customStyles}
            />
        </div>
    )
}

export default EbayDropDown;


