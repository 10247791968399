import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Spinner,
    Button,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
} from "reactstrap";
import { getEbayAction } from "../store/actions/getEbayAction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import dayjs from "dayjs";
import { FaEye } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Ebay = () => {
    const { data, loading } = useSelector((state) => state.getEbay);

    const [Ebayitems, setEbayitems] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const dispatch = useDispatch();
    const [list, setList] = useState(12);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        dispatch(getEbayAction(keyword));
    }, []);

    useEffect(() => {
        let display = displayedItems();

        setEbayitems(display);
        setFilterItems(display);
        let pages = Math.ceil(data.length / 12);
        setTotalPages(pages);
    }, [data]);

    useEffect(() => {
        let display = displayedItems();

        setEbayitems(display);
        setFilterItems(display);
    }, [page]);

    function displayedItems() {
        const startIndex = (page - 1) * 12;
        const endIndex = startIndex + 12;
        let startNumber = startIndex;
        let endNumber = endIndex;
        return data.slice(startIndex, endIndex);
    }

    const getDataKeyword = (e) => {
        e.preventDefault();
        dispatch(getEbayAction(keyword));
    };
    return (
        <Container>
            <Row className="mt-4 flex justify-between">
                <Col
                    xs={8}
                    className="d-flex align-items-center justify-content-end"
                >
                    <Input
                        type="text"
                        placeholder="Keyword"
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value);
                        }}
                        className="form-control w-75"
                    />
                </Col>
                <Col xs={4}>
                    <div className="ml-2">
                        <Button
                            onClick={getDataKeyword}
                            className="button-search"
                        >
                            Search
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-1 my-4 ">
                {loading ? (
                    <div className="text-center mx-auto">
                        <Spinner className="mt-5 text-center" color="primary" />
                    </div>
                ) : (
                    <>
                        {Ebayitems?.map((item, index) => (
                            <>
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    className="my-2 cursor-pointer ebay_card "
                                >
                                    <a href={item?.viewItemURL} target="_blank">
                                        {/* <div className="border-b h-[10rem]">
                                              
                                            </div> */}

                                        <Row className="hover:drop-shadow-md border mx-1">
                                            <Col
                                                md="4"
                                                className="flex align-center px-0  justify-center bg-[#f2f2f2]"
                                            >
                                                <div className=" my-auto">
                                                    <img
                                                        alt="Sample"
                                                        src={
                                                            item?.galleryURL[0]
                                                        }
                                                        style={{
                                                            maxWidth: "100%", // Set a maximum width to prevent stretching
                                                            maxHeight: "100%",
                                                            objectFit: "cover",

                                                            // Automatically adjust the height to maintain aspect ratio
                                                            display: "block", // Remove extra space below the image
                                                            // Center the image horizontally
                                                        }}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="py-2 px-2">
                                                    <div
                                                        tag="h5"
                                                        className="ebay_card__title "
                                                    >
                                                        {item?.title[0]}
                                                    </div>
                                                    <div
                                                        className="mb-2 ebay_card__price"
                                                        tag="h6"
                                                    >
                                                        $
                                                        {
                                                            item?.sellingStatus
                                                                ?.currentPrice
                                                                ?.__value__
                                                        }{" "}
                                                    </div>
                                                    <div>
                                                        <div className="md:flex justify-between aligns-center">
                                                            <div className="flex ">
                                                                <span>
                                                                    <FaEye className="text-primary fs--22" />
                                                                </span>
                                                                <span className="ml-2 text-muted">
                                                                    {item
                                                                        ?.listingInfo
                                                                        ?.watchCount[0]
                                                                        ? item
                                                                              ?.listingInfo
                                                                              ?.watchCount[0]
                                                                        : 0}
                                                                </span>
                                                            </div>
                                                            <div className="text-muted ">
                                                                {item
                                                                    ?.listingInfo
                                                                    ?.EndDate[0] &&
                                                                    dayjs(
                                                                        item
                                                                            ?.listingInfo
                                                                            ?.EndDate[0]
                                                                    ).format(
                                                                        "DD-MM-YYYY"
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                            </>
                        ))}
                        {Ebayitems.length == 0 && (
                            <div className="text-center mx-auto">
                                <div className="mt-5 text-center">
                                    No Data Found
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Row>
            {Ebayitems.length > 0 && (
                <Row className="flex justify-center mt-3 mb-5">
                    <Col md={12}>
                        <div className="d-flex justify-center">
                            <Pagination
                                page={page}
                                setPage={(newPage) => setPage(newPage)}
                                totalPages={totalPages}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};
export default Ebay;
