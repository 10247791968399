import React, { useRef } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { FaFilePdf, FaFilterCircleXmark, FaRobot } from "react-icons/fa6";
import { GrAttachment } from "react-icons/gr";
import { DotPulse, Ring } from "@uiball/loaders";
import { IoSend } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const ChatbotFooter = ({
    data,
    setCategory,
    category,

    setTypeMessage,
    typeMessage,
    toggleFilter,
    file,
    setFile,
    graphicSelected,
    submit,
    setGraphicSelected,
}) => {
    const allowedTypes = [
        "application/pdf",

        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "text/plain",
    ];
    const fileRef = useRef(null);
    const { chat, loading, chattingId, historyChat, delete_loader, sitesData } =
        useSelector((state) => state.chatting);
    const handleFile = (e) => {
        console.log("selectedFile e", e.target.files[0]);
        const selectedFile = e.target.files[0];
        console.log("selectedFile", selectedFile);
        if (selectedFile) {
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
                fileRef.current.value = "";
            } else {
                toast.warn(
                    "Please select a valid document type (pdf, doc, docx, csv, txt)."
                );
            }
        }
    };
    return (
        <>
            <Row
                className={`mx-3 my-2 px-2 align-items-center rounded-[20px]    `}
                style={{
                    background: data?.ChatHistoryBackgroundColor,
                }}
            >
                <Col
                    md="2"
                    xs="4"
                    className="flex align-items-center p-0"
                    style={{
                        color: data?.ChatbotTextColor,
                    }}
                >
                    <div
                        className="text-center border-r-2  py-3 p-0 pl-1 w-[200px] footer-font"
                        style={{
                            borderColor: data?.ChatbotTextColor,
                        }}
                    >
                        Category
                    </div>
                </Col>
                <Col md="5" xs="4" className="justify-content-left d-flex">
                    <div className="w-full ">
                        <Input
                            className="w-full chat-input  py-3 px-3 footer-font"
                            style={{
                                color: data?.ChatbotTextColor,
                                background: data?.ChatHistoryBackgroundColor,
                            }}
                            placeholder="category name"
                            value={category}
                            type="text"
                            readOnly
                            // onChange={(e) => {
                            //     setTypeMessage(e.target.value);
                            // }}
                        />
                    </div>
                </Col>
                <Col
                    md="5"
                    xs="4"
                    className="flex align-center border-l-2 "
                    style={{
                        borderColor: data?.ChatbotTextColor,
                    }}
                >
                    {category == "Document" ? (
                        <>
                            <div className="flex items-center">
                                <div className="d-md-block d-none">
                                    Upload Document
                                </div>
                                <div
                                    className="ml-2 cursor-pointer"
                                    // onClick={
                                    //     loading
                                    //         ? null
                                    //         : handleClick
                                    // }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!loading) {
                                            fileRef.current.click();
                                        }
                                    }}
                                >
                                    <GrAttachment />
                                </div>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        handleFile(e);
                                    }}
                                    ref={fileRef}
                                    hidden
                                ></input>
                                {file !== "" && (
                                    <>
                                        {loading ? (
                                            <Ring
                                                size={25}
                                                speed={1.3}
                                                color="black"
                                                className="d-xl-flex d-none"
                                            />
                                        ) : (
                                            <div
                                                className="px-3 py-1 ml-2 flex items-center d-xl-flex d-none"
                                                style={{
                                                    background:
                                                        data?.CategoryBackgroundColor,
                                                }}
                                            >
                                                <span className="mr-1">
                                                    <IoDocumentTextOutline className="text-white fs--18" />
                                                </span>{" "}
                                                <span
                                                    className=""
                                                    style={{
                                                        color: data?.CategoryTextColor,
                                                    }}
                                                >
                                                    {file?.name}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    ) : category == "Image" ? (
                        <div
                            className=" px-1 d-none d-md-block"
                            style={{
                                borderColor: data?.ChatbotTextColor,
                            }}
                        >
                            <Input
                                className="w-full chat-input  py-3 footer-font px-1"
                                style={{
                                    color: data?.ChatbotTextColor,
                                    background:
                                        data?.ChatHistoryBackgroundColor,
                                }}
                                value={graphicSelected}
                                type="select"
                                onChange={(e) => {
                                    setGraphicSelected(e.target.value);
                                }}
                            >
                                <option value="DALL-E 2">DALL-E 2</option>
                                <option value="DALL-E 3">DALL-E 3</option>
                            </Input>
                        </div>
                    ) : null}
                </Col>
            </Row>

            {file !== "" && (
                <Row
                    className={`mx-3 my-2 px-2 py-2 align-items-center rounded-[20px]  d-xl-none  `}
                    style={{
                        background: data?.ChatHistoryBackgroundColor,
                    }}
                >
                    <Col xs="12" className=" d-flex ">
                        <>
                            {loading ? (
                                <Ring size={25} speed={1.3} color="black" />
                            ) : (
                                <div
                                    className="px-3 py-1 ml-2 flex items-center"
                                    style={{
                                        background:
                                            data?.CategoryBackgroundColor,
                                    }}
                                >
                                    <span className="mr-1">
                                        <IoDocumentTextOutline className="text-white fs--18" />
                                    </span>{" "}
                                    <span
                                        className=""
                                        style={{
                                            color: data?.CategoryTextColor,
                                        }}
                                    >
                                        {file?.name}
                                    </span>
                                </div>
                            )}
                        </>
                    </Col>
                </Row>
            )}

            <Row
                className={`mx-3 my-2 px-2    align-items-cente rounded-[20px]  `}
                style={{
                    background: data?.ChatHistoryBackgroundColor,
                }}
            >
                <Col
                    md="2"
                    xs="4"
                    className="flex align-items-center p-0 "
                    style={{
                        color: data?.ChatbotTextColor,
                    }}
                >
                    <div
                        className=" text-center  footer-font border-r-2 py-3  pl-1 w-[200px]"
                        style={{
                            borderColor: data?.ChatbotTextColor,
                        }}
                    >
                        Message
                    </div>
                </Col>

                <Col md="10" xs="8" className="flex align-items-center  w-100">
                    <div className="w-100">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit(typeMessage);
                            }}
                        >
                            <div className="py-2 flex  align-items-center">
                                <Input
                                    className="chat-input footer-font"
                                    placeholder="Send message "
                                    style={{
                                        color: data?.ChatbotTextColor,
                                        background:
                                            data?.ChatHistoryBackgroundColor,
                                    }}
                                    value={typeMessage}
                                    type="text"
                                    onChange={(e) => {
                                        setTypeMessage(e.target.value);
                                    }}
                                />

                                {loading ? (
                                    <DotPulse
                                        size={25}
                                        speed={1.3}
                                        color="black"
                                    />
                                ) : (
                                    <Button
                                        className="light border-0 "
                                        type="submit"
                                    >
                                        <IoSend
                                            className={`icon-design ${
                                                typeMessage != ""
                                                    ? "text-success"
                                                    : ""
                                            }`}
                                        />
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ChatbotFooter;
