import React from "react";
import { Col, Row } from "reactstrap";
import userchat from "../assets/img/userchat.jpeg";

import botchat from "../assets/img/botchat.jpeg";

export const ChatBotMessages = ({
    chats,

    data,
    chatContainerRef,
}) => {
    function parseTextToObjects(text) {
        const pattern =
            /Title: (.*?)##data##Description: (.*?)##data##Url: (.*?)##data##Image: (.*?)#@data@#/g;
        let matches;
        const objects = [];

        while ((matches = pattern.exec(text)) !== null) {
            objects.push({
                title: matches[1],
                description: matches[2],
                url: matches[3],
                image: matches[4],
            });
        }
        return objects;
    }

    return (
        <>
            {chats?.length > 0 &&
                chats?.map((item, index) => {
                    return (
                        <>
                            <div
                                ref={
                                    index === chats.length - 1
                                        ? chatContainerRef
                                        : null
                                }
                            >
                                <Row
                                    className="py-3 pl-1 border-b-2 "
                                    style={{
                                        borderColor: data?.ChatbotColor,
                                    }}
                                >
                                    <Col>
                                        <Row>
                                            <Col
                                                md="1"
                                                xs="3"
                                                sm="2"
                                                className="justify-center flex"
                                            >
                                                <div className="avatar avatar-sm rounded-circle w-[40px] h-[40px]">
                                                    <img
                                                        src={userchat}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                xs="9"
                                                md="11"
                                                sm="10"
                                                className="px-0"
                                            >
                                                <div className="px-2">
                                                    <p
                                                        className=" chat-message-user"
                                                        style={{
                                                            color: data?.UserMessageColor,
                                                        }}
                                                    >
                                                        {item?.User}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    className="py-3 pl-1 border-b-2 "
                                    style={{
                                        borderColor: data?.ChatbotColor,
                                    }}
                                >
                                    <Col>
                                        <Row>
                                            <Col
                                                md="1"
                                                xs="3"
                                                sm="2"
                                                className="justify-center flex"
                                            >
                                                <div className="avatar avatar-sm rounded-circle  w-[40px] h-[40px]  ">
                                                    <img src={botchat} />
                                                </div>
                                            </Col>
                                            <Col
                                                xs="9"
                                                md="11"
                                                sm="10"
                                                className="px-0"
                                            >
                                                <div className="px-2">
                                                    <p
                                                        className="chat-message-user"
                                                        style={{
                                                            color: data?.ChatbotColor,
                                                        }}
                                                    >
                                                        {typeof item?.AI ===
                                                            "string" &&
                                                        item?.AI.startsWith(
                                                            "http"
                                                        ) ? (
                                                            <img
                                                                src={item.AI}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        ) : typeof item?.AI ===
                                                              "string" &&
                                                          !item?.AI.startsWith(
                                                              "http"
                                                          ) ? (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        whiteSpace:
                                                                            "pre-wrap",
                                                                    }}
                                                                >
                                                                    {
                                                                        item?.AI?.split(
                                                                            "##end##"
                                                                        )[0]
                                                                    }
                                                                </div>
                                                                <Row className="py-3">
                                                                    {parseTextToObjects(
                                                                        item?.AI
                                                                    )?.map(
                                                                        (
                                                                            obj,
                                                                            index
                                                                        ) => (
                                                                            <>
                                                                                <Col
                                                                                    sm="6"
                                                                                    md="4"
                                                                                >
                                                                                    <a
                                                                                        href={
                                                                                            obj?.url
                                                                                        }
                                                                                        target="_blank"
                                                                                    >
                                                                                        <div className="shadow bg-[#fff]">
                                                                                            <div className="box">
                                                                                                <img
                                                                                                    src={
                                                                                                        obj?.image
                                                                                                    }
                                                                                                />
                                                                                                <div className="is-clipped">
                                                                                                    <div
                                                                                                        style={{
                                                                                                            wordBreak:
                                                                                                                "break-word",
                                                                                                        }}
                                                                                                        className="has-text-weight-bold preview__tiltle"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: obj?.title,
                                                                                                        }}
                                                                                                    ></div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            wordBreak:
                                                                                                                "break-word",
                                                                                                        }}
                                                                                                        className="mt-2 preview__description"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: obj?.description,
                                                                                                        }}
                                                                                                    ></div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            wordBreak:
                                                                                                                "break-word",
                                                                                                        }}
                                                                                                        className="mt-2 is-size-7 text-primary"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: obj?.url,
                                                                                                        }}
                                                                                                    ></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </Row>
                                                            </>
                                                        ) : null}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    );
                })}
        </>
    );
};
