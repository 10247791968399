import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BiHomeAlt } from "react-icons/bi";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { Col, Container, Row } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const location = useLocation();
    const { user } = useSelector((state) => state.authUser);
    console.log("location", location);

    return (
        <div className="w-full bg-black">
            <Container>
                {/* <div className="max-w-[1640px]bg-black"> */}

                <Row className=" align-items-center justify-content-between  py-4  px-4  bg-black  text-zinc-600  z-50">
                    <Col md="4" lg="5" xl="4" xs="7">
                        <Link to="/">
                            <div className="flex items-center gap-3 hover:cursor-pointer">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M6.82928 9C6.93984 8.68722 6.99999 8.35064 6.99999 8C6.99999 7.64936 6.93984 7.31278 6.82928 7H17C20.3137 7 23 9.68629 23 13C23 16.3137 20.3137 19 17 19H15.8293C15.9398 18.6872 16 18.3506 16 18C16 17.6494 15.9398 17.3128 15.8293 17H17C19.2091 17 21 15.2091 21 13C21 10.7909 19.2091 9 17 9H6.82928Z"
                                        fill="white"
                                    />
                                    <circle
                                        cx="13"
                                        cy="18"
                                        r="3"
                                        fill="white"
                                    />
                                    <circle cx="4" cy="8" r="3" fill="white" />
                                </svg>
                                <p className=" text-md md:text-2xl  text-white hover:cursor-pointer   md:font-bold">
                                    Open Truth
                                </p>
                            </div>
                        </Link>
                    </Col>

                    {location.pathname !== "/pricing" && (
                        <Col
                            lg="7"
                            xl="6"
                            md="8"
                            className="hidden md:block text-sm font-semibold py-2 "
                        >
                            <div>
                                <div className="flex  gap-2 lg:gap-8  items-center border  hover:cursor-pointer px-2 py-2 rounded-full  shadow-md">
                                    <div>
                                        <BiHomeAlt
                                            size={30}
                                            className="bg-white rounded-full p-1 shadow-sm"
                                        />
                                    </div>
                                    <div className="flex gap-2 lg:gap-4 items-center justify-start  px-1 lg:px-5">
                                        <Link
                                            to="/"
                                            className="text-decoration-none"
                                        >
                                            <div className="active:text-white hover:text-white text-white fs--18 fw--600">
                                                Home
                                            </div>
                                        </Link>
                                        <Link
                                            to="/news"
                                            className="text-decoration-none"
                                        >
                                            <div className="active:text-white hover:text-white text-white fs--18 fw--600">
                                                News
                                            </div>
                                        </Link>
                                        <Link
                                            to="/mainview"
                                            className="text-decoration-none"
                                        >
                                            <div className="active:text-white hover:text-white text-white fs--18 fw--600">
                                                OpenTruthGPTQ
                                            </div>
                                        </Link>
                                        <Link
                                            to="/pricing"
                                            className="text-decoration-none"
                                        >
                                            <div className="active:text-white hover:text-white  text-white fs--18 fw--600">
                                                Pricing
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}

                    <Col
                        md="3"
                        lg="2
                        "
                        xs="5"
                        className="d-flex justify-end  md:justify-center"
                    >
                        <div className="hidden md:block"></div>
                        <div className="block cursor-pointer md:hidden">
                            {toggle ? (
                                <AiOutlineClose
                                    onClick={() => setToggle(!toggle)}
                                    className=" p-1  text-white"
                                    size={35}
                                />
                            ) : (
                                <GiHamburgerMenu
                                    onClick={() => setToggle(!toggle)}
                                    className="p-1   rounded  text-white"
                                    size={35}
                                />
                            )}
                        </div>
                    </Col>
                    {toggle && (
                        <Col xs="12" className="block md:hidden py-3 px-2">
                            <div className="px-3">
                                <Link to="/" className="text-decoration-none ">
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="my-2 active:text-dark  hover:text-white bg-dark text-white rounded-md text-center fs--17 fw--600 py-2"
                                    >
                                        Home
                                    </div>
                                </Link>
                                <Link
                                    to="/news"
                                    className="text-decoration-none "
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="my-2 active:text-white hover:text-white fs--17 fw--600 py-2 bg-dark text-white rounded-md text-center"
                                    >
                                        Blog
                                    </div>
                                </Link>
                                <Link
                                    to="/mainview"
                                    className="text-decoration-none "
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="my-2 active:text-white hover:text-white fs--17 fw--600 py-2 bg-dark text-white rounded-md text-center"
                                    >
                                        OpenTruthGPTQ
                                    </div>
                                </Link>
                                <Link
                                    to="/pricing"
                                    className="text-decoration-none "
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="my-2 active:text-white hover:text-white fs--18 fw--700 py-2 fs--17 fw--600 py-2 bg-dark text-white rounded-md text-center"
                                    >
                                        Pricing
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    )}
                </Row>

                {/* </div> */}
            </Container>
        </div>
    );
};
export default Navbar;
