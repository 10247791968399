import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import MultiSelect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
    getSitesAction,
    saveSitesAction,
} from "../store/actions/chattingAction";
import { toast } from "react-toastify";

export default function FilterWebsilteModal({
    toggleFilter,
    filter,
    setFilter,
}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const { chat, loading, chattingId, historyChat, delete_loader, sitesData } =
        useSelector((state) => state.chatting);
    const { user } = useSelector((state) => state.authUser);
    const dispatch = useDispatch();
    const onSelect = (selectedList, selectedItem) => {
        updateStatusAndSendToBackend(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        updateStatusAndSendToBackend(selectedList);
    };
    const updateStatusAndSendToBackend = (selectedList) => {
        const updatedOptions = options?.map((option) => {
            const isSelected = selectedList.some(
                (selected) => selected.Id === option.Id
            );
            return {
                ...option,
                Status: isSelected ? "True" : "False",
            };
        });

        setSelectedOptions(selectedList);
        setOptions(updatedOptions);
    };
    useEffect(() => {
        console.log("sitesData", sitesData);
        if (sitesData && sitesData?.length > 0) {
            let data = sitesData?.filter((obj) => obj?.Status == "True");

            setSelectedOptions(data);
            setOptions(sitesData);
        } else {
            setOptions([]);
        }
    }, [sitesData]);

    const handleFilter = () => {
        let obj = {
            UserId: user?.UserId,
            Websites: options,
        };
        console.log("obj--", obj);
        if (obj?.Websites?.length > 0) {
            dispatch(
                saveSitesAction(obj, () => {
                    setSelectedOptions([]);
                    setOptions([]);
                    dispatch(getSitesAction(user?.UserId));
                    toggleFilter();
                })
            );
        } else {
            toast.warn("select atleast 1 sites");
        }
    };

    const handleCancel = () => {
        if (sitesData?.length > 0) {
            console.log("sitesData__", sitesData);
            let data = sitesData?.filter((obj) => obj?.Status == "True");

            setSelectedOptions(data);
            setOptions(sitesData);
            toggleFilter();
        } else {
            setSelectedOptions([]);
            setOptions([]);
            toggleFilter();
        }
    };
    return (
        <>
            <Modal
                isOpen={filter}
                // toggle={toggleFilter}
                size="md"
                centered={true}
                className="modal"
            >
                <div className="bg-gradient-to-t from-sky-500 to-sky-600 modal__header d-flex justify-content-between align-items-center px-4 py-4">
                    <div className="fs--18 fw--600">Filter Sites</div>
                    <div className="cursor-pointer" onClick={handleCancel}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                fill="#ffff"
                                fill-opacity="0.4"
                            />
                            <path
                                d="M9.10205 14.7568L14.8977 9.24228"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14.7572 14.8982C12.6037 12.6349 11.3962 11.3659 9.24268 9.10254"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <ModalBody className="px-4 py-5">
                    <Row>
                        <Col>
                            <MultiSelect
                                options={options}
                                showArrow={true}
                                selectedValues={selectedOptions}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                displayValue="value"
                                showCheckbox={true}
                                placeholder="Select Sites"
                                style={{
                                    multiselectContainer: {
                                        width: "100%",
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <div className="text-right">
                                <Button
                                    className="bg-danger program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2 mr-2"
                                    onClick={handleFilter}
                                    disabled={delete_loader}
                                >
                                    {delete_loader ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        `${"Save"}`
                                    )}
                                </Button>
                                <Button
                                    className="bg-gradient-to-t from-sky-500 to-sky-600 program__card__button cursor-pointer text-white fs--16  fw--400 px-4 py-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}
