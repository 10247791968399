import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BiHomeAlt } from "react-icons/bi";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { Col, Container, Row } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/authAction";
const AuthNavbar = () => {
    const [toggle, setToggle] = useState(false);
    const location = useLocation();

    const { user } = useSelector((state) => state.authUser);
    const dispatch = useDispatch();
    console.log("location", location);
    const history = useHistory();
    return (
        <div className="w-full bg-black">
            <Container>
                {/* <div className="max-w-[1640px]bg-black"> */}
                <Row className=" py-5 align-items-center justify-content-between   px-4  bg-black  text-zinc-600  z-50">
                    <Col md="4" lg="4" xs="8">
                        <Link to="/">
                            <div className="flex items-center gap-3 hover:cursor-pointer">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M6.82928 9C6.93984 8.68722 6.99999 8.35064 6.99999 8C6.99999 7.64936 6.93984 7.31278 6.82928 7H17C20.3137 7 23 9.68629 23 13C23 16.3137 20.3137 19 17 19H15.8293C15.9398 18.6872 16 18.3506 16 18C16 17.6494 15.9398 17.3128 15.8293 17H17C19.2091 17 21 15.2091 21 13C21 10.7909 19.2091 9 17 9H6.82928Z"
                                        fill="white"
                                    />
                                    <circle
                                        cx="13"
                                        cy="18"
                                        r="3"
                                        fill="white"
                                    />
                                    <circle cx="4" cy="8" r="3" fill="white" />
                                </svg>

                                <p className=" text-md md:text-2xl  text-white hover:cursor-pointer   md:font-bold">
                                    Open Truth
                                </p>
                            </div>
                        </Link>
                    </Col>

                    <Col
                        md="4"
                        lg="3"
                        xs="6"
                        className="d-flex justify-end  md:justify-center"
                    >
                        {/* <div className="hidden md:block">
                            <div className="flex items-center gap-3">
                                <Link
                                    to="/login"
                                    className="text-decoration-none"
                                >
                                    <span className="hover:cursor-pointer text-white text-xl">
                                        Login
                                    </span>
                                </Link>
                                {user?.user_id ? (
                                    <span
                                        className="hover:cursor-pointer text-white text-xl "
                                        onClick={() => {
                                            dispatch(
                                                logout(() => {
                                                    history.push("/");
                                                })
                                            );
                                        }}
                                    >
                                        Logout
                                    </span>
                                ) : (
                                    <Link
                                        to="/signup"
                                        className="text-decoration-none ml-2"
                                    >
                                        <span className="hover:cursor-pointer text-white text-xl ">
                                            Sign Up
                                        </span>
                                    </Link>
                                )}
                            </div>
                        </div> */}
                    </Col>
                </Row>

                {/* </div> */}
            </Container>
        </div>
    );
};
export default AuthNavbar;
