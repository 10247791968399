import { EBAY_LOADING, GET_EBAY, EBAY_IMAGES, GET_EBAY_IMAGE, EBAY_DATA, GET_EBAY_CATEGORY, GET_EBAY_CONDITION, DESCRIPTION_AI, CREATE_EBAY_LOADING, GET_SHIPPING, GET_COUNTRY, EBAY_ITEM_SPECIFIC } from "../types";
import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var GetEbayRepo = RepositoryFactory.get("getEbay");

export const setEbayLoading = (val) => async (dispatch) => {
    dispatch({ type: EBAY_LOADING, payload: val });
};
export const createEbayLoading = (val) => async (dispatch) => {
    dispatch({ type: CREATE_EBAY_LOADING, payload: val });
};

export const getEbayAction = (item) => async (dispatch) => {
    await dispatch(setEbayLoading(true));
    try {
        console.log("ab", item);
        if (item) {
            dispatch({ type: GET_EBAY, payload: [] });
            let { data } = await GetEbayRepo.getEbay(item);

            if (data?.status_code == 200) {
                dispatch({ type: GET_EBAY, payload: data?.detail?.data });
                await dispatch(setEbayLoading(false));
            } else {
                toast.error(data?.Detail);
                dispatch({ type: GET_EBAY, payload: [] });
                await dispatch(setEbayLoading(false));
            }
        } else {
            dispatch({ type: GET_EBAY, payload: [] });
            await dispatch(setEbayLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};

export const getEbayItemSpecificAction = (id) => async (dispatch) => {
    try {
      
            let { data } = await GetEbayRepo.getEbayItemSpecific(id);
            if (data?.status == 200) {
                dispatch({ type: EBAY_ITEM_SPECIFIC, payload: data?.detail?.Data });
            } else {
                toast.error(data?.detail);
                dispatch({ type: EBAY_ITEM_SPECIFIC, payload: [] });
               
            }
       
    } catch (e) {
        toast.error(e.message);
      
    }
};
export const getShippingDetailAction = (item) => async (dispatch) => {
    try {
            let { data } = await GetEbayRepo.getShippingDetail(item);
            if (data?.status == 200) {
                dispatch({ type: GET_SHIPPING, payload: data?.detail?.Data });
            } else {
                toast.error(data?.Message);
            }
       
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const logoutEbayAction = (id,onSuccess) => async (dispatch) => {
    try {
        await dispatch(createEbayLoading(true));
            let { data } = await GetEbayRepo.logoutEbay(id);
        
            if (data.status_code == 200) {
                onSuccess()
                await dispatch(createEbayLoading(false));
               
                toast.success("Ebay account logout successfully")
            } else {
                await dispatch(createEbayLoading(false));
                toast.error(data?.detail);
            }
       
    } catch (e) {
        toast.error(e.message);
       
        await dispatch(createEbayLoading(false));
    }
};

export const createEbayListingAction = (item) => async (dispatch) => {
    await dispatch(createEbayLoading(true));
    try {
            let { data } = await GetEbayRepo.createEbayListing(item);
            console.log("data===>",data)
            await dispatch(createEbayLoading(false));

            if (data?.status_code == 200) {
                toast.success("Listing Created Successfully")
                await dispatch(createEbayLoading(false));
            } else {
                toast.error(data?.detail);
                await dispatch(createEbayLoading(false));
            }
    } catch (e) {
        toast.error(e.message);
        await dispatch(createEbayLoading(false));
    }
};
export const getEbayCategoryAction = (item,onSuccess) => async (dispatch) => {
    await dispatch(setEbayLoading(true));
    try {
        dispatch({ type: GET_EBAY, payload: [] });
            let { data } = await GetEbayRepo.getEbayCategory(item);
    console.log("data___", data?.detail?.Data?.SuggestedCategoryArray?.SuggestedCategory)
            if (data?.status== 200) {
                dispatch({ type: GET_EBAY_CATEGORY, payload: data?.detail?.Data?.SuggestedCategoryArray?.SuggestedCategory});
                await dispatch(setEbayLoading(false));
                onSuccess()
            } else {
                toast.error(data?.detail);
                dispatch({ type: GET_EBAY, payload: [] });
                await dispatch(setEbayLoading(false));
            }
       
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const getEbayCountryAction = (id) => async (dispatch) => {
   
    try {
            let { data } = await GetEbayRepo.getCountry(id);
   
            if (data?.status== 200) {
                dispatch({ type: GET_COUNTRY, payload: data?.detail.Data});
       
              
            } else {
                toast.error(data?.detail?.Message);
                dispatch({ type: GET_COUNTRY, payload: [] });
               
            }
       
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const getEbayTokenAction = (id, onSuccess) => async (dispatch) => {
    try {
        let { data } = await GetEbayRepo.getEbayToken(id);

        if (data?.status_code == 200) {
            onSuccess();
        } else {

            toast.error(data?.Detail);
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const sendCodeEbayAction = (obj, onSuccess) => async (dispatch) => {
    try {
        let { data } = await GetEbayRepo.sendEbayCode(obj);
        if (data?.status_code == 200) {

            onSuccess(true);
            toast.success("User  authenticate  with Ebay  Successfully");
        } else {
            onSuccess(false);
            toast.error(data?.detail);
        }
    } catch (e) {
        onSuccess(false);
        toast.error(e.message);
    }
};

export const ebayImagesAction = (obj,onSuccess) => async (dispatch) => {
    await dispatch(setEbayLoading(true));
    try {
        let { data } = await GetEbayRepo.ebayImagesRep(obj);
        console.log("data__", data);
        if (data?.status_code == 200) {
            dispatch({ type: GET_EBAY_IMAGE, payload: data?.detail });
            onSuccess()
          
        } else {
            toast.error(data?.detail);
            await dispatch(setEbayLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const getEbayDataAction = (respId, onSuccess) => async (dispatch) => {

    try {

        let { data } = await GetEbayRepo.getEbayJson(respId);

        if (data?.status_code == 200) {
            const responseData = data?.detail?.Data;
            if (responseData && responseData?.length > 0 && data.status_code==200 ) {
                dispatch({ type: EBAY_DATA, payload: responseData });
                await dispatch(setEbayLoading(false));
                onSuccess(false);
            } else {
                dispatch({ type: EBAY_DATA, payload: [] });
                onSuccess(true);
            }

        } else {
            onSuccess(false);
            
            toast.error(data?.detail);
            await dispatch(setEbayLoading(false));
        }
    } catch (e) {
        onSuccess(false);
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};



export const ebayDescriptionAction = (obj) => async (dispatch) => {
    try {
        await dispatch(setEbayLoading(true));
        let { data } = await GetEbayRepo.ebayDescriptionRep(obj);
       
        if (data?.status_code == 200) {
            dispatch({type:DESCRIPTION_AI,payload:data.detail})
            await dispatch(setEbayLoading(false));
        } else {
            toast.error(data?.detail);
            await dispatch(setEbayLoading(false));
        }
    } catch (e) {
       
        toast.error(e.message);
        await dispatch(setEbayLoading(false));
    }
};
export const getEbayCondition=(payload)=>async (dispatch)=>{
    try {
        let { data } = await GetEbayRepo.getConditionRep(payload);
        console.log("data++++",data)

        if (data?.status == 200) {
            dispatch({
                type:GET_EBAY_CONDITION,
                payload:data.detail.Data.ConditionValues.Condition
            })
           
        } else {
            toast.error(data?.detail);
        }
    } catch (e) {
        toast.error(e.message);
 
    }
}

