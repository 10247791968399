import { RepositoryFactory } from "../../repository/RepositoryFactory";

import { GET_ALL_PUBLISH_NEWS, NEWS_LOADER } from "../types";

var news = RepositoryFactory.get("news");

export const setNewsLoader = (val) => async (dispatch) => {
    dispatch({ type: NEWS_LOADER, payload: val });
};
//**************************THIS IS FOR THE GET ALL PUBLISH NEWS [GET API.......]***************
export const allPublishNewsAction = () => async (dispatch) => {
    try {
        await dispatch(setNewsLoader(true));

        const { data } = await news.getAllPublishNews();
        if (data?.status_code == 200) {
            dispatch({ type: GET_ALL_PUBLISH_NEWS, payload: data.detail });
            await dispatch(setNewsLoader(false));
        } else {
            alert(data?.detail);
            dispatch({ type: GET_ALL_PUBLISH_NEWS, payload: [] });
            await dispatch(setNewsLoader(false));
        }
    } catch (err) {
        alert(err);
        await dispatch(setNewsLoader(false));
    }
};





