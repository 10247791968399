import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Row, Spinner } from "reactstrap";
import {
    braveSearchChatAction,
    chatHistoryAction,
    getBraveSearchHistory,
    getSitesAction,
} from "../store/actions/chattingAction";
import { logout } from "../store/actions/authAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import { useEffect } from "react";
import { FaFilePdf, FaFilterCircleXmark, FaRobot } from "react-icons/fa6";
import FilterWebsilteModal from "./FilterWebsilteModal";

const SearchBar = ({
    query,
    setQuery,
    Category,
    setCategory,
    setSubCategory,
    SubCategory,
    chatId,
    setChatId,
    showDropdown,
    setShowDropdown,
}) => {
    const [History, setHistory] = useState([]);
    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };
    const [filter, setFilter] = useState(false);
    const toggleFilter = () => {
        setFilter(!filter);
    };
    const { chat, braveSearchHistory, loading } = useSelector(
        (state) => state.chatting
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const { user, uid } = useSelector((state) => state.authUser);

    useEffect(() => {
        if (braveSearchHistory?.length > 0) {
            // const uniqueData = new Map(
            //     braveSearchHistory?.map((item) => [
            //         item?.FirstMessage?.toLowerCase(),
            //         item,
            //     ])
            // );
            // console.log("uniqueData", uniqueData);
            // const uniqueArray = Array.from(uniqueData?.values());
            // console.log(uniqueArray, "uniqueArray");
            setHistory(braveSearchHistory);
        }
    }, [braveSearchHistory]);

    useEffect(() => {}, []);
    const handleInputClick = () => {
        setShowDropdown(!showDropdown);
    };

    const submit = (e) => {
        e.preventDefault();
        if (uid) {
            if (query != "" && Category != "") {
                let obj = {
                    Category,
                    SubCategory: Category != "WebSearch" ? "" : SubCategory,
                    Query: query,
                    ChatId: Number(chatId),
                    UserId: user?.UserId,
                };
                console.log("obj===", obj);

                dispatch(
                    braveSearchChatAction(obj, (value) => {
                        if (value == "not exist") {
                            toast.error("block");
                            setShowDropdown(false);
                            dispatch(
                                logout(() => {
                                    history.push("/");
                                })
                            );
                        } else {
                            if (value == null) {
                                setQuery("");
                                setShowDropdown(false);
                                let payload = {
                                    UserId: user?.UserId,
                                    Category: Category,
                                };
                                dispatch(getBraveSearchHistory(payload, false));
                            }
                        }
                    })
                );
            } else {
                toast.warn("Please must the required fields!");
            }
        } else {
            toast.warn("First  Login");
        }
    };

    useEffect(() => {
        if (uid != null) {
            dispatch(getSitesAction(uid));
        }
    }, [uid]);

    // const handleKeyPress = (e) => {
    //     if (e.key === "Enter") {
    //         submit();
    //     }
    // };

    return (
        <>
            <Row className="mx-2">
                <Col lg="6" className="my-1 px-0">
                    <div className="relative w-full">
                        <Form onSubmit={submit}>
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="w-full py-2 px-5 pl-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                                    value={query}
                                    required
                                    onChange={handleInputChange}
                                    onClick={handleInputClick}
                                />
                                <HiOutlineSearch
                                    size={20}
                                    className="w-5 h-5 text-gray-400 absolute left-3 top-3"
                                />
                                <div className="ml-2">
                                    <Button
                                        type="submit"
                                        className="button-search"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Spinner size="md" />
                                        ) : (
                                            "Search"
                                        )}
                                    </Button>
                                </div>
                            </div>

                            {showDropdown && (
                                <div className="absolute z-10 top-12 left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                                    {History?.map((obj, index) => (
                                        <div
                                            key={index}
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => {
                                                setShowDropdown(!showDropdown);
                                                setQuery(obj?.FirstMessage);
                                            }}
                                        >
                                            {obj?.FirstMessage}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Form>
                    </div>
                </Col>
                <Col lg="2" className="my-1 ">
                    {/* <div className="mx-2"> */}
                    <Input
                        // className=""

                        className="w-full text-black   border border-gray-300 rounded-md focus:outline-none"
                        value={Category}
                        type="select"
                        onChange={(e) => {
                            setCategory(e.target.value);
                        }}
                    >
                        <option value="WebSearch">Web Search</option>
                        <option value="SpellCheck">Spell Check</option>
                        <option value="ImageSearch">Image</option>
                        <option value="AutoSuggestion">Auto Suggestion</option>
                    </Input>
                    {/* </div> */}
                </Col>
                <Col lg="2" className="my-1  ">
                    {Category == "WebSearch" && (
                        <Input
                            className="w-full   text-black    border border-gray-300 rounded-md focus:outline-none"
                            // style={{
                            //     color: data?.ChatbotTextColor,
                            //     background: data?.ChatHistoryBackgroundColor,
                            // }}
                            value={SubCategory}
                            type="select"
                            onChange={(e) => {
                                setSubCategory(e.target.value);
                            }}
                        >
                            <option value="AIPRO">AI PRO</option>
                            <option value="DATAAI">DATA AI</option>
                        </Input>
                    )}
                </Col>
                {Category == "WebSearch" && (
                    <Col lg="2" className=" my-1">
                        <div
                            onClick={toggleFilter}
                            className="w-full cursor-pointer flex    py-2 px-1    text-black    border border-gray-300 rounded-md focus:outline-none"
                        >
                            <span>Filter Sites</span>
                            <span className="ml-1 pt-1">
                                <FaFilterCircleXmark />
                            </span>
                        </div>
                    </Col>
                )}
            </Row>

            <FilterWebsilteModal
                toggleFilter={toggleFilter}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SearchBar;
