import {
    AUTH_NOTIFICATION,
    AUTH_SET_LOADING,
    LOADER,
    SINGLE_USER,
    FORGET_PASSWORD,
} from "../types";

const initialState = {
    notification: {
        message: "",
        type: "",
    },
    loading: false,
    singleUser: {},
    customizationData: null,
};

export default function authReducer(state = initialState, { type, payload }) {
    switch (type) {
        case AUTH_NOTIFICATION:
            return {
                ...state,
                notification: payload,
            };
        case AUTH_SET_LOADING:
            return {
                ...state,
                customizationData: payload,
            };
        case LOADER:
            return {
                ...state,
                loading: payload,
            };
        case SINGLE_USER:
            return {
                ...state,
                singleUser: payload,
            };
        case "CUSTOMIZE":
            return {
                ...state,
                customizationData: payload,
            };
        default:
            return {
                ...state,
            };
    }
}
